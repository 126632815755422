import { Alert, Icon, Intent } from '@blueprintjs/core';
import i18next from 'i18next';
import React from 'react';
import './MatchItem.css';

class MatchItem extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            deleteOpen:false,
        }
    }

    delete = () => {
        this.setState({deleteOpen:true});

    }
    cancelDelete = () => {
        this.setState({deleteOpen:false});
    }

    render() {
        const { database, spreadsheet} = this.props;

        const deletePopup= <Alert
            cancelButtonText={i18next.t("general.cancel")}
            confirmButtonText={i18next.t("general.delete")}
            icon="trash"
            intent={Intent.DANGER}
            isOpen={this.state.deleteOpen}
            onCancel={this.cancelDelete}
            onConfirm={this.props.onRemove}
            >
        <p>
            {i18next.t("upload.delete-match-confirm" )}
        </p>
        </Alert>;

        return <div className={"match-item" + (this.props.disabled ? " disabled" :"")}>
            {deletePopup}
            <div className="spreadsheet">{spreadsheet}</div>
            <Icon icon="chevron-right" color="#AAA" style={{marginLeft:'10px', marginRight:'10px'}} />
            <div className="database">{database}</div>
            <div className="spacer" />
            <div className="delete" onClick={this.delete}>{i18next.t("general.delete")}</div>

        </div>
    }
}

export {MatchItem}