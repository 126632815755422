import React from 'react';
import {tableIcon} from '../_components';
import logo from "../assets/logo.svg";
import {Button, Intent} from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import "./MenuPage.css";
import {MenuButton} from "./MenuButton";

import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { history } from '../_helpers';

class MenuPage extends React.Component {
    render() {

        const { currentTable, currentMenu, currentTableName, currentTablePermission, resetTable } = this.props;

        const tableButton = <Button active={!currentMenu} onClick={resetTable} icon={tableIcon('#137CBD',true)} rightIcon="double-chevron-right" large={false} intent={Intent.PRIMARY} className="menu-table-button" outlined={true}>
            {/* {tableIcon('#FFF')} */}
            <span className="bp3-text-small">{currentTable ? currentTableName : i18next.t("menu.select-table")}</span>

        </Button>

        return (
        <div id="menu">
            <div id="logo-div">
                <img src={logo} alt="logo" id="logo" />
                

            </div>
            {tableButton}
            {currentTable &&currentMenu &&  
            <MenuButton id="records" currentMenu={currentMenu} />}
            {currentTable &&currentMenu && currentTablePermission > 0 && 
            <MenuButton id="upload-data" currentMenu={currentMenu} />}
            {currentTable &&currentMenu &&  
            <MenuButton id="download-data" currentMenu={currentMenu} />}
            {currentTable &&currentMenu && currentTablePermission ===2 &&   <div className="divider" ><div /></div>}
            {currentTable &&currentMenu && currentTablePermission ===2 &&  
            <MenuButton id="edit-form" currentMenu={currentMenu} />}
            {currentTable &&currentMenu &&  currentTablePermission ===2 &&   
            <MenuButton id="co-workers" currentMenu={currentMenu} />}
            {currentTable &&currentMenu && <div className="divider" ><div /></div>}
            <MenuButton id="settings" currentMenu={currentMenu} />
            <MenuButton id="sign-out" currentMenu={currentMenu} />
            
            
        </div>
        );
    }
    return 

    
    
}



export {MenuPage}