import { TextArea, Callout, Dialog, H4, Tab, Tabs, Switch, Classes, Button, Intent, EditableText, H6, FormGroup, InputGroup, Spinner, Alert } from '@blueprintjs/core';
import React from 'react';
import i18n from 'i18next';
import './FieldDialog.css';
import { KeyValueTable } from './KeyValueTable';
import {checkFieldString, checkIfDuplicateExists, isNumber, postWithToken, safeParse, typeFromString} from "../_helpers";
import { findAllByDisplayValue } from '@testing-library/react';
import Tour from 'reactour';
import DescriptionImage from '../assets/helper/helper-description.png';
import KeyImage from '../assets/helper/helper-key.png';
import NameImage from '../assets/helper/helper-name.png';
import TitleImage from '../assets/helper/helper-title.png';
import RequiredImage from '../assets/helper/helper-required.png';
import SurveyImage from '../assets/helper/helper-survey.png';
import TypeImage from '../assets/helper/helper-type.png';


class FieldDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTitle : false,
      isRequired:false,
      isSurvey:false,
      isFloat:false,
      fieldName:"",
      fieldKey:"",
      fieldDescription:"",
      singleTable : [],
      multiTable : [],
      saveLoading:false,
      evaluateError:false,
      min:"",
      max:"",
      loading:false,
      fixedType:null,
      confirmDeleteOpen:false,
      surveyDescription:"",
      isTourOpen : false,
    };

    

    var current_isOpen = this.props.isOpen;
  }

  currentTabId = "field-edit-tab-text";

  

  
  loadExistingField = () => {
    // load
    this.setState({
      loading:true,
    });

    //load from DB
    postWithToken('editform/loadField.php',{tableIdx:this.props.tableIdx, fieldIdx:this.props.fieldIdx},
    (data)=>{

      let options = safeParse(data.data.options);
      let type = parseInt(data.data.type);
      let stringType;

      let newState = {
        isTitle:parseInt(data.data.title_field) > 0,
        isRequired:parseInt(data.data.required) > 0,
        fieldName:data.data.name,
        fieldKey:data.data.key,
        isSurvey:parseInt(data.data.survey) > 0,
        surveyDescription:data.data.survey_description,
      }
      if(type===0) {
        stringType = "text";
        newState.fieldDescription = options.description ?? "";
      } else if(type===1) {
        //integer
        stringType = "number";
        newState.fieldDescription = options.description ?? "";
        newState.isFloat = false;
        newState.min = options.min ?? "";
        newState.max= options.max?? "";
      } else if(type===2) { 
        //float
        stringType = "number";
        newState.fieldDescription = options.description ?? "";
        newState.isFloat = true;
        newState.min = options.min ?? "";
        newState.max= options.max?? "";
      } else if(type===3) {
        //radio
        stringType="single-selection";
        newState.singleTable = options;
      } else if(type===4) {
        //checkbox group
        stringType="multiple-selection";
        newState.multiTable= options;

      } else if(type===5) {
        //textarea
        stringType="long-text";
        newState.fieldDescription = options.description ?? "";
      } else if(type===6) {
        //date only
        stringType="date";
        newState.fieldDescription = options.description ?? "";
      } else if(type===7) {
        //datetime only
        stringType="datetime";
        newState.fieldDescription = options.description ?? "";
      } else if(type===8) {
        //timeonly
        stringType="time";
        newState.fieldDescription = options.description ?? "";
      }

      this.currentTabId = "field-edit-tab-"+stringType;


      newState.fixedType = stringType;

      this.setState(newState);

    },null,(data)=>{
      this.setState({
        loading:false,
      })
    })

  }


  reset = () => {
    this.setState({
      isTitle:false,
      isRequired:false,
      isFloat:false,
      isSurvey:false,
      fieldName:"",
      fieldKey:"",
      fieldDescription:"",
      singleTable : [],
      multiTable : [],
      evaluateError:false,
      saveLoading:false,
      min:"",
      max:"",
      loading:false,
      fixedType:null,
      confirmDeleteOpen:false,
      surveyDescription:"",
    });

    this.currentTabId = "field-edit-tab-text";
    if(this.props.isNew === false && this.props.isOpen === true) {
      this.loadExistingField();
    }

  }

  boolChange = (name) => {
    this.setState({
      [name] : !this.state[name],
    })
  }

  getBoolSwitch = (key) => {
    return <Switch className={"input-group-switch-"+key} key={"field-switch-"+key} checked={this.state[key]} label={i18n.t("editform.bool-switch-"+key)} onChange={() => {this.boolChange(key)}} /> 
  }
  changeField = (key, value) => {
    this.setState({
      [key] : value,
    })
  }
  getInputField = (key, required=false) => {
    let intent = Intent.NONE;
    let messageString;

    if(this.state.evaluateError && (key==="fieldName" ||key==="fieldKey")) {
      if(!this.state[key]) {
        intent = Intent.DANGER;
        messageString = i18n.t("general.field-empty");
      } else if(key === "fieldKey" && !checkFieldString(this.state[key])) {
        intent = Intent.DANGER;
        messageString = i18n.t("editform.only-letters");
      }
    } else if(key==="min" || key==="max") {
      
      if(this.state[key] !== "" && !(isNumber(this.state[key]))) {
        //wrong data
        intent =Intent.DANGER;
        messageString=i18n.t("general.not-number");
      }

    }

    return<div id={"input-group-"+key}> <FormGroup className={"input-group-"+key} helperText={messageString} intent={intent} key={"field-input-"+key} label={i18n.t("editform.field-input-name-"+key)} labelFor={"field-input-"+key} labelInfo={required ? i18n.t("general.required") : i18n.t("general.optional")}>
      <InputGroup id={"field-input-"+key} intent={intent} placeholder={i18n.t("editform.field-input-placeholder-"+key)} value={this.state[key]} onChange={(e) => {this.changeField(key, e.target.value)}} />
    </FormGroup></div>;
  }

  getPanel = (key, body, options) => {
    return <div className="field-edit-container">
      <Callout className="field-dialog-description bp3-fill">
        <H6>{i18n.t("editform.field-"+key)}</H6>
        <small>{i18n.t("editform.field-description-"+key)}</small>

      </Callout>
      {body}
      <div className="field-dialog-options">
        {options}
      </div>
    </div>;

  }

  onTableChanged = (key, newState) => {
    this.setState({
      [key] : newState
    });
  }

  onTabChange = (newTab, prevTab) => {
    this.currentTabId = newTab;
  }

  onSave = () => {

    /* check empty or illegal character for name and key */
    if(!this.state.fieldName 
      || !this.state.fieldKey 
      || !checkFieldString(this.state.fieldKey)) {
        this.setState({
          evaluateError:true,
        })

    } else if((this.currentTabId === "field-edit-tab-single-selection" && !this.checkTableFill(this.state.singleTable)) 
    || (this.currentTabId === "field-edit-tab-multiple-selection" && (!this.checkTableFill(this.state.multiTable) || !this.checkTableValueKeyCompliant(this.state.multiTable) || !this.checkTableValueDuplicate(this.state.multiTable) ) ) ) {
      //check if there is one or more if this is a multiple selection
      this.setState({
        evaluateError:true,
      })
    
    } else if( (this.currentTabId==='field-edit-tab-number') && ( !isNumber(this.state.min) || !isNumber(this.state.max) ) ) {
      alert(i18n.t("editform.min-and-max-not-number"));

    } else {
      //now save
      //switch case about currentTabId
      let vars = {
        tableIdx:this.props.tableIdx,
        name : this.state.fieldName,
        key : this.state.fieldKey,
        isTitle: this.state.isTitle ? 1 : 0,
        isRequired : this.state.isRequired ? 1:0,
        fieldIdx : this.props.fieldIdx ?? 0,
        isSurvey : this.state.isSurvey ? 1:0,
        surveyDescription: this.state.surveyDescription,
      };

      switch(this.currentTabId) {
        case "field-edit-tab-text": 
          vars.description = this.state.fieldDescription;
          break;
        case "field-edit-tab-number" : 
          vars.description = this.state.fieldDescription;
          vars.isFloat = this.state.isFloat ? 1:0;
          vars.min = this.state.min;
          vars.max = this.state.max;
          break;
        case "field-edit-tab-single-selection" :
          vars.description = this.state.fieldDescription;
          vars.table = JSON.stringify(this.state.singleTable);
          break;
        case "field-edit-tab-multiple-selection" :
          vars.description = this.state.fieldDescription;
          vars.table = JSON.stringify(this.state.multiTable);
          break;
        case "field-edit-tab-long-text" :
          vars.description = this.state.fieldDescription;
          break;
        case "field-edit-tab-date" :
          vars.description = this.state.fieldDescription;
          break;
        case "field-edit-tab-datetime" :
          vars.description = this.state.fieldDescription;
          break;
        case "field-edit-tab-time" : 
          vars.description = this.state.fieldDescription;
          break;
        default:break;

      }
      vars.type = typeFromString(this.currentTabId.slice(15), this.state.isFloat);
      this.setState({saveLoading:true});

      postWithToken('editform/saveField.php',vars,(data) => {
        let idx = parseInt(data.idx);
        this.props.onSave(this.props.sectionIndex, idx, vars.name, vars.type, vars.isSurvey);
        this.props.onClose();

      },(error, data) => {
        if(error ==='error.duplicate') {
          alert(i18n.t("error.duplicate-with-name",{name : data.duplicateName}))

        } else {
          alert(i18n.t(error));
        }

      }, (data)=> {
        this.setState({saveLoading:false});

      });


    }

  }

  onDelete = () => {
    // TODO : confirm then delete
    this.setState({
      confirmDeleteOpen:true,
    })
  }

  closeDeleteAlert = () => {
    this.setState({
      confirmDeleteOpen:false,
    })
  }

  onConfirmDelete = () => {
    if(this.state.saveLoading) return;

    this.setState({
      saveLoading:true
    })

    //do post
    postWithToken('editform/deleteField.php',{tableIdx:this.props.tableIdx, fieldIdx:this.props.fieldIdx},
    (data) => {
      this.setState({
        confirmDeleteOpen:false,
        saveLoading:false,
      })
      this.props.onDelete(this.props.sectionIndex, this.props.fieldIdx);
      this.props.onClose();

    }, null, (data)=>{

      this.setState({
        saveLoading:false
      })
    });



  }


  checkTableFill = (table) => {

    // on error, returns false
    /** check if this table (key-value pair) is not empty */
    /** check value = true will check all values to meet field-safe criteria */
    let tableArray = Array.from(table);
    if(tableArray.length > 0) {
      let error = false;
      tableArray.forEach((pair) => {
        if(!pair.key || !pair.value) error = true;
        // if(checkFieldString(pair.value) === false)  error = true;
      });
      if(error) return false; else return true;
    } else {
      return false;
    }
  }

  checkTableValueKeyCompliant = (table) => {
    let tableArray = Array.from(table);
    let error = false;
    tableArray.forEach((pair) => {
      if(checkFieldString(pair.value) === false)  error = true;
    });

    if(error) return false; else return true;
  }
  checkTableValueDuplicate = (table) => {
    //check for duplicate values (for checkbox)
    let tableArray = Array.from(table);
    let error = false;
    let valuesArray = [];
    tableArray.forEach((pair) => {
      valuesArray.push(pair.value);
    });
    //check for duplicate values (not allowed)
    if(checkIfDuplicateExists(valuesArray)) {
      error = true;
    }

    if(error) return false; else return true;

  }

  getErrorDisplayForTable = (table, checkValue = false) => {
    let errorMsg;
    if(!this.checkTableFill(table)) {
      errorMsg = i18n.t("editform.table-empty");
    } else if(checkValue && !this.checkTableValueKeyCompliant(table)) {
      errorMsg = i18n.t("editform.table-value-error");

    } else if(checkValue && !this.checkTableValueDuplicate(table)) {
      errorMsg = i18n.t("editform.table-value-duplicate-error");

    }
    return this.state.evaluateError && errorMsg && 
    <small className="warning-text">
      {errorMsg}
    </small>;
  }

  getSurveyCallout = () => {
    return <Callout className="field-dialog-survey-callout" icon={null} intent={Intent.PRIMARY}>
      {this.getBoolSwitch("isSurvey")} 
      {this.state.isSurvey && 
      <FormGroup 
        key={"field-input-survey-desc"} 
        label={i18n.t("editform.survey-label")} 
        labelFor={"field-input-survey-desc"} 
        labelInfo={i18n.t("general.optional")}>
        <TextArea style={{width:'100%'}} placeholder={i18n.t("editform.survey-helper")} id={"field-input-survey-desc"} value={this.state.surveyDescription} onChange={(e) => {this.changeField("surveyDescription", e.target.value)}} />
      </FormGroup>}
    </Callout>;

  }

  showTour = () => {
    this.setState({
      isTourOpen : true,
    })
  }
  closeTour = () => {
    this.setState({
      isTourOpen : false,
    })
  }
  onOpened = () => {
    // console.log(this.props.shouldDisplayHelper);
    if(this.props.shouldDisplayHelper===true) {
      this.setState({
        isTourOpen:true,
      });
    }
  }
  
  render() {

    const {isOpen, isNew, sectionIndex, fieldIdx, onClose, onSave} = this.props;
    if(this.current_isOpen !== isOpen) {
      this.current_isOpen = isOpen;
      this.reset(); //reset on close and reopen
    }

    const nameField = this.getInputField("fieldName", true);
    const keyField = this.getInputField("fieldKey", true);
    const nameAndKey = <div className="field-dialog-namekey-wrapper">
      {nameField}<span className="spacer" />{keyField}

    </div>
    const descriptionField = this.getInputField("fieldDescription");


    const TextPanel = this.getPanel("text", 
    [
      nameAndKey,
      descriptionField
    ], 
    [
      this.getBoolSwitch("isTitle"),
      this.getBoolSwitch("isRequired"),
      this.getSurveyCallout(),
    ]);

    const NumberPanel = this.getPanel("number", 
    [
      nameAndKey,
      descriptionField,
      this.getInputField("min", false),
      this.getInputField("max", false),
    ], 
    [
      this.getBoolSwitch("isFloat"),
      this.getBoolSwitch("isTitle"),
      this.getBoolSwitch("isRequired"),
      this.getSurveyCallout(),
    ]);

    const SingleSelectionPanel = this.getPanel("single-selection", 
    [
      nameAndKey,
      <KeyValueTable table={this.state.singleTable} onTableChange={(newState) => {this.onTableChanged('singleTable', newState)}} />,
      this.getErrorDisplayForTable(this.state.singleTable)
    ], 
    [
      this.getBoolSwitch("isTitle"),
      this.getBoolSwitch("isRequired"),
      this.getSurveyCallout(),
    ]);
    
    const MultipleSelectionPanel = this.getPanel("multiple-selection", 
    [
      nameAndKey,
      <KeyValueTable table={this.state.multiTable} onTableChange={(newState) => {this.onTableChanged('multiTable', newState)}} />,
      this.getErrorDisplayForTable(this.state.multiTable, true)
    ], 
    [
      this.getSurveyCallout(),
      // this.getBoolSwitch("isTitle"),
      // this.getBoolSwitch("isRequired"),
    ]);

    const LongTextPanel = this.getPanel("long-text", 
    [
      nameAndKey,
      descriptionField
    ], 
    [
      // this.getBoolSwitch("isTitle"),
      this.getBoolSwitch("isRequired"),
      this.getSurveyCallout(),
    ]);

    const DatePanel = this.getPanel("date", 
    [
      nameAndKey,
      descriptionField
    ], 
    [
      this.getBoolSwitch("isTitle"),
      this.getBoolSwitch("isRequired"),
      this.getSurveyCallout(),
    ]);

    const DateTimePanel = this.getPanel("datetime", 
    [
      nameAndKey,
      descriptionField
    ], 
    [
      this.getBoolSwitch("isTitle"),
      this.getBoolSwitch("isRequired"),
      this.getSurveyCallout(),
    ]);

    const TimePanel = this.getPanel("time", 
    [
      nameAndKey,
      descriptionField
    ], 
    [
      this.getBoolSwitch("isTitle"),
      this.getBoolSwitch("isRequired"),
      this.getSurveyCallout(),
    ]);

    const {fixedType} = this.state;

    const fieldDialogHelperSteps = [
      {
        selector : '.bp3-tab-list',
        content:<div>
          <div className="helper-title">{i18n.t("helper.field-type-title")}</div>
          {i18n.t("helper.field-type")}
          <img src={TypeImage} className="helper-image" />
        </div>,
      },
      {
        selector : '#input-group-fieldName',
        content:<div>
          <div className="helper-title">{i18n.t("helper.field-name-title")}</div>
          {i18n.t("helper.field-name")}
          <img src={NameImage} className="helper-image" />
        </div>,
      },
      {
        selector : '#input-group-fieldKey',
        content:<div>
          <div className="helper-title">{i18n.t("helper.field-key-title")}</div>
          {i18n.t("helper.field-key")}
          <img src={KeyImage} className="helper-image" />
        </div>,
      },
      {
        selector : '#input-group-fieldDescription',
        content:<div>
          <div className="helper-title">{i18n.t("helper.field-description-title")}</div>
          {i18n.t("helper.field-description")}
          <img src={DescriptionImage} className="helper-image" />
        </div>,
      },
      {
        selector : '.input-group-switch-isTitle',
        content:<div>
          <div className="helper-title">{i18n.t("helper.field-title-title")}</div>
          {i18n.t("helper.field-title")}
          <img src={TitleImage} className="helper-image" />
        </div>,
      },
      {
        selector : '.input-group-switch-isRequired',
        content:<div>
          <div className="helper-title">{i18n.t("helper.field-required-title")}</div>
          {i18n.t("helper.field-required")}
          <img src={RequiredImage} className="helper-image" />
        </div>,
      },
      {
        selector : '.input-group-switch-isSurvey',
        content:<div>
          <div className="helper-title">{i18n.t("helper.field-survey-title")}</div>
          {i18n.t("helper.field-survey")}
          <img src={SurveyImage} className="helper-image" />
        </div>,
      },
    ]

    return <Dialog isOpen={isOpen} onClose={onClose} icon="form" title={i18n.t(isNew ? "editform.new-field":"editform.edit-field")} onOpened={this.onOpened}>
      {this.state.loading ? <div className="field-dialog-spinner-wrapper"><Spinner /></div> : 
      <Tabs selectedTabId={fixedType !== null ? "field-edit-tab-"+fixedType : undefined} id="field-dialog-tabs" vertical={true} onChange={this.onTabChange}>
        <Tab disabled={fixedType !== 'text' && fixedType !== null} className="field-edit-dialog-tab" panelClassName="field-edit-dialog-panel" id="field-edit-tab-text" title={i18n.t("editform.field-text")} panel={TextPanel} />
        <Tab disabled={fixedType !== 'number' && fixedType !== null} className="field-edit-dialog-tab" panelClassName="field-edit-dialog-panel" id="field-edit-tab-number" title={i18n.t("editform.field-number")} panel={NumberPanel} />
        <Tab disabled={fixedType !== 'single-selection' && fixedType !== null} className="field-edit-dialog-tab" panelClassName="field-edit-dialog-panel" id="field-edit-tab-single-selection" title={i18n.t("editform.field-single-selection")} panel={SingleSelectionPanel} />
        <Tab disabled={fixedType !== 'multiple-selection' && fixedType !== null} className="field-edit-dialog-tab" panelClassName="field-edit-dialog-panel" id="field-edit-tab-multiple-selection" title={i18n.t("editform.field-multiple-selection")} panel={MultipleSelectionPanel} />
        <Tab disabled={fixedType !== 'long-text' && fixedType !== null} className="field-edit-dialog-tab" panelClassName="field-edit-dialog-panel" id="field-edit-tab-long-text" title={i18n.t("editform.field-long-text")} panel={LongTextPanel} />
        <Tab disabled={fixedType !== 'date' && fixedType !== null} className="field-edit-dialog-tab" panelClassName="field-edit-dialog-panel" id="field-edit-tab-date" title={i18n.t("editform.field-date")} panel={DatePanel} />
        <Tab disabled={fixedType !== 'datetime' && fixedType !== null} className="field-edit-dialog-tab" panelClassName="field-edit-dialog-panel" id="field-edit-tab-datetime" title={i18n.t("editform.field-datetime")} panel={DateTimePanel} />
        <Tab disabled={fixedType !== 'time' && fixedType !== null} className="field-edit-dialog-tab" panelClassName="field-edit-dialog-panel" id="field-edit-tab-time" title={i18n.t("editform.field-time")} panel={TimePanel} />
      </Tabs>}
      <div className={Classes.DIALOG_FOOTER} >
        <div className={Classes.DIALOG_FOOTER_ACTIONS} >
          <Button onClick={this.showTour} minimal={true} intent={Intent.SUCCESS}>{i18n.t("helper.helper-button")}</Button>
          <Button onClick={onClose} >{i18n.t("general.cancel")}</Button>
          {!isNew && <Button onClick={this.onDelete} loading={this.state.saveLoading} intent={Intent.DANGER}>{i18n.t("general.delete")}</Button>}
          <Button onClick={this.onSave} loading={this.state.saveLoading} intent={Intent.PRIMARY}>{i18n.t("general.save")}</Button>

        </div>
      </div>
      <Alert isOpen={this.state.confirmDeleteOpen} onCancel={this.closeDeleteAlert} 
      onConfirm={this.onConfirmDelete}
      
      cancelButtonText={i18n.t("general.cancel")}
      confirmButtonText={i18n.t("general.delete")}
      icon="trash"
      intent={Intent.DANGER} >
        {this.state.saveLoading === false && i18n.t("editform.confirm-delete-field")}
        {this.state.saveLoading && <Spinner />}
      </Alert>
      <Tour 
        steps={fieldDialogHelperSteps}
        isOpen={this.state.isTourOpen}
        onRequestClose={this.closeTour}
      />
     

    </Dialog>;
  }
}

export {FieldDialog};