import React from 'react';
import {Button, Dialog, Classes, Intent} from '@blueprintjs/core';
import ReactCodeInput from 'react-code-input';
import i18n from 'i18next';
import './VerificationDialog.css';

export function VerificationDialog(props) {
    return <Dialog isOpen={props.isOpen} autoFocus={true}>
        <div className={Classes.DIALOG_BODY}>
            <div className="verification-dialog-body">
                <div className="title-text">{i18n.t("verification.check-code")}</div>
                <div className="description">{i18n.t("verification.check-code-description")}</div>

                <ReactCodeInput 
                    type='text' 
                    fields={4} 
                    onChange={props.onChange} 
                    disabled={props.loading} 
                    value={props.value}
                    isValid={props.isValid} />
                    
                <div className='actions'>
                    <Button 
                        onClick={props.resend} 
                        minimal={true}
                        loading={props.resetLoading}
                         >
                        <span className="action-text">
                            {i18n.t("verification.resend")} 
                        </span>

                    </Button>

                    <Button 
                        onClick={props.cancel} 
                        minimal={true} >
                        <span className="action-text">
                            {i18n.t("general.cancel")} 
                        </span>

                    </Button>
                </div>
            </div>


        </div>


    </Dialog>
}
