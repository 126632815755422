export function tableIcon (color, small) {
  if(small===true) {

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9">
        <g id="그룹_68" data-name="그룹 68" transform="translate(-84 -202)">
          <g id="사각형_50" data-name="사각형 50" transform="translate(84 202)" fill="none" stroke={color} stroke-width="1">
            <rect width="12" height="9" rx="2" stroke="none"/>
            <rect x="0.5" y="0.5" width="11" height="8" rx="1.5" fill="none"/>
          </g>
          <path id="패스_45" data-name="패스 45" d="M2081.595,1370.344h10.193" transform="translate(-1996.673 -1164.678)" fill="none" stroke={color} stroke-width="0.5"/>
          <path id="패스_46" data-name="패스 46" d="M2086.444,1370.344v4.51" transform="translate(-1998.72 -1164.678)" fill="none" stroke={color} stroke-width="0.5"/>
          <path id="패스_47" data-name="패스 47" d="M2086.444,1370.344v4.51" transform="translate(-1996.408 -1164.678)" fill="none" stroke={color} stroke-width="0.5"/>
          <path id="패스_48" data-name="패스 48" d="M2086.444,1370.344v4.51" transform="translate(-1994.097 -1164.678)" fill="none" stroke={color} stroke-width="0.5"/>
          <path id="패스_49" data-name="패스 49" d="M2081.595,1370.344h10.193" transform="translate(-1996.673 -1162.366)" fill="none" stroke={color} stroke-width="0.5"/>
        </g>
      </svg>







    );
  } else {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 21 16">
            <g id="그룹_63" data-name="그룹 63" transform="translate(-84 -202)">
            <g id="사각형_50" data-name="사각형 50" transform="translate(84 202)" fill="none" stroke={color} stroke-width="2">
                <rect width="21" height="16" rx="4" stroke="none"/>
                <rect x="1" y="1" width="19" height="14" rx="3" fill="none"/>
            </g>
            <path id="패스_45" data-name="패스 45" d="M2081.595,1370.344h17.637" transform="translate(-1996 -1162)" fill="none" stroke={color} stroke-width="1"/>
            <path id="패스_46" data-name="패스 46" d="M2086.444,1370.344v7.8" transform="translate(-1996 -1162)" fill="none" stroke={color} stroke-width="1"/>
            <path id="패스_47" data-name="패스 47" d="M2086.444,1370.344v7.8" transform="translate(-1992 -1162)" fill="none" stroke={color} stroke-width="1"/>
            <path id="패스_48" data-name="패스 48" d="M2086.444,1370.344v7.8" transform="translate(-1988 -1162)" fill="none" stroke={color} stroke-width="1"/>
            <path id="패스_49" data-name="패스 49" d="M2081.595,1370.344h17.637" transform="translate(-1996 -1158)" fill="none" stroke={color} stroke-width="1"/>
            </g>
        </svg>
    );

  }

}

export function recordsIcon (color, small) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
  <g id="records" transform="translate(-80 -285)">
    <g id="사각형_41" data-name="사각형 41" transform="translate(80 285)" fill="none" stroke={color} stroke-width="1.5">
      <rect width="14" height="16" rx="3" stroke="none"/>
      <rect x="0.75" y="0.75" width="12.5" height="14.5" rx="2.25" fill="none"/>
    </g>
    <path id="패스_36" data-name="패스 36" d="M2074.157,1448.838h5.727" transform="translate(-1990.074 -1158.464)" fill="none" stroke={color} stroke-linecap="round" stroke-width="1"/>
    <path id="패스_43" data-name="패스 43" d="M2074.157,1448.838h5.727" transform="translate(-1990.074 -1156.009)" fill="none" stroke={color} stroke-linecap="round" stroke-width="1"/>
    <path id="패스_44" data-name="패스 44" d="M2074.157,1448.838h4.346" transform="translate(-1990.074 -1153.555)" fill="none" stroke={color} stroke-linecap="round" stroke-width="1"/>
  </g>
</svg>;

}

export function editFormIcon(color) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
    <g id="그룹_11" data-name="그룹 11" transform="translate(-81 -503)">
      <g id="사각형_42" data-name="사각형 42" transform="translate(81 503)" fill="#fff" stroke={color} stroke-width="2">
        <rect width="16" height="6" rx="3" stroke="none"/>
        <rect x="1" y="1" width="14" height="4" rx="2" fill="none"/>
      </g>
      <g id="사각형_43" data-name="사각형 43" transform="translate(81 511)" fill="#fff" stroke={color} stroke-width="2">
        <rect width="16" height="6" rx="3" stroke="none"/>
        <rect x="1" y="1" width="14" height="4" rx="2" fill="none"/>
      </g>
    </g>
  </svg>;

}
export function uploadIcon(color) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="12.822" height="14.301" viewBox="0 0 12.822 14.301">
      <path id="패스_39" data-name="패스 39" d="M5.661,0l5.661,6.793H8.251V12.8H3.244V6.793H0Z" transform="translate(0.75 0.75)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    </svg>

 
}
export function downloadIcon(color) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="12.822" height="14.301" viewBox="0 0 12.822 14.301">
  <path id="패스_58" data-name="패스 58" d="M5.661,0l5.661,6.793H8.251V12.8H3.244V6.793H0Z" transform="translate(12.072 13.551) rotate(180)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
</svg>


 
}
export function coworkerIcon(color) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9">
    <g id="그룹_10" data-name="그룹 10" transform="translate(-82 -564)">
      <g id="타원_9" data-name="타원 9" transform="translate(82 564)" fill="none" stroke={color} stroke-width="1.5">
        <circle cx="4.5" cy="4.5" r="4.5" stroke="none"/>
        <circle cx="4.5" cy="4.5" r="3.75" fill="none"/>
      </g>
      <g id="타원_10" data-name="타원 10" transform="translate(87 564)" fill="none" stroke={color} stroke-width="1.5">
        <circle cx="4.5" cy="4.5" r="4.5" stroke="none"/>
        <circle cx="4.5" cy="4.5" r="3.75" fill="none"/>
      </g>
    </g>
  </svg>


}
export function settingIcon(color) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <g id="그룹_69" data-name="그룹 69" transform="translate(-82 -673)">
      <g id="타원_11" data-name="타원 11" transform="translate(82 673)" fill="none" stroke={color} stroke-width="1.5">
        <circle cx="6" cy="6" r="6" stroke="none"/>
        <circle cx="6" cy="6" r="5.25" fill="none"/>
      </g>
      <g id="타원_12" data-name="타원 12" transform="translate(84 675)" fill="none" stroke={color} stroke-width="1">
        <circle cx="4" cy="4" r="4" stroke="none"/>
        <circle cx="4" cy="4" r="3.5" fill="none"/>
      </g>
    </g>
  </svg>


}