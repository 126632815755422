import { DateInput, IDateFormatProps } from "@blueprintjs/datetime";
import moment from "moment";
export const jsDateFormatter: IDateFormatProps = {
    // note that the native implementation of Date functions differs between browsers
    formatDate: date => date.toLocaleDateString(),
    parseDate: str => new Date(str),
    highlightCurrentDay : true
};
 
export function getMomentFormatter(format: string): IDateFormatProps {
    // note that locale argument comes from locale prop and may be undefined
    return {
        formatDate: (date, locale) => moment(date).format(format),
        parseDate: (str, locale) => moment(str, format).toDate(),
    }
};