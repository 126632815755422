import React from 'react';
import {Link } from 'react-router-dom';
import './MenuButton.css';
import i18n from "i18next";
import { authenticationService } from '../_services';
import {history} from "../_helpers";

class MenuButton extends React.Component {
    navigateTo = (url) => {

        //erase localstorage
        localStorage.removeItem("records_state");
        localStorage.removeItem("records_filters");
        localStorage.removeItem("records_clicked");
        localStorage.removeItem("records_scrollTop");

        // if(url === '/records') {
            
        // }
        if("/"+this.props.currentMenu === url) {
            //same place again, so reload
            window.location.reload();
        } else {
            history.push(url);

        }

    }
    render() {
        var icon;

        if(this.props.id === 'records') {
            icon = <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
                <g id="records" transform="translate(-80 -285)">
                <g id="사각형_41" data-name="사각형 41" transform="translate(80 285)" fill="none" className="stroke-path" strokeWidth="1.5">
                    <rect width="14" height="16" rx="3" stroke="none"/>
                    <rect x="1" y="1" width="12" height="14" rx="2" fill="none"/>
                </g>
                <path id="패스_36" data-name="패스 36" d="M2074.157,1448.838h5.727" transform="translate(-1990.074 -1158.464)" fill="none" className="stroke-path" strokeLinecap="round" strokeWidth="1"/>
                <path id="패스_43" data-name="패스 43" d="M2074.157,1448.838h5.727" transform="translate(-1990.074 -1156.009)" fill="none" className="stroke-path" strokeLinecap="round" strokeWidth="1"/>
                <path id="패스_44" data-name="패스 44" d="M2074.157,1448.838h4.346" transform="translate(-1990.074 -1153.555)" fill="none" className="stroke-path" strokeLinecap="round" strokeWidth="1"/>
                </g>
            </svg>
          
        } else if(this.props.id === 'upload-data') {
            icon = <svg xmlns="http://www.w3.org/2000/svg" width="13.322" height="14.801" viewBox="0 0 13.322 14.801">
                <path id="패스_39" data-name="패스 39" d="M5.661,0l5.661,6.793H8.251V12.8H3.244V6.793H0Z" transform="translate(1 1)" fill="none" className="stroke-path" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </svg>
          
          
        } else if(this.props.id === 'download-data') {
            icon = <svg xmlns="http://www.w3.org/2000/svg" width="13.322" height="14.801" viewBox="0 0 13.322 14.801">
                <path id="패스_58" data-name="패스 58" d="M5.661,0l5.661,6.793H8.251V12.8H3.244V6.793H0Z" transform="translate(12.322 13.801) rotate(180)" fill="none" className="stroke-path" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </svg>
          
          
          
        } else if(this.props.id === 'edit-form') {
            icon = <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                <g id="그룹_11" data-name="그룹 11" transform="translate(-81 -503)">
                <g id="사각형_42" data-name="사각형 42" transform="translate(81 503)" fill="none" className="stroke-path" strokeWidth="1.5">
                    <rect width="13" height="6" rx="3" stroke="none"/>
                    <rect x="1" y="1" width="11" height="4" rx="2" fill="none"/>
                </g>
                <g id="사각형_43" data-name="사각형 43" transform="translate(81 510)" fill="none" className="stroke-path" strokeWidth="1.5">
                    <rect width="13" height="6" rx="3" stroke="none"/>
                    <rect x="1" y="1" width="11" height="4" rx="2" fill="none"/>
                </g>
                </g>
            </svg>
          
          
          
          
          
        } else if(this.props.id === 'co-workers') {
            icon = <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9">
                <g id="그룹_10" data-name="그룹 10" transform="translate(-82 -564)">
                <g id="타원_9" data-name="타원 9" transform="translate(82 564)" fill="none" className="stroke-path" strokeWidth="1.5">
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none"/>
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none"/>
                </g>
                <g id="타원_10" data-name="타원 10" transform="translate(87 564)" fill="none" className="stroke-path" strokeWidth="1.5">
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none"/>
                    <circle cx="4.5" cy="4.5" r="3.75" fill="none"/>
                </g>
                </g>
            </svg>
          
          
          
          
        } else if(this.props.id === 'settings') {
            icon = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                <g id="그룹_69" data-name="그룹 69" transform="translate(-82 -673)">
                <g id="타원_11" data-name="타원 11" transform="translate(82 673)" fill="none" className="stroke-path" strokeWidth="1.5">
                    <circle cx="6" cy="6" r="6" stroke="none"/>
                    <circle cx="6" cy="6" r="5.25" fill="none"/>
                </g>
                <g id="타원_12" data-name="타원 12" transform="translate(84 675)" fill="none" className="stroke-path" strokeWidth="1">
                    <circle cx="4" cy="4" r="4" stroke="none"/>
                    <circle cx="4" cy="4" r="3.5" fill="none"/>
                </g>
                </g>
            </svg>
        }else if(this.props.id ==='sign-out') {
            icon = <svg xmlns="http://www.w3.org/2000/svg" width="12.142" height="14" viewBox="0 0 12.142 14">
                <g id="그룹_71" data-name="그룹 71" transform="translate(-83 -718)">
                <g id="사각형_69" data-name="사각형 69" transform="translate(83 718)" fill="none" className="stroke-path" strokeWidth="1.5">
                    <rect width="8" height="14" rx="3" stroke="none"/>
                    <rect x="0.75" y="0.75" width="6.5" height="12.5" rx="2.25" fill="none"/>
                </g>
                <g id="그룹_70" data-name="그룹 70" transform="translate(-1)">
                    <path id="패스_59" data-name="패스 59" d="M2072.866,3114h6.754" transform="translate(-1984 -2389)" fill="none" className="stroke-path" strokeLinecap="round" strokeWidth="1"/>
                    <path id="패스_60" data-name="패스 60" d="M2078,3111.385l1.642,2.607-1.642,2.582" transform="translate(-1984 -2389)" fill="none" className="stroke-path" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
                </g>
                </g>
            </svg>
            return <div className="menu-button" onClick={()=>{ authenticationService.logout(true); }}>
                <div>
                    {icon}
                    {i18n.t("menu."+this.props.id)}

                </div>

            </div>;
           
        
          
          

        } else {
            return (<div></div>);

        }

        return (
            <div onClick={()=>this.navigateTo("/"+this.props.id)}  className={"menu-button" + (this.props.currentMenu === this.props.id ? " active" : "")}>
                <div>
                    {icon}
                    {i18n.t("menu."+this.props.id)}
                </div>


            </div>
        );
    }
}

export {MenuButton}