import React from 'react';
import {Card, Icon} from '@blueprintjs/core';
import './StepCard.css';

function StepCard(props) {
    return <Card elevation="3" className={"step-card" + (props.disabled ? " disabled" : "")}>
        <span className="step">STEP {props.step}</span>
        <span className="title">{props.title}</span>
        <div className="subtitle">{props.subtitle}</div>
        <div>
            {props.children}
        </div>
    </Card>;
}
function DownArrow() {
    return <div align="center">

        <Icon icon="caret-down" iconSize={30} color="#DEDEDE" />
    </div>;
}

export {StepCard, DownArrow}