export function stringForType(type) {
  /*
  0 : string
  1 : int
  2 : float
  3 : radio
  4 : checkbox group
  5 : textarea
  6: date only
  7: date and time
  8 : time only
  */
  if(typeof(type) !=="number") {
    type = parseInt(type);
  }

  switch(type) {
    case 0 : return "type.string";
    case 1 : return "type.int";
    case 2 : return "type.float";
    case 3 : return "type.radio";
    case 4 : return "type.checkbox";
    case 5 : return "type.textarea";
    case 6 : return "type.date";
    case 7 : return "type.datetime";
    case 8 : return "type.time";
    default: return "";
  }
}

export function checkFieldString(string) {
  let patt = /^(\w|_)+$/;
  return patt.test(string);
}
export function checkIfDuplicateExists(w){
  return new Set(w).size !== w.length 
}

export function typeFromString(string, isFloat=false) {
  /**
   * 0 : string
1 : int
2 : float
3 : radio
4 : checkbox group
5 : textarea
6: date only
7: date and time
8 : time only
   */
  switch(string) {
    case "text" : return 0;
    case "number" : if(isFloat) return 2; else return 1;
    case "single-selection" : return 3;
    case "multiple-selection" : return 4;
    case "long-text" : return 5;
    case "date" : return 6;
    case "datetime" : return 7;
    case "time" : return 8;
    default : return 0;
  }
}