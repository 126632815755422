import React from 'react';
import {tableIcon} from '../_components';
import {history,  postWithToken, NetworkToaster, getPermissionIcon, getPermissionTitle } from "../_helpers";
import { authenticationService } from '../_services';
import { Intent, H5, ControlGroup, Alert, Button,  Classes, Popover, Card, Elevation, Icon, Checkbox } from "@blueprintjs/core";
import './HomePage.css';
import i18n from 'i18next';
import { Form, Field, Formik, ErrorMessage} from 'formik';

import queryString from 'query-string';

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: authenticationService.currentUserValue,
            tables : [],
            loading : false,
            addError : null,
            renamePopoverIdx:null,
            archiveIdx:null,
            removeIdx:null,
            multi:false,
            invites:[],
        };

        this.props.onLoad();
    }

    loadTables = () => {

        this.setState({
            loading:true,
        })
        postWithToken("table/list.php", null,
        (data) => {
            this.setState({
                tables:data.tables,
                invites:data.invites,
                loading:false,
            })
        });
        
    }

    componentDidMount() {
        this.loadTables();


        //check if there is pre-defined email in the query area
        const query = queryString.parse(this.props.location.search);
        if(query.email !== undefined) {
            //has email
            //check if this is the same as authenticated email
            if(authenticationService.currentUserEmailValue !== query.email) {
                // console.log(query.email);
                //then logout
                authenticationService.logout();
                history.push(`/login?email=${query.email}`);

            } 
        }

    }
    setCurrentTable = (idx, tableName,multi, permission) => {
        this.props.setCurrentTable(idx, tableName, multi, permission);
        history.push('/records');
    }
    


    getCardForElement = (element)  => {
        /****
         * returns card element according to element
         * element is a element of this.state.tables
         */
        var footer;
        if(parseInt(element.permission) === 2) {
            //manager
            footer = <div className="table-card-footer-right" onClick={(e) => {e.stopPropagation()}}>
                <Popover 
                onClose={() => {
                    this.setState({
                        renamePopoverIdx:null,
                    })
                }}
                isOpen={this.state.renamePopoverIdx===parseInt(element.table_idx)} 
                popoverClassName={Classes.POPOVER_CONTENT_SIZING}  
                target={<Button minimal={true} icon="edit" small={true} onClick={() => {this.setState({renamePopoverIdx : parseInt(element.table_idx)})}} />} 
                content={this.getRenameMenu(element.table_idx, element.name)} 
                />
                <Button minimal={true} icon="archive" small={true} onClick={() => {
                    this.archiveTable(element.table_idx);
                }} />


            </div>
        } else {
            //user
            footer = <div className="table-card-footer-right" onClick={(e) => {e.stopPropagation()}}>
            <Button minimal={true} icon="log-out" small={true} onClick={() => {
                this.removeTable(element.table_idx);
            }} />


        </div> 
        }
        return <Card key={element.table_idx} className="table-card" interactive={true} elevation={Elevation.TWO}
        onClick={() => this.setCurrentTable(element.table_idx, element.name, element.multi > 0 ? "multi" : "single", parseInt(element.permission))}
       >
            <h3 className="bp3-heading" style={{wordWrap:'break-word'}} >
                {element.name}
            </h3>
            <div className="table-permission bp3-text-small">
                <img src={getPermissionIcon(element.permission)}  alt="permission icon"/>
                {getPermissionTitle(element.permission)}
            </div>
            <div className={"table-multi" + (element.multi > 0 ? " multi" : "")}>
                {element.multi > 0 ? i18n.t("table.multi") : i18n.t('table.single')}

            </div>
            <div className="table-card-footer">
               <div className="table-card-footer-left">
                    <div className="table-count">
                        <strong>{element.record_count}</strong>
                        <span className="bp3-text-small">{i18n.t("table.records")}</span>
                    </div>
                    <div className="table-count">
                        <strong>{element.field_count}</strong>
                        <span className="bp3-text-small">{i18n.t("table.fields")}</span>
                    </div>
               </div>
               {footer}

           </div>

       </Card>;

    }

    getRenameMenu = (idx, tableName) => {

        return <div className="table-card-popover-content">
            <h4 className="bp3-heading">{i18n.t("Rename table")}</h4>
            <small>{i18n.t("table.new-name")}</small>
            <div className="warning-text">{this.state.addError}</div>
            <Formik
            initialValues={
                {
                    tableName:tableName,
                }
            }
            validate = {(values) => {
                let errors ={};
                if(values.tableName.trim().length ===0) {
                    errors.tableName = i18n.t("table.name-required");
                } else if(values.tableName.trim() === tableName) {
                    errors.tableName = i18n.t("table.old-name");

                }
                return errors;
            }}
            onSubmit={
                ({tableName},{setState, setSubmitting}) => {
                    //submit
                    postWithToken('table/rename.php', {idx:idx, tableName:tableName}, (data)=> {
                        this.loadTables();
                        this.setState({
                            renamePopoverIdx:null,
                        });

                    }, (error) => {
                        this.setState({
                            addError:error,
                        });
                        setTimeout(() => {
                            this.setState({
                                addError:null,
                            });
                        }, 2000);
                    } , (data) => {
                    });
                }
            }
            >
                {({
                    isSubmitting
                }) => (
                    <Form>
                        <ErrorMessage name="tableName" component="div" className="warning-text bp3-text-small" />
                        <ControlGroup vertical={true}>
                            <Field name="tableName" type="text" class="bp3-input bp3-input-group" autoFocus={true} placeholder={i18n.t("table.eg")} />
                            <Button type="submit" loading={isSubmitting}  className="bp3-intent-primary">{i18n.t("table.rename")}</Button>
                        </ControlGroup>
                    </Form>
                )
                }
                
            </Formik>
            
            
        </div> 
    }

    archiveTable = (idx) => {
        idx = parseInt(idx);
        this.setState({
            archiveIdx:idx,
        });
    }
    

    handleArchiveCancel = () => {
        this.setState({
            archiveIdx:null,
        });

    }
    handleArchiveConfirm= () => {
        this.setState({
            archiveIdx:null,
            loading:true,
        });

        postWithToken('table/archive.php',{idx:this.state.archiveIdx, archived:1}, 
        (data)=> {
            this.loadTables();
            NetworkToaster.show({intent:Intent.SUCCESS, message:i18n.t("table.archive-success"), icon:"endorsed"});
        }, null, (data)=>{
            this.setState({
                loading:false,
            });
        });


    }


    removeTable = (idx) => {
        idx = parseInt(idx);
        this.setState({
            removeIdx:idx,
        });

    }
    handleRemoveCancel = () => {
        this.setState({
            removeIdx:null,
        });

    }
    handleRemoveConfirm= () => {
        this.setState({
            removeIdx:null,
            loading:true,
        });

        postWithToken('table/remove',{idx:this.state.removeIdx }, 
        (data)=> {
            this.loadTables();
            NetworkToaster.show({intent:Intent.SUCCESS, message:i18n.t("table.remove-success"), icon:"endorsed"});
        }, null, (data)=>{
            this.setState({
                loading:false,
            });
        });


    }


    /**
     * INVITE related
     */
    onDeclineInvite = (requestIdx) => {
        if(window.confirm(i18n.t("table.decline-invite-confirm"))) {
            this.setState({
                inviteLoading:requestIdx
            });
            postWithToken('table/respond',{
                requestIdx:requestIdx,
                accept:false,
            },() => {
                //successful
                //so reload everything
                this.loadTables();
            },null,() => {
                this.setState({inviteLoading:null});
            });
        }
    }

    onAcceptInvite = (requestIdx) => {
        this.setState({
            inviteLoading:requestIdx
        });
        postWithToken('table/respond',{
            requestIdx:requestIdx,
            accept:true,
        },() => {
            //successful
            //so reload everything
            this.loadTables();
        },null,() => {
            this.setState({inviteLoading:null});
        });

    }






    render() {

        /******
         * Make components
         */

        /* add Button displayed at the end of list */
        let addButton = (<Card className="table-card add" interactive={true} elevation={Elevation.ONE}>
            <svg xmlns="http://www.w3.org/2000/svg" width="19.266" height="19.266" viewBox="0 0 19.266 19.266">
                <g id="그룹_8" data-name="그룹 8" transform="translate(-1257.99 -386.213)">
                    <path id="패스_33" data-name="패스 33" d="M3252.489,382h14.266" transform="translate(-1992 13.846)" fill="none" stroke="#bfbfbf" stroke-linecap="round" stroke-width="5"/>
                    <path id="패스_34" data-name="패스 34" d="M0,0H14.266" transform="translate(1267.622 388.713) rotate(90)" fill="none" stroke="#bfbfbf" stroke-linecap="round" stroke-width="5"/>
                </g>
            </svg>
        </Card>);

        /* popover displayed when add button clicked */
        let popOver = (
            <div className="table-card-popover-content">
                <h4 className="bp3-heading">{i18n.t("table.table-name")}</h4>
                
                
                <Formik
                initialValues={
                    {
                        tableName:'',
                    }
                }
                validate = {(values) => {
                    let errors ={};
                    if(values.tableName.trim().length ===0) {
                        errors.tableName = i18n.t("table.name-required");
                    } 
                    return errors;
                }}
                // validationSchema={Yup.object().shape({
                //     tableName: Yup.string().required(i18n.t("Name is required!")),
                // })}
                onSubmit={
                    ({tableName},{setState, setSubmitting}) => {
                        //submit
                        postWithToken('table/create.php', {tableName:tableName, multi:this.state.multi}, (data) => {
                            //success
                            this.setCurrentTable(data.data.idx, tableName, this.state.multi ? "multi" : "single", 2);
                            this.setState({multi:false});
                        }, (error) => {
                            //error
                            this.setState({
                                addError:error, 
                                multi:false,
                             });
                             setTimeout(() => {
                                this.setState({
                                    addError:null,
                                })
                            }, 2000);
                             
                        }, (data) => {
                            //common
                            setSubmitting(false);
                        });

                        

                    }

                }
                >
                    {({
                        isSubmitting

                    }) => (
                        <Form>
                            <Checkbox checked={this.state.multi} onChange={(e)=>this.setState({multi:e.target.checked})} label={i18n.t("table.is-multi")} />
                            <small>{i18n.t("table.name-of-table")}</small>
                            <ErrorMessage name="tableName" component="div" className="warning-text bp3-text-small" />
                            <div className="warning-text">{this.state.addError}</div>
                            <ControlGroup vertical={true}>
                                <Field name="tableName" type="text" class="bp3-input bp3-input-group" autoFocus={true} placeholder={i18n.t("table.eg")} />
                                <Button type="submit" loading={isSubmitting}  className="bp3-intent-primary">{i18n.t("general.add")}</Button>
                            </ControlGroup>
                        </Form>

                    )

                    }
                    
                </Formik>
                
                
            </div>

        );

        /* empty box displayed when there is no table */
        let emptyBox = (
            <Card className={"table-card" + (this.state.loading ? "" : " empty")} interactive={false} elevation={Elevation.ZERO}
            >
                <h3 className={"bp3-heading" + (this.state.loading ? " bp3-skeleton" : "")}>{i18n.t("table.start")}</h3>
                <span className={(this.state.loading ? " bp3-skeleton" : "")}>{i18n.t("table.start-description")} </span>
            </Card>
        );

        const { currentUser, tables } = this.state;

        

        /*******
         * 
         * RENDER
         * 
         */
        return ( 
                <div id="body" className={(this.state.loading ? "loading" : "")}>
                    <h4 className="bp3-heading title">
                        {tableIcon('#333')} TABLES
                    </h4>

                    {this.state.invites && this.state.invites.length > 0 && <div id="invite-list">
                        <H5>📬 Invites</H5>
                        <div class="list">
                            {this.state.invites.map(invite => <InviteCard {...invite} 
                                loading={invite.request_idx === this.state.inviteLoading}
                                onDecline={() => this.onDeclineInvite(invite.request_idx)} 
                                onAccept={() => this.onAcceptInvite(invite.request_idx)} />)}

                        </div>

                    </div>}

                    <div id="table-list">
                        {tables.length > 0 ? tables.map(element => this.getCardForElement(element)) : emptyBox}                        
                        <Popover popoverClassName={Classes.POPOVER_CONTENT_SIZING} className="table-add-popover" target={addButton} content={popOver} />
                            
                        
                    </div>

                    <Alert
                        cancelButtonText={i18n.t("general.cancel")}
                        confirmButtonText={i18n.t("general.archive")}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={this.state.archiveIdx !== null}
                        onCancel={this.handleArchiveCancel}
                        onConfirm={this.handleArchiveConfirm}
                    >
                        <p>
                            {i18n.t("table.archive-confirm-text")}
                        </p>
                    </Alert>

                    <Alert
                        cancelButtonText={i18n.t("general.cancel")}
                        confirmButtonText={i18n.t("table.remove-table")}
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={this.state.removeIdx !== null}
                        onCancel={this.handleRemoveCancel}
                        onConfirm={this.handleRemoveConfirm}
                    >
                        <p>
                            {i18n.t("table.remove-confirm-text")}
                        </p>
                    </Alert>
                </div>
        );
    }
}

function InviteCard(props) {
    return <Card className="invite-card">
        <span class="invited-by">{i18n.t("table.invited-by")} <b>{props.from_name}</b></span>
        <span class="table-name">{props.name}</span>
        <span class="invite-permission">
            <img src={getPermissionIcon(props.permission)}  alt="permission icon"/>
            {getPermissionTitle(props.permission)}
        </span>
        <div className="invite-actions">
            <Button disabled={props.loading} onClick={props.onDecline} minimal={true} small={true}>{i18n.t("general.delete")}</Button>
            <Button disabled={props.loading} onClick={props.onAccept} intent={Intent.PRIMARY} small={true}>{i18n.t("general.confirm")}</Button>
        </div>
    </Card>
}

export {HomePage};