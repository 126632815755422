import React from 'react';
import i18n from 'i18next';
import {FormGroup, H1, H3, Dialog,Tag, Callout, Switch, H4, Classes, Button, Card, H5, Icon, InputGroup, Intent, Popover, PopoverInteractionKind, Keys, EditableText, TextArea } from '@blueprintjs/core';
import '../../node_modules/@blueprintjs/select/lib/css/blueprint-select.css';
import { Select } from '@blueprintjs/select';
import { settingIcon, VerificationDialog } from '../_components';
import {checkSurveyUrl, getPermissionIcon, getPermissionTitle, history, NetworkToaster, permissionSelectFieldRenderer, postWithToken, validateEmail, validatePassword} from '../_helpers';
import ReactCodeInput from 'react-code-input';
import './LostpassPage.css';
import queryString from 'query-string';
import SolutionImage from '../assets/illustrations/solution.svg';
import CompleteImage from '../assets/illustrations/lostpass-complete.svg';

const lostpass_initialState = {
    email : "",
    emailError:false,
    loading:false,
    currentPage:0,
    /** verification  */
    verificationCode:"",
    resendLoading:false,
    password:"",
    verifyPassword:"",
    signupErrors:{},
};

class LostpassPage extends React.Component {

    constructor(props) {
        super(props);

        let initialState = lostpass_initialState;
        this.needSubmitInitially = false;

        const query = queryString.parse(this.props.location.search);
        if(query.email !== undefined) {
            //has email, so set this as the value for signup/login
            initialState.email = query.email;
            if(query.code !== undefined) {
                initialState.verificationCode = query.code
                initialState.currentPage = 'verification';

            } else {
                initialState.currentPage = 0;

            }
            this.needSubmitInitially = true;

        }

        this.state = initialState;
    }

    componentDidMount() {
        //check if we should initial load;
        if(this.needSubmitInitially) {
            this.submit();
        }
    }


    changeValue = (key,value, additionalFunction) => {
        this.setState({
            [key] : value,
            emailError:false,
        },additionalFunction);
    }



    submit = (e) => {
        if(e) e.preventDefault();

        // do something
        const {currentPage } = this.state;

        if(currentPage===0) {
            //email stage
            //validate email
            if(validateEmail(this.state.email) === false) {
                //error
                this.setState({
                    emailError:true
                })
            } else {
                //success!

                //
                this.setState({
                    loading:true,
                });

                postWithToken('login/lostpass',{
                    email:this.state.email
                },(data) => {
                    
                    this.setState({
                        currentPage:'verification',
                    });


                },null, () => this.setState({loading:false}));

            }
        } else if(currentPage === 'verification') {
            //verify!
            if(this.state.verificationCode.length === 4) {
                this.setState({loading:true});
                //then really verify!
                postWithToken('login/lostpassVerify', {
                    email:this.state.email,
                    code:this.state.verificationCode,
                },(data) => {
                    //goto agreement page
                    this.setState({
                        currentPage:'change',
                    });
                },null,()=> this.setState({loading:false}));
            }
        } else if(currentPage === 'change') {
            //change pw
            let signupErrors = {};

            const { email, password, verifyPassword, verificationCode} = this.state;
            if(password.length === 0) {
                signupErrors.password = i18n.t("signup.signup-error-empty-password");

            } else if(validatePassword(password) === false) {
                signupErrors.password = i18n.t("signup.password-requirement");


            } else if(password!==verifyPassword) {
                signupErrors.verifyPassword= i18n.t("signup.signup-error-password-mismatch");

            }

            this.setState({
                signupErrors:signupErrors
            });

            if(Object.keys(signupErrors).length === 0) {
                //no error, so submit
                this.setState({loading:true});
                postWithToken("login/lostpassChange", {
                    email:email,
                    code:verificationCode,
                    password:password,
                },(data) => {
                    this.setState({
                        currentPage:'congratulations',
                    });

                },null,() => {

                    this.setState({loading:false});
                });
            }
        } else if(currentPage === 'congratulations') {
            history.push(`/login?email=${this.state.email}`);
        }

    }


    resendVerification = () => {
        this.setState({
            resendLoading:true
        });

        postWithToken('login/lostpass',{
            email:this.state.email
        },() => {
            NetworkToaster.show({message:i18n.t("verification.check-code"), intent:Intent.SUCCESS, icon:"tick"});

        },null,() => this.setState({resendLoading:false}));

    }

    cancelVerification = () => {

        //go back to email input phase
        this.setState({currentPage:0});
    }

    checkVerification = () => {
        if(this.state.verificationCode.length === 4) {
            //then go check!
            this.submit();

        }
    }
    
    /** general */

    getBody = () => {
        const {currentPage} = this.state;

        if(currentPage === 0) {
            return [
                <img id="lostpass-image" src={SolutionImage} />,
                <H1>
                    {i18n.t("login.lost-pass-title")}
                </H1>,
                <div className="step">
                    {i18n.t("login.lost-pass-subtitle")}
                </div>,
                <form className="inputs" onSubmit={this.submit}>
                    <div className="row">
                        <InputGroup 
                            type="email" 
                            autoFocus={true}
                            value={this.state.email}
                            placeholder={i18n.t("login.email")}
                            onChange={(e) => this.changeValue('email',e.target.value)}
                            intent={this.state.emailError ? Intent.DANGER:Intent.NONE}
                            large={true} />
                        <Button 
                            intent={Intent.PRIMARY} 
                            style={{marginLeft:'10px'}}
                            minimal={true} 
                            type="submit"
                            loading={this.state.loading}
                            
                            icon="send-message" />
                    </div>
                </form>
            ]
        

        } else if(currentPage ==='verification') {
            //show verification page
            return []//empty

        } else if(currentPage ==='change') {
            //completed verificaiton, change pw
            const {signupErrors} = this.state;
            return [
                <H1>
                    {i18n.t("login.lost-pass-new-pass-title")}
                </H1>,
                <div className="step">
                    {i18n.t("signup.signup-subtitle")}
                </div>,
                <form className="inputs" onSubmit={this.submit}>
                    
                    <FormGroup
                        label={i18n.t("signup.password")}
                        labelFor="password-input"
                        intent={signupErrors.password? Intent.DANGER : Intent.NONE}
                        helperText={signupErrors.password ? signupErrors.password : i18n.t("signup.password-requirement")}
                    >
                        <InputGroup 
                            type="password" 
                            id="password-input"
                            intent={signupErrors.password? Intent.DANGER : Intent.NONE}
                            value={this.state.password}
                            onChange={(e) => this.changeValue('password',e.target.value)}
                            autoFocus={true}
                            large={true} />

                    </FormGroup>
                    <FormGroup
                        label={i18n.t("signup.verify-password")}
                        labelFor="password-input-2"
                        intent={signupErrors.verifyPassword? Intent.DANGER : Intent.NONE}
                        helperText={signupErrors.verifyPassword}
                    >
                        <InputGroup 
                            type="password" 
                            id="password-input-2"
                            intent={signupErrors.verifyPassword? Intent.DANGER : Intent.NONE}
                            value={this.state.verifyPassword}
                            onChange={(e) => this.changeValue('verifyPassword',e.target.value)}
                            large={true} />

                    </FormGroup>
                    
                    
                    <Button 
                        intent={Intent.PRIMARY} 
                        type="submit"
                        loading={this.state.loading}
                        text={i18n.t("general.save")}
                        />
            </form>
            ]
        } else if(currentPage ==='congratulations') {
            return [
                <img id="lostpass-image" src={CompleteImage} />,
                <H1>
                    {i18n.t("login.lost-pass-congratulations-title")}
                </H1>,
                <div className="step">
                    {i18n.t("login.lost-pass-congratulations-subtitle")}
                </div>,
                <Button style={{marginTop:"20px"}} intent={Intent.PRIMARY} text={i18n.t("signup.congratulations-submit")} large={true} onClick={this.submit} />

            ];
        } else {
            //what?
            return [];
        }

    }

    render() {




        return <div id="lostpass-body-bg" className={this.state.currentPage}>
            <VerificationDialog
                isOpen={this.state.currentPage==='verification'}
                onChange={(value) => this.changeValue('verificationCode',value,this.checkVerification)}
                loading={this.state.loading}
                resend={this.resendVerification}
                cancel={this.cancelVerification}
                value={this.state.verificationCode}
                resetLoading = {this.state.resendLoading}
                />
            <div id="lostpass-body" className={this.state.loading ? "loading" : ""}>
                {this.getBody()}
            </div>

        </div>        
    }
}

export {LostpassPage};