import React from 'react';
import i18n from 'i18next';

import { Callout, Dialog, H4, Tab, Tabs, Switch, Classes, Button, Intent, EditableText, H6, FormGroup, InputGroup, Popover, Tooltip } from '@blueprintjs/core';


class KeyValueTable extends React.Component {

    insertPair = () => {
        let newTable = Array.from(this.props.table);
        newTable.push({key:"", value:""});
        this.props.onTableChange(newTable);
    }
    removePair = (index) => {
        let newTable = Array.from(this.props.table);
        newTable.splice(index, 1);
        this.props.onTableChange(newTable);

    }

    changeKeyValue = (value, index) => {
        let newTable = Array.from(this.props.table);
        newTable[index].key = value;
        this.props.onTableChange(newTable); 
    }

    changeValueValue = (value, index) => {
        let newTable = Array.from(this.props.table);
        newTable[index].value= value;
        this.props.onTableChange(newTable); 
    }

    render() {
        /*
        Table structure
        [
            {
                key : key,
                value : value
            },
            ...
        ]
        */
        const { table } = this.props;
        if(!table || table.length ===0)  this.insertPair();
        return <table className="bp3-html-table bp3-html-table-condensed field-dialog-keyvalue-table">
            <thead>
            <tr>
                <th>{i18n.t("editform.display-name")}</th>
                <th>{i18n.t("editform.saved-value")}</th>
                <th className="min">
                    <Tooltip content={i18n.t("editform.add-pair-tooltip")} >
                        <Button small={true} onClick={this.insertPair} minimal={true} icon="add" intent={Intent.PRIMARY}></Button>
                    </Tooltip>
                </th>
            </tr>
    
            </thead>
            <tbody>
                {table.map((element, index) => <tr className="field-dialog-key-value-tr">
                    <td><EditableText onChange={(value) => this.changeKeyValue(value, index)} value={element.key} placeholder={i18n.t("editform.display-name-placeholder")} className="field-dialog-editable-text" /></td>
                    <td><EditableText onChange={(value) => this.changeValueValue(value, index)} value={element.value} placeholder={i18n.t("editform.saved-value-placeholder")} className="field-dialog-editable-text" /></td>
                    <td className="min">
                        <Button onClick={()=> this.removePair(index)} className="hidden-button" minimal={true} icon="cross" small={true} intent={Intent.DANGER}></Button> 
                    </td>
                </tr>)}

    
            </tbody>
    
        </table>;
    }
}
export {KeyValueTable}