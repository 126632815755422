import React, {useState} from 'react'
import { Draggable } from "react-beautiful-dnd";
import { Button, ButtonGroup, Card, Classes, Intent, Tag } from '@blueprintjs/core';
import './FieldCard.css';
import { stringForType } from '../_helpers';
import i18n from 'i18next';

function FieldCard(props) {
  const [state, setState] = useState();

  const {item, provided, ind, snapshot, onClick} = props;
  /***
   * element = {
   *  'name' : section title,
   *  'children' : [
   *    {'field_idx' / 'name' / 'type' / 'options' / 'required' / 'title_field'}
   *    ...
   *  ]
   *  
   * }
   * 
   */


  return(<div onClick={onClick} className={"field-card" + (snapshot.isDragging ? " active" : "")}
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    style={
      provided.draggableProps.style
    }
  >
    {item.survey > 0 && <Tag style={{marginRight:'10px'}} intent={Intent.PRIMARY}>survey</Tag>}
    <span className="field-card-name">{item.name}</span>
    <span className="field-card-type">{i18n.t(stringForType(item.type))}</span>
  </div>);
}

export {FieldCard}