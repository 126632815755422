
import React from 'react';
import i18n from 'i18next';
import {FormGroup, H1, H3, Dialog,Tag, Callout, Switch, H4, Classes, Button, Card, H5, Icon, InputGroup, Intent, Popover, PopoverInteractionKind, Keys, EditableText, TextArea } from '@blueprintjs/core';
import '../../node_modules/@blueprintjs/select/lib/css/blueprint-select.css';
import { Select } from '@blueprintjs/select';
import { settingIcon, VerificationDialog } from '../_components';
import {checkSurveyUrl, getPermissionIcon, getPermissionTitle, history, NetworkToaster, permissionSelectFieldRenderer, postWithToken, validateEmail, validatePassword} from '../_helpers';
import ReactCodeInput from 'react-code-input';
import './SignupPage.css';
import queryString from 'query-string';
import CompleteImage from '../assets/illustrations/meditate.svg';

const signup_state = {
    email:'',
    emailError:false,
    loading:false,
    organization:"",
    purpose:"",
    currentPage:0,
    alreadyRegistered:false,
    name:"",
    /** verification  */
    verificationCode:"",
    resendLoading:false,
    password:"",
    verifyPassword:"",
    signupErrors:{},

}

class SignupPage extends React.Component {
    constructor(props) {
        super(props);

        let initialState= signup_state;

        this.needSubmitInitially = false;

        const query = queryString.parse(this.props.location.search);
        if(query.email !== undefined) {
            //has email, so set this as the value for signup/login
            initialState.email = query.email;
            if(query.code !== undefined) {
                initialState.verificationCode = query.code
                initialState.currentPage = 'verification';

            } else {
                initialState.currentPage = 0;

            }
            this.needSubmitInitially = true;

        }


        this.state = initialState;



    }

    componentDidMount() {
        //check if we should initial load;
        if(this.needSubmitInitially) {
            this.submit();
        }
    }


    changeValue = (key,value, additionalFunction) => {
        this.setState({
            [key] : value,
            emailError:false,
        },additionalFunction);
    }

    

    submit = (e) => {
        if(e) e.preventDefault();

        // do something
        const {currentPage } = this.state;

        if(currentPage===0) {
            //email stage
            //validate email
            if(validateEmail(this.state.email) === false) {
                //error
                this.setState({
                    emailError:true
                })
            } else {
                //success!

                //
                this.setState({
                    loading:true,
                });

                postWithToken('signup/checkEmail',{
                    email:this.state.email
                },(data) => {
                    if(data.registered) {
                        if(data.accepted) {
                            //then email is sent! -> verification
                            this.setState({
                                currentPage:'verification',
                            });

                        } else {
                            //please wait!
                            this.setState({
                                currentPage:'application',
                                organization:data.organization,
                                purpose:data.purpose,
                                name:data.name,
                                alreadyRegistered:true,
                            });
                        }
                    } else {
                        //register!
                        this.setState({
                            currentPage:'application',
                        });
                    }

                },null, () => this.setState({loading:false}));

            }
        } else if(currentPage ==='application') {
            //apply!
            //save variables
            this.setState({
                loading:true,
            });
            postWithToken('signup/apply',{
                email:this.state.email,
                organization:this.state.organization,
                purpose:this.state.purpose,
                name:this.state.name,
            },(data) => {
                this.setState({
                    currentPage:'complete',
                });

            },null, () => this.setState({loading:false}));
        } else if(currentPage === 'verification') {
            //verify!
            if(this.state.verificationCode.length === 4) {
                this.setState({loading:true});
                //then really verify!
                postWithToken('signup/verifyEmail', {
                    email:this.state.email,
                    code:this.state.verificationCode,
                },(data) => {
                    //goto agreement page
                    this.setState({
                        currentPage:'agreement',
                        name:data.name,
                        organization:data.organization,
                    });
                },null,()=> this.setState({loading:false}));
            }
        } else if(currentPage === 'agreement') {
            this.setState({
                loading:true,
                
            }, () =>{
                setTimeout(() => {
                    this.setState({
                        currentPage:'signup',
                        loading:false,
                    })

                }, 400);
            });
        } else if(currentPage === 'signup') {
            //verify
            let signupErrors = {};

            const {email,name, organization, password, verifyPassword, verificationCode} = this.state;
            if(name.trim().length === 0) {
                signupErrors.name = i18n.t("signup.signup-error-empty-name");
            }
            if(password.length === 0) {
                signupErrors.password = i18n.t("signup.signup-error-empty-password");

            } else if(validatePassword(password) === false) {
                signupErrors.password = i18n.t("signup.password-requirement");


            } else if(password!==verifyPassword) {
                signupErrors.verifyPassword= i18n.t("signup.signup-error-password-mismatch");

            }

            this.setState({
                signupErrors:signupErrors
            });

            if(Object.keys(signupErrors).length === 0) {
                //no error, so submit
                this.setState({loading:true});
                postWithToken("signup/signup", {
                    email:email,
                    name:name,
                    code:verificationCode,
                    organization:organization,
                    password:password,
                },(data) => {
                    this.setState({
                        currentPage:'congratulations',
                    });

                },null,() => {

                    this.setState({loading:false});
                });
            }
        } else if(currentPage === 'congratulations') {
            history.push(`/login?email=${this.state.email}`);
        }

    }

    /** verification related */

    resendVerification = () => {
        this.setState({
            resendLoading:true
        });

        postWithToken('signup/checkEmail',{
            email:this.state.email
        },() => {
            NetworkToaster.show({message:i18n.t("verification.check-code"), intent:Intent.SUCCESS, icon:"tick"});

        },null,() => this.setState({resendLoading:false}));

    }

    cancelVerification = () => {

        //go back to email input phase
        this.setState({currentPage:0});
    }

    checkVerification = () => {
        if(this.state.verificationCode.length === 4) {
            //then go check!
            this.submit();

        }
    }
    

    /** general */

    getBody = () => {
        const {currentPage} = this.state;

        if(currentPage === 0) {
            return [
                <H1>
                    {i18n.t("signup.welcome")}
                </H1>,
                <div className="step">
                    {i18n.t("signup.step-email")}
                </div>,
                <div className="step-description">
                    {i18n.t("signup.step-email-description")}
                </div>,
                <form className="inputs" onSubmit={this.submit}>
                    <div className="row">
                        <InputGroup 
                            type="email" 
                            autoFocus={true}
                            placeholder={i18n.t("login.email")}
                            value={this.state.email}
                            onChange={(e) => this.changeValue('email',e.target.value)}
                            intent={this.state.emailError ? Intent.DANGER:Intent.NONE}
                            large={true} />
                        <Button 
                            intent={Intent.PRIMARY} 
                            style={{marginLeft:'10px'}}
                            minimal={true} 
                            type="submit"
                            loading={this.state.loading}
                            
                            icon="send-message" />
                    </div>
                </form>
            ]
        } else if(currentPage ==='application') {
            const {alreadyRegistered} = this.state;
            return [
                <H1>
                    {i18n.t(alreadyRegistered ? "signup.check-info":"signup.input-info")}
                </H1>,
                <div className="step">
                    {i18n.t(alreadyRegistered ? "signup.check-correct":"signup.tell-us-about-yourself")}
                </div>,
                <div className="step-description">
                    {i18n.t("signup.soon-as-we-can")}
                </div>,
                <form className="inputs" onSubmit={this.submit}>
                    <FormGroup
                        label={i18n.t("signup.name")}
                        labelFor="name-input"
                    >
                        <InputGroup 
                            type="text" 
                            id="name-input"
                            autoFocus={true}
                            value={this.state.name}
                            onChange={(e) => this.changeValue('name',e.target.value)}
                            large={true} />

                    </FormGroup>
                    <FormGroup
                        label={i18n.t("signup.organization")}
                        labelFor="org-input"
                    >
                        <InputGroup 
                            type="text" 
                            id="org-input"
                            value={this.state.organization}
                            onChange={(e) => this.changeValue('organization',e.target.value)}
                            large={true} />

                    </FormGroup>
                    <FormGroup
                        label={i18n.t("signup.purpose")}
                        labelFor="purpose-input"
                    >
                        
                        <InputGroup 
                            type="text" 
                            id="purpose-input"
                            value={this.state.purpose}
                            onChange={(e) => this.changeValue('purpose',e.target.value)}
                            large={true} />

                    </FormGroup>
                    
                    <Button 
                        intent={Intent.PRIMARY} 
                        type="submit"
                        loading={this.state.loading}
                        text={i18n.t("general.save")}
                         />
                </form>
            ]
        } else if(currentPage ==='complete') {
            //completed application
            return [
                <H1>
                    {i18n.t("signup.complete-title")}
                </H1>,
                <div className="step">
                    {i18n.t("signup.complete-subtitle")}
                </div>,
                <div className="step-description">
                    {i18n.t("signup.complete-description")}
                </div>,
                <Button 
                    style={{marginTop:'30px'}}
                    intent={Intent.PRIMARY}
                    large={true}
                    text={i18n.t("signup.goto-blog")}
                    onClick={()=>window.open('https://blog.redea.io','_blank')}
                />,
                <Button 
                    style={{marginTop:'10px'}}
                    intent={Intent.NONE}
                    minimal={true}
                    text={i18n.t("signup.contact-us")}
                    onClick={()=>window.location.href='mailto:redea.help@gmail.com'}
                />,
                <Button 
                    style={{marginTop:'10px'}}
                    intent={Intent.NONE}
                    minimal={true}
                    icon="home"
                    onClick={()=>history.push('/')}
                />
            ]

        } else if(currentPage ==='verification') {
            //show verification page
            return []//empty

        } else if(currentPage ==='agreement') {
            //completed verificaiton, get additional data
            return [
                <div id="agreement-box">
                    <div className="main-text">

                        {i18n.t("signup.agreement-title-1")} 
                    </div>
                    <div className="main-text-2">
                        {i18n.t("signup.agreement-title-2")} 
                    </div>
                    <div className="money">
                        {i18n.t("signup.agreement-money")} 
                    </div>
                    <div className="promise">
                        <div>
                            <Icon icon="endorsed" color="#0D8050" iconSize="23" />
                            <span className="text">
                                {i18n.t("signup.agreement-statement-1")}
                            </span>
                        </div>
                        <div>
                            <Icon icon="endorsed" color="#0D8050" iconSize="23" />
                            <span className="text">
                                {i18n.t("signup.agreement-statement-2")}
                            </span>
                        </div>
                        <div>
                            <Icon icon="endorsed" color="#0D8050" iconSize="23" />
                            <span className="text">
                                {i18n.t("signup.agreement-statement-3")}
                            </span>
                        </div>

                    </div>
                    <div className="actions">
                        <Button onClick={()=>window.open('https://redea.io/privacy?lang=' + i18n.language, '_blank')} minimal={true} text={i18n.t("signup.privacy")} large={true} />
                        <Button onClick={()=>window.open('https://redea.io/terms?lang=' + i18n.language, '_blank')} minimal={true} text={i18n.t("signup.terms")} large={true} />

                    </div>


                </div>,
                <Button onClick={this.submit} id="continue-button" text={i18n.t("signup.agree-and-continue")} intent={Intent.PRIMARY} large={true} style={{margin:'10px 50px'}} />
            ];
        } else if(currentPage ==='signup') {
            //completed verificaiton, get additional data
            const {signupErrors} = this.state;
            return [
                <H1>
                    {i18n.t("signup.signup-title")}
                </H1>,
                <div className="step">
                    {i18n.t("signup.signup-subtitle")}
                </div>,
                <div className="step-description">
                    {i18n.t("signup.signup-description")}
                </div>, 
                <form className="inputs" onSubmit={this.submit}>
                    <FormGroup
                        label={i18n.t("signup.email")}
                        labelFor="email-input"
                    >
                        <InputGroup 
                            type="email" 
                            disabled={true}
                            id="email-input"
                            value={this.state.email}
                            large={true}
                            />
                    </FormGroup>
                    <FormGroup
                        label={i18n.t("signup.password")}
                        labelFor="password-input"
                        intent={signupErrors.password? Intent.DANGER : Intent.NONE}
                        helperText={signupErrors.password ? signupErrors.password : i18n.t("signup.password-requirement")}
                    >
                        <InputGroup 
                            type="password" 
                            id="password-input"
                            intent={signupErrors.password? Intent.DANGER : Intent.NONE}
                            value={this.state.password}
                            onChange={(e) => this.changeValue('password',e.target.value)}
                            autoFocus={true}
                            large={true} />

                    </FormGroup>
                    <FormGroup
                        label={i18n.t("signup.verify-password")}
                        labelFor="password-input-2"
                        intent={signupErrors.verifyPassword? Intent.DANGER : Intent.NONE}
                        helperText={signupErrors.verifyPassword}
                    >
                        <InputGroup 
                            type="password" 
                            id="password-input-2"
                            intent={signupErrors.verifyPassword? Intent.DANGER : Intent.NONE}
                            value={this.state.verifyPassword}
                            onChange={(e) => this.changeValue('verifyPassword',e.target.value)}
                            large={true} />

                    </FormGroup>
                    <FormGroup
                        label={i18n.t("signup.name")}
                        labelFor="name-input"
                        intent={signupErrors.name ? Intent.DANGER : Intent.NONE}
                        helperText={signupErrors.name}
                    >
                        <InputGroup 
                            type="text" 
                            id="name-input"
                            intent={signupErrors.name ? Intent.DANGER : Intent.NONE}
                            value={this.state.name}
                            onChange={(e) => this.changeValue('name',e.target.value)}
                            large={true} />

                    </FormGroup>
                    <FormGroup
                        label={i18n.t("signup.organization")}
                        labelFor="org-input"
                    >
                        <InputGroup 
                            type="text" 
                            id="org-input"
                            value={this.state.organization}
                            onChange={(e) => this.changeValue('organization',e.target.value)}
                            large={true} />

                    </FormGroup>
                    
                    <Button 
                        intent={Intent.PRIMARY} 
                        type="submit"
                        loading={this.state.loading}
                        text={i18n.t("general.save")}
                         />
            </form>
            ]
        } else if(currentPage ==='congratulations') {
            return [
                <img id="congratulations-image" src={CompleteImage} />,
                <H1>
                    {i18n.t("signup.congratulations-title")}
                </H1>,
                <div className="step">
                    {i18n.t("signup.congratulations-subtitle")}
                </div>,
                <Button style={{marginTop:"20px"}} intent={Intent.PRIMARY} text={i18n.t("signup.congratulations-submit")} large={true} onClick={this.submit} />

            ];
        } else {
            //what?
            return [];
        }

    }

    render() {




        return <div id="signup-body-bg" className={this.state.currentPage}>
            <VerificationDialog
                isOpen={this.state.currentPage==='verification'}
                onChange={(value) => this.changeValue('verificationCode',value,this.checkVerification)}
                loading={this.state.loading}
                resend={this.resendVerification}
                cancel={this.cancelVerification}
                value={this.state.verificationCode}
                resetLoading = {this.state.resendLoading}
                 />
            <div id="signup-body" className={this.state.loading ? "loading" : ""}>
                {this.getBody()}
            </div>
    
        </div>        
    }
}

export {SignupPage}