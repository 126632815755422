import React from 'react';
import {Tooltip, Button, Intent, Position} from '@blueprintjs/core';
import './TemplateComponents.css';
import i18n from 'i18next';

function TitleMenu(props) {
    return <Tooltip intent={props.intent ?? Intent.NONE} disabled={props.disabled} content={i18n.t(`template.menu-${props.menu}`)} position={Position.TOP}>
       <Button intent={props.intent ?? Intent.NONE} disabled={props.disabled} onClick={props.onClick} minimal={true} icon={props.icon} /> 
    </Tooltip>
}


export {TitleMenu }