import {handleResponse, NetworkToaster} from "../_helpers";
import apiUrl from '../config';
import { authenticationService } from '../_services';
import i18n from "i18next";
import { isFunction } from "@blueprintjs/core/lib/esm/common/utils";
import { Intent } from "@blueprintjs/core";

export function postWithToken(url, body, success, error, common, formData = false) {
  // formData == true when file upload
  var headers = {};

  if(authenticationService.currentUserValue) {
    if(formData) {
      headers = {
        "X-TOKEN" : authenticationService.currentUserValue.token 
      };
    } else {
      headers = { 
        'Content-Type': 'application/json', 
        "X-TOKEN" : authenticationService.currentUserValue.token 
      };
    }

  }

  const requestOptions = {
    method: 'POST',
    cache: 'no-cache',
    headers: headers,
    body: formData ? body : JSON.stringify(body),
  };
  return fetch(`${apiUrl}/${url}`, requestOptions)
  .then(handleResponse)
  .then(data => {
    if(data.success === true) {
      if(isFunction(success)) success(data);
    } else {
      var errorMessage;
      if(data.message) {
        errorMessage = i18n.t("error."+data.message);
      } else {
        errorMessage = i18n.t("error.general");
      }
      if(isFunction(error)) {
        error(errorMessage, data);
      } else {
        NetworkToaster.show({message:errorMessage, intent:Intent.DANGER, icon:"warning-sign"});

      }
    }
    if(isFunction(common)) common(data);

  }).catch(e => {
    const errorMessage = i18n.t("error.general");
    if(isFunction(error)) {
      error(errorMessage, null);
    } else {
      NetworkToaster.show({message:errorMessage, intent:Intent.DANGER, icon:"warning-sign"});

    } 
    if(isFunction(common)) common(null);
  });
}

export function streamWithToken(url, token, vars, handlers) {
  // console.log(`stream start with : ${vars.history}`);
  vars['t'] = token;  

  const params = Object.entries(vars).map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');
  const urlWithParams = `${apiUrl}/${url}?${params}`;
  var source = new EventSource(urlWithParams);

  Object.entries(handlers).map(([eventName, eventHandler]) => source.addEventListener(eventName, eventHandler,false));
  return source;

}