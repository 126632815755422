export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
export const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

/**
 * Moves a section up
 */
export const moveSectionUp = (list, index) => {
  const sourceList = list;
  const [removed] = sourceList.splice(index,1);
  sourceList.splice(index-1,0,removed);
  return sourceList;
}

/**
 * Moves a section down 
 */
export const moveSectionDown = (list, index) => {
  const sourceList = list;
  const [removed] = sourceList.splice(index,1);
  sourceList.splice(index+1,0,removed);
  return sourceList;
}

/**
 * Deletes a section
 */
export const deleteSection = (list, index) => {
  const sourceList = list;
  sourceList.splice(index,1);
  return sourceList;

}

/**
 * Inserts a section
 */
export const addSection = (list, index, name) => {
  const sourceList = list;
  sourceList.splice(index,0,{
    name:name, children:[],
  });
  return sourceList;

}