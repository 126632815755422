import React from 'react';
import './CoWorkers.css';
import i18n from 'i18next';
import { coworkerIcon } from '../_components';
import { Classes, Button, Card, H5, Icon, InputGroup, Intent, Popover, PopoverInteractionKind, Keys } from '@blueprintjs/core';
import {getPermissionIcon, getPermissionTitle, history, NetworkToaster, permissionSelectFieldRenderer, postWithToken, validateEmail} from '../_helpers';
import '../../node_modules/@blueprintjs/select/lib/css/blueprint-select.css';
import { Select } from '@blueprintjs/select';

const coworkers_initialState = {
    loading:false,
    coworkers:[
        {name:"User Name", email:"asdf@asdf.com", permission:0, userIdx:9, request:false,},
        {name:"User N", email:"asdf12341234@asdf.com", permission:1, userIdx:9, request:false,},
        {name:"User Name Two", email:"asdf1234@asdf.com", permission:2, userIdx:9, request:false,},
    ],
    invitePermission:0,
    inviteEmail:'',
    isInviteOpen:false,
    invalidEmail:false,
    inviteLoading:false,
    deleteLoading:null,
    deleteLoadingIsRequest:false,
    changePermissionLoading:null,
    changePermissionIsRequest:false,
    searchString:"",
    filteredCoworkers:[
        {name:"User Name", email:"asdf@asdf.com", permission:0, userIdx:9, request:false,},
        {name:"User N", email:"asdf12341234@asdf.com", permission:1, userIdx:9, request:false,},
        {name:"User Name Two", email:"asdf1234@asdf.com", permission:2, userIdx:9, request:false,},

    ],


}

class CoWorkersPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = coworkers_initialState;
    }
    initializeState = () => {
        this.setState(coworkers_initialState);
    }
    componentDidMount() {
        this.populate();
    }

    populate = () => {
        this.setState({
            loading:true,
            filteredCoworkers:[
                {name:"User Name", email:"asdf@asdf.com", permission:0, userIdx:9, request:false,},
                {name:"User N", email:"asdf12341234@asdf.com", permission:1, userIdx:9, request:false,},
                {name:"User Name Two", email:"asdf1234@asdf.com", permission:2, userIdx:9, request:false,},
            ], //for loading image
        });

        postWithToken('coworkers/list.php',{tableIdx:this.props.currentTable},(data) => {
            //success
            this.setState({coworkers:Array.from(data.users)},() => {
                this.redisplayCoworkers();
            });
        },(errorMessage,data) => {
            NetworkToaster.show({message:errorMessage, intent:Intent.DANGER, icon:"warning-sign"});
            if(data.message === 'no_permission') {
                //then return to tables
                history.push('/');
            }
        },()=> this.setState({loading:false}));


    }
    onChangePermission = (idx,permission,request=false) => {
        //if request===true, then idx is requestIdx
        //else idx is userIdx
        this.setState({changePermissionLoading:idx, changePermissionIsRequest:request});

        postWithToken('coworkers/permission.php', {
            tableIdx:this.props.currentTable,
            idx:idx,
            request:request,
            permission:permission,
        },(data)=>{
            //success, so change value
            let newState = this.state.coworkers;
            newState.forEach((coworker,index) => {
                if(coworker.request===request) {
                    if(request) {
                        //check requestidx
                        if(idx===coworker.requestIdx) {
                            //bingo
                            newState[index].permission = permission;
                            return;
                        }

                    } else {
                        if(idx===coworker.userIdx) {
                            //bingo
                            newState[index].permission = permission;
                            return;
                        }

                    }
                }

            });
            this.setState({coworkers:newState},()=>{
                this.redisplayCoworkers();
            });
        },null,()=>this.setState({
            changePermissionLoading:null, changePermissionIsRequest:false,
        }));
        //just change state

    }
    onDeleteCoworker = (idx,request=false) => {
        //if request===true, then idx is requestIdx
        //else idx is userIdx
        if(window.confirm(i18n.t("coworkers.delete-confirm"))) {
            this.setState({deleteLoading:idx, deleteLoadingIsRequest:request});
            postWithToken('coworkers/delete.php',{
                tableIdx:this.props.currentTable,
                idx:idx,
                request:request,
            },(data) => {
                NetworkToaster.show({message:i18n.t("coworkers.delete-success"), intent:Intent.SUCCESS, icon:"small-tick"});
                this.populate();

            },null,()=>{
                this.setState({
                    deleteLoading:null,
                    deleteLoadingIsRequest:false,
                })
            })

        }


    }
    toggleInviteOpen = () => {
        this.setState({isInviteOpen:!this.state.isInviteOpen});
        this.initializeInvite();
    }
    closeAndInitializeInvite = () => {
        this.setState({isInviteOpen:false});
        this.initializeInvite();
    }
    initializeInvite = () => {
        this.setState({invitePermission:0,inviteEmail:'',invalidEmail:false,inviteLoading:false,});
    }


    sendInvite = (e) => {
        e.preventDefault();

        const {inviteEmail, invitePermission} = this.state;
        if(validateEmail(inviteEmail)) {
            //now send
            this.setState({inviteLoading:true, invalidEmail:false});
            postWithToken("coworkers/invite.php",{
                tableIdx:this.props.currentTable,
                permission:invitePermission,
                email:inviteEmail,
            },(data)=> {
                ///success
                NetworkToaster.show({message:i18n.t("coworkers.invite-success"), intent:Intent.SUCCESS, icon:"small-tick"});
                this.closeAndInitializeInvite();
                this.populate();
            },null,()=>this.setState({inviteLoading:false}));

        } else {
            //invalid email
            this.setState({invalidEmail:true});
        }

        return false;

    }

    searchCoworker = (e) => {
        const needle = e.target.value;
        this.setState({
            searchString:needle,
        },()=> {
            this.redisplayCoworkers();
        });

    }

    redisplayCoworkers = () => {
        //called after change of search string or data itself
        let newCoworkers = this.state.coworkers;
        const searchString = this.state.searchString.trim();

        if(searchString.length > 0) {
            //has searchstring
            newCoworkers = this.state.coworkers.filter(element => (element.name.includes(searchString) || element.email.includes(searchString)));


        }

        this.setState({
            filteredCoworkers:newCoworkers
        });
    }
    render() {

        const PopoverContent = <div>
            <form onSubmit={this.sendInvite}>
                <PermissionSelector disabled={this.state.inviteLoading} permission={this.state.invitePermission} onItemSelect={(item) => this.setState({invitePermission:item})} />
                <InputGroup intent={this.state.invalidEmail ? Intent.DANGER : Intent.NONE} 
                    onKeyUp={(e)=> {if(e.key==="Escape") this.setState({isInviteOpen:false})}} 
                    autoFocus={true} 
                    style={{marginTop:'10px'}} 
                    value={this.state.inviteEmail} 
                    disabled={this.state.inviteLoading}
                    onChange={(e) => this.setState({inviteEmail:e.target.value})} 
                    placeholder={i18n.t("coworkers.invite-email-placeholder")} />
                {this.state.invalidEmail && <span style={{fontSize:'smaller',color:'#C23030'}}>{i18n.t("coworkers.invalid-email")}</span>}
                <div className="coworkers-invite-actions">
                    <Button disabled={this.state.inviteLoading} onClick={()=>this.setState({isInviteOpen:false})} intent={Intent.NONE} minimal={true} text={i18n.t('general.cancel')} />
                    <Button loading={this.state.inviteLoading} onClick={this.sendInvite} type="submit" style={{marginLeft:'10px'}} rightIcon="send-message" intent={Intent.PRIMARY} text={i18n.t("general.send")} />

                </div>

            </form>
        </div>;

        return <div id="body">
            <h4 className="bp3-heading title">
                {coworkerIcon('#333')} CO-WORKERS 
            </h4>
            <div className="body-limited small">
                <div className="coworkers-menu">
                    <InputGroup 
                        autoFocus={true}
                        className="grow margin-right-10 bp3-round" 
                        leftElement={<Icon icon="search" />} 
                        placeholder={i18n.t("coworkers.search-user-placeholder")}
                        onChange={this.searchCoworker}
                        value={this.state.searchString}
                        />
                    <Popover
                        isOpen={this.state.isInviteOpen}
                        popoverClassName={Classes.POPOVER_CONTENT_SIZING }
                        lazy={true}
                    >
                        <Button active={this.state.isInviteOpen} onClick={this.toggleInviteOpen} icon="new-person" text={i18n.t("coworkers.add-new-user")} intent={Intent.PRIMARY} />
                        {PopoverContent}
                    </Popover>

                </div>

                <div className="coworkers-list">
                    {this.state.filteredCoworkers.map((element, index) => <CoworkerCard 
                        loading={this.state.loading} 
                        deleteLoading ={this.state.deleteLoadingIsRequest === element.request && ((element.request && this.state.deleteLoading === element.requestIdx) || (!element.request && this.state.deleteLoading === element.userIdx)) }
                        permissionLoading={this.state.changePermissionIsRequest === element.request && ((element.request && this.state.changePermissionLoading === element.requestIdx) || (!element.request && this.state.changePermissionLoading === element.userIdx)) }
                        {...element} 
                        onItemSelect={(permission) => {this.onChangePermission(element.request ? element.requestIdx : element.userIdx,permission,element.request); return false;}}
                        onDelete={()=>this.onDeleteCoworker(element.request ? element.requestIdx : element.userIdx,element.request)} />)}
                </div>
                {(!this.state.filteredCoworkers || this.state.filteredCoworkers.length ===0) && <div className="coworkers-empty">
                    <svg xmlns="http://www.w3.org/2000/svg" width="200" viewBox="0 0 411.455 216.565">
                    <g id="Group_74" data-name="Group 74" transform="translate(-1169.549 -595.747)">
                        <path id="Path_63" data-name="Path 63" d="M6550.124,4337.745s-5.389-116.329,103.466-152.661c76.524-25.541,125.048,22.913,203.05,18.781,32.959-1.746,74.1-26.822,92.059-74.862" transform="translate(-5379 -3527)" fill="none" stroke="#8A9BA8" stroke-linecap="round" stroke-width="3"/>
                        <path id="Path_64" data-name="Path 64" d="M6732.608,4125.569h18.262l-6.656,14.438" transform="matrix(0.848, -0.53, 0.53, 0.848, -6340.248, 676.995)" fill="none" stroke="#8A9BA8" stroke-width="3"/>
                    </g>
                    </svg>
                    <div>
                        {i18n.t("coworkers.empty-description")}<br />
                        {i18n.t("coworkers.empty-description2")}
                    </div>
                </div>}

            </div>

        </div>;
    }

}

function CoworkerCard(props) {
    return <Card className={"coworkers-card"}>
        <H5 className={props.loading ? "bp3-skeleton" : (props.request ? " request-title":"")}>{props.request ? i18n.t("coworkers.pending-title"):props.name}</H5>
        <div className={"email " + (props.loading ? "bp3-skeleton" : "")}>{props.email}</div>
        <div className="coworkers-actions">
            <PermissionSelector {...props} />
            <Button loading={props.deleteLoading} onClick={props.onDelete} className="remove-button" icon="trash" minimal={true} />
        </div>
    </Card>;
}

function PermissionSelector(props) {
    return <Select items={[0,1,2]}
        filterable={false}
        activeItem={parseInt(props.permission)}
        itemRenderer={permissionSelectFieldRenderer}
        onItemSelect={props.onItemSelect}
        minimal={true}
        popoverProps={{minimal:true,  }}
        disabled={props.disabled}
        className={props.loading ? "bp3-skeleton" : ""}
        
        
        >
        <Button 
        disabled={props.disabled}
        loading={props.permissionLoading}
        className="coworkers-permission" outlined={true}>
            <div className="coworkers-permission-button permission-item bp3-text-small">
                <img src={getPermissionIcon(props.permission)}  alt="permission icon"/>
                {getPermissionTitle(props.permission)}
                <Icon icon="caret-down" style={{marginLeft:'5px'}} />
            </div>
        </Button>

    </Select>
}

export {CoWorkersPage}