
import React from 'react';
import i18n from 'i18next';
import {Spinner, H3, Dialog,Tag, Callout, Switch, H4, Classes, Button, Card, H5, Icon, InputGroup, Intent, Popover, PopoverInteractionKind, Keys, EditableText, TextArea } from '@blueprintjs/core';
import {checkSurveyUrl, getPermissionIcon, getPermissionTitle, history, NetworkToaster, permissionSelectFieldRenderer, postWithToken, validateEmail, validatePassword} from '../_helpers';
import '../../node_modules/@blueprintjs/select/lib/css/blueprint-select.css';
import { Select } from '@blueprintjs/select';
import { settingIcon, VerificationDialog } from '../_components';
import './SettingsPage.css';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactCodeInput from 'react-code-input';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SignatureCanvas from 'react-signature-canvas';

import {render} from 'react-dom';
import {renderToString} from 'react-dom/server';
import jsPDF from 'jspdf';
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from 'pdfmake';


const consentsListPage_initialState = {
    isOpen :false,
    list:[],
    loading:false,
    currentPage:0,
    totalCount:0,
    totalPages:0,

};

class ConsentsListPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = consentsListPage_initialState;
        this.piSignatureCanvas = props.signatureCanvas;
        this.piName = props.piName;
    }

    componentDidUpdate() {
        this.piSignatureCanvas = this.props.signatureCanvas;
        this.piName = this.props.piName;

    }

    populate = (page) => {
        const {tableIdx} = this.props;

        var vars = {
            tableIdx:tableIdx,
        };

        if(page !== undefined && page > 0) {
            vars.page = page;
        }

        this.setState({loading:true});
        postWithToken('settings/consents', vars,
        (data) => {
            // console.log(data);
            this.setState(data.state);


        }, null, () => this.setState({loading:false}));

    }

    componentDidMount() {
        this.populate();
    }


    openDialog = () => {
        this.setState({
            isOpen:true,
        });
    }
    closeDialog = () => {
        this.setState({
            isOpen:false,
        });

    }

    getConsent = (survey_user_idx) => {
        // const piSignature = this.piSignatureCanvas.toDataURL();
        // const pdf = new jsPDF("p", "mm", "a4");
        // pdf.html()
        // pdf.setLanguage("ko");
        // pdf.set
        // pdf.html("허준녕",{
        //     callback: function (doc) {
        //       doc.save("pdf");
        //     }
        //  });
        // pdf.save("pdf");
        // alert(this.consentContent);

        
        NetworkToaster.show({
            message:i18n.t("consent.please-wait"),
            intent:Intent.WARNING,
        });
        const piSignature= this.piSignatureCanvas();

        postWithToken('settings/getSignedConsent', {
            tableIdx:this.props.tableIdx,
            surveyUserIdx:survey_user_idx,

        },(data) => {
            const userName = data.name;
            const signedDate = data.date;
            const userSignature = data.signature;
            const consentContent = data.consent;

            var val = htmlToPdfmake(consentContent, {
                defaultStyles: {
                    h1 : {
                        fontSize:22,
                    },
                    h2 : {
                        fontSize:18,
                    },
                    h3 : {
                        fontSize:15,
                    },
                    h4 : {
                        fontSize:12,
                    },
                    h5 : {
                        fontSize:10,
                    },
                    h6 : {
                        fontSize:8,
                    },
                }
            });
            
            val.push({
                image:piSignature,
                width:150,
                margin:[0,100,0,10],
            });
            val.push({
                text : this.piName,
                fontSize:12,
                bold:true,
                lineHeight:1.2,
            });
            val.push({
                text : i18n.t("consent.pi-name"),
                fontSize:10,
                lineHeight:1.2,

    
            });
            val.push({
                text : signedDate,
                fontSize:10,
                bold:true,
                margin:[0,0,0,70],
                lineHeight:1.2,
            });

            //participant
            val.push({
                image:"data:image/png;base64,"+userSignature,
                width:150,
                margin:[0,0,0,10],
            });
            val.push({
                text : userName,
                fontSize:12,
                bold:true,
                lineHeight:1.2,
            });
            val.push({
                text : i18n.t("consent.participant-name"),
                fontSize:10,
                lineHeight:1.2,
    
            });
            val.push({
                text : signedDate,
                fontSize:10,
                bold:true,
                lineHeight:1.2,
            });
            
            // console.log(val);
            var dd = {
                content:val, 
                defaultStyle: {
                    font:'NanumMyeongjo',
                    lineHeight:2,
                    
                },
                styles: {
                    'ql-align-center' : {
                        alignment:'center'
                    },
                    'ql-align-right' : {
                        alignment:'right'
                    },
                    'ql-align-justify' : {
                        alignment:'justify'
                    },
                    'ql-size-huge' : {
                        fontSize:24,
                    },
                    'ql-size-large' : {
                        fontSize:18,
                    },
                    'ql-size-small' : {
                        fontSize:8,
                    },
                    
                }
            };
            pdfMake.fonts = {
            
                // download default Roboto font from cdnjs.com
                // Roboto: {
                //   normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                //   bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                //   italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                //   bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
                // },
                NanumMyeongjo: {
                    normal: 'https://cdn.jsdelivr.net/font-nanum/1.0/nanummyeongjo/v2/NanumMyeongjo-Regular.ttf',
                    italics: 'https://cdn.jsdelivr.net/font-nanum/1.0/nanummyeongjo/v2/NanumMyeongjo-Regular.ttf',
                    bold: 'https://cdn.jsdelivr.net/font-nanum/1.0/nanummyeongjo/v2/NanumMyeongjo-Bold.ttf',
                    bolditalics: 'https://cdn.jsdelivr.net/font-nanum/1.0/nanummyeongjo/v2/NanumMyeongjo-Bold.ttf',
                    extrabold: 'https://cdn.jsdelivr.net/font-nanum/1.0/nanummyeongjo/v2/NanumMyeongjo-ExtraBold.ttf',
                  },
             
             }

             pdfMake.createPdf(dd).download();
             NetworkToaster.show({
                message:i18n.t("consent.success"),
                intent:Intent.SUCCESS,
                icon:"tick"
            });
            //  try{
                

            //  } catch(e) {
            //     NetworkToaster.show({
            //         message:i18n.t("consent.error-pdf"),
            //         intent:Intent.DANGER,
            //         icon:"error"
            //     });

            //  }


        });

        
        
    }

    gotoPage = (e) => {
        // console.log(e.target.getAttribute('name'));
        var paging = parseInt(e.target.getAttribute('name'));
        
        this.populate(paging);

    }

    render() {


        var bodyComponent;

        if(this.state.loading) {
            //loading
            bodyComponent = <div align="center"><Spinner style={{marginTop:'20px', marginBottom:'20px'}} /></div>;
        } else if(this.state.list !== undefined && this.state.list.length ===0) {
            //empty
            bodyComponent =<div className="consent-empty-div">
                {i18n.t("consent.empty")}
                <Button onClick={this.closeDialog} text={i18n.t("consent.work-harder")} minimal={true} large={true} style={{marginTop:'20px'}} />
            </div>; 

        } else {
            //load complete, so display
            bodyComponent = <table className="bp3-html-table bp3-html-table-condensed bp3-interactive" style={{width:'100%'}}>
                <thead>
                    <tr>
                        <th>
                            {i18n.t("consent.hospitalNo")}
                        </th>
                        <th>
                            {i18n.t("consent.name")}
                        </th>
                        <th>
                            {i18n.t("consent.date")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.list!==undefined && this.state.list.map((consent) => {
                            return <tr key={consent.survey_user_idx} onClick={(e) => this.getConsent(consent.survey_user_idx)}>
                                <td>
                                    {consent.hospital_no}
                                </td>
                                <td>
                                    {consent.name}
                                </td>
                                <td>
                                    {consent.date}
                                </td>

                            </tr>;
                        })
                    }
                </tbody>


            </table>;

        }

        var pagingItems = [];
        const {currentPage, totalPages}= this.state;
        const startPage = (currentPage-5 > 0 ? currentPage-5 : 1);
        const endPage = (currentPage+5 > totalPages ? totalPages : currentPage+5);
        for(var i= startPage ;i<=endPage;i++ ) {
            var displayText;
            if(i == startPage && startPage > 1) {
                //then there is more to the left
                displayText = "... " + i;
            } else if(i==endPage && endPage < totalPages) {
                //then there is more to the right
                displayText = i + " ...";
            } else {
                displayText = i;
            }
            pagingItems.push(<span key={i} name={i} onClick={(e) => {this.gotoPage(e)}} className={"consent-paging-button" + (i==currentPage ? " selected" : "")}>
                {displayText}
            </span>);
                    
        }
        var pagingComponent = <div className="consent-paging">
            {pagingItems}
        </div>;

        return <div>
            <Dialog 
                isOpen={this.state.isOpen} 
                onClose={this.closeDialog}
                title={i18n.t("consent.title")}>
                <div className={Classes.DIALOG_BODY}>
                    
                    {bodyComponent}
                    {pagingComponent}

                </div>
                {/* <div className={Classes.DIALOG_FOOTER}>
                </div> */}


            </Dialog>

            <Button onClick={this.openDialog} style={{marginBottom:'20px', marginTop:'10px'}} text={i18n.t("settings.show-consents")} intent={Intent.SUCCESS}/>
        </div>;
    }
}

export {ConsentsListPage};