import iconCrown from '../assets/icons/permission-crown.svg';
import iconUser from '../assets/icons/permission-user.svg';
import iconViewer from '../assets/icons/permission-viewer.svg';
import {Icon, MenuItem} from '@blueprintjs/core';
export function getPermissionIcon(permission)  {
    switch (parseInt(permission)) {
        case 0 : return iconViewer;
        case 1 : return iconUser; 
        case 2 : return iconCrown; 
        default : return null; 
    }

}
export function getPermissionTitle(permission) {
    switch (parseInt(permission)) {
        case 0 : return 'VIEWER';
        case 1 : return 'USER'; 
        case 2 : return 'MANAGER'; 
        default : return ''; 
    }

}


export function permissionSelectFieldRenderer(permission, {handleClick, modifiers} ) {
    const icon = getPermissionIcon(parseInt(permission));
    
    return(
        <MenuItem
            active={modifiers.active}
            key={permission}
            onClick={handleClick}
            className="bp3-text-small"
            text={<div className="permission-item">
                <img src={icon}  alt="permission icon"/>
                {getPermissionTitle(permission)}

            </div>}
        />
    )
}
