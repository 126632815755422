import { BehaviorSubject } from 'rxjs';

// import config from 'config';
import apiUrl from '../config';

import {history, handleResponse } from '../_helpers';
var currentUserSubject;
try {
    currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

 }
catch (e)
{
    currentUserSubject = new BehaviorSubject();

}

var currentUserEmail;
try {
    currentUserEmail = new BehaviorSubject(localStorage.getItem('currentUserEmail'));

 }
catch (e)
{
    currentUserEmail = new BehaviorSubject();

}
/*
currentUser.token만 존재

*/

export const authenticationService = {
    login,
    logout,
    signup,
    updateToken,
    currentUser: currentUserSubject.asObservable(),
    currentUserEmail: currentUserEmail.asObservable(),
    get currentUserValue () { return currentUserSubject.value },
    get currentUserEmailValue () { return currentUserEmail.value}
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(`${apiUrl}/login/mobileLogin.php`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if(data.success === true) {
                // console.log("user"+JSON.stringify(data));
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(data));
                currentUserSubject.next(data);

                localStorage.setItem('currentUserEmail', email);
                currentUserEmail.next(email);
                return true;

            } else {
                localStorage.removeItem('currentUser');
                currentUserSubject.next(null);
                localStorage.removeItem('currentUserEmail');
                currentUserEmail.next(null);

                return data.message ?? "unknown_error";

            }
            
        });
}

function signup(email, password, name) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, name})
    };

    return fetch(`${apiUrl}/login/register.php`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if(data.success === true) {
                // console.log("user"+JSON.stringify(data));
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(data));
                currentUserSubject.next(data);

                localStorage.setItem('currentUserEmail', email);
                currentUserEmail.next(email);

                return data;

            } else {
                localStorage.removeItem('currentUser');
                currentUserSubject.next(null);
                localStorage.removeItem('currentUserEmail');
                currentUserEmail.next(null);
                return data.message ?? "unknown_error";

            }
            
        });
}

function logout(userAction = false) {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    localStorage.removeItem('currentUserEmail');
    currentUserEmail.next(null);

    if(userAction) {
        history.push("/", {logoutByUser:true});
    }
}

function updateToken(data) {
    localStorage.setItem('currentUser', JSON.stringify(data));
    currentUserSubject.next(data);
}