import { Button, EditableText, Popover, Position } from '@blueprintjs/core';
import { DatePicker, TimePicker, TimePrecision } from '@blueprintjs/datetime';
import React from 'react';
import {getMomentFormatter} from '../_helpers';
import moment from "moment";
import './DateTimePicker.css';

const minDate = new Date("1900-01-01");
const maxDate = new Date("2100-12-31");

class DateTimePicker extends React.Component { 
    constructor(props) {
        super(props);

        this.state ={
            show: false,
        }
    }
    closePopup = () => {
        this.setState({show:false});
    }
    onClosedPopup = () => {
        if(this.props.showDatePicker===false && this.props.showTimePicker) {
            //timepicker only , so save on close popup
            this.props.onConfirm(this.checkValue(this.props.value)); 
        }

    }
    getDatePicker = () => {
        const {onConfirm} = this.props;
        const momentFormatter = getMomentFormatter(this.getFormat());
        return <DatePicker
        key="datepicker"
        value={this.getValueFromProp()}
        onChange={(selectedDate, isUserChange) => {
            // console.log(selectedDate);
            if(isUserChange) {
                onConfirm(selectedDate ?  momentFormatter.formatDate(selectedDate) : null);
                // this.setState({show:false});

            }
            // this.setState({show:false});
        }}
        reverseMonthAndYearMenus={true}
        showActionsBar={true}
        // shortcuts={true}
        minDate={minDate}
        maxDate={maxDate}

         />

    }
    getDateTimePicker = () => {
        const {onConfirm} = this.props;
        const momentFormatter = getMomentFormatter(this.getFormat());
        return <DatePicker
        key="datetimepicker"
        value={this.getValueFromProp()}
        onChange={(selectedDate, isUserChange) => {
            // console.log(selectedDate);
            if(isUserChange) {
                onConfirm(selectedDate ?  momentFormatter.formatDate(selectedDate) : null);
                // this.setState({show:false});

            }
            // this.setState({show:false});
        }}
        reverseMonthAndYearMenus={true}
        showActionsBar={true}
        // shortcuts={true}
        minDate={minDate}
        maxDate={maxDate}
        timePickerProps={{showArrowButtons:true}}

        timePrecision={this.props.showTimePicker ? TimePrecision.SECOND : null}

         />

    }
    getTimePicker = () => {
        const {onChange} = this.props;
        const momentFormatter = getMomentFormatter(this.getFormat());
        // console.log(this.getValueFromProp());
        return <TimePicker
        precision={TimePrecision.SECOND}
        value={this.getValueFromProp()}
        showArrowButtons={true}
        onChange={(selectedDate) => {
            onChange(selectedDate ?  momentFormatter.formatDate(selectedDate) : null);
        }}

         />

    }

    getValueFromProp = () => {

        const momentFormatter = getMomentFormatter(this.getFormat());
        const value = this.checkValue(this.props.value);
        if(moment(value,this.getFormat(), true).isValid()) {
            return this.props.value ? momentFormatter.parseDate(value) : null;

        }  else {
            return null;
        }
    }
    getFormat = () => {
        let returnArray = [];
        if(this.props.showDatePicker) returnArray.push("YYYY-MM-DD");
        if(this.props.showTimePicker) returnArray.push("HH:mm:ss");
        return returnArray.join(" ");
    }
    checkValue = (value) => {
        if(value) {
            const momentFormatter = getMomentFormatter(this.getFormat());
            let dateValue = momentFormatter.parseDate(value);
            if(dateValue < minDate) dateValue = minDate;
            if(dateValue > maxDate) dateValue = maxDate;
            if(Object.prototype.toString.call(dateValue) === '[object Date]') {
                return momentFormatter.formatDate(dateValue); 

            } else {
                return null;
            }

        } else {
            return null;
        }

    }
    render() {
        /** value is in Date format */
        const { placeholder, showDatePicker, showTimePicker, onChange, onConfirm} = this.props;

        let content;
        if(showDatePicker && showTimePicker) {
            content = (this.getDateTimePicker());
        } else if(showDatePicker) {
            content= (this.getDatePicker());
        } else if(showTimePicker) {
            content = (this.getTimePicker());

        }
        return <div className="records-view-editable-picker-wrapper">
            <Popover {...this.props.popoverProps}
            autoFocus={false}
            isOpen={this.state.show}
            onClose={this.closePopup}
            position={Position.TOP}
            content={content}
            target={<div className="records-view-editable-picker-target" />}
            onClosed={this.onClosedPopup}
            disabled={this.props.disabled}
            />
            <EditableText {...this.props.editableProps}
                onEdit={(value) => {this.setState({show:true})}}
                value={this.props.value }
                placeholder={placeholder}
                disabled={this.props.disabled}
                onChange={(value) => {
                    // console.log("changed")
                    onChange(value);
                }}
                
                onConfirm={(value) => {
                    // console.log('confirm');

                    onConfirm(this.checkValue(value));
                    // this.setState({show:false});
                }}
                />
            
            

        </div>

    }
}

export {DateTimePicker}