import React from 'react';
import './FilterInput.css';
import {Select} from "@blueprintjs/select";
import { Button, InputGroup, Intent, MenuItem } from '@blueprintjs/core';
import i18n from 'i18next';

// eslint-disable-next-line no-use-before-define
const OperatorSelect = Select.ofType();

const Operator = Object.freeze({
    "match" : "match",
    "not" : "not",
    "like" : "like",
    "between" : "between",
    "lessThan":"<",
    "lessOrEqual":"<=",
    "greaterThan":">",
    "greaterOrEqual":">=",
    "checked" : "checked",
    "unchecked" : "unchecked"
})

class FilterInput extends React.Component {
    constructor(props) {
        super(props);
        this.state ={};
    }

    getOperators = (type) => {
        let operators = [];
        if(type===0) {
            //text
            operators.push(null,Operator.match, Operator.not);
            operators.push(Operator.like);
        } else if(type===1 || type===2) {
            //number
            operators.push(null,Operator.match, Operator.not);
            operators.push(Operator.between,Operator.lessThan, Operator.lessOrEqual, Operator.greaterOrEqual, Operator.greaterThan);
        } else if(type===3) {
            //radio
            operators.push(null,Operator.match, Operator.not);
        } else if(type===4) {
            //checkbox

            operators.push(null,Operator.checked, Operator.unchecked);
        } else if(type===6 || type===7 || type ===8) {
            //datetime
            operators.push(null,Operator.match, Operator.not);
            operators.push(Operator.between,Operator.lessThan, Operator.lessOrEqual, Operator.greaterOrEqual, Operator.greaterThan);
        }
        return operators;
    }
    renderOperators = (operator, {handleClick, modifiers} ) => {
        return(
            <MenuItem
                textClassName={operator === null ? "gray-text" : ""}
                active={modifiers.active}
                key={operator}
                // label={i18n.t(`operator.${operator}`)}
                onClick={handleClick}
                text={i18n.t(`operator.${operator}`)}
            />
        )
    }
    render() {
        const { name, id, type, operator, value, value2, onOperatorSelect, onValueChange, inline, disabled, disabledMessage, outlined } = this.props;
        return(
            <div key={id} className="filter-input-wrapper" style={{margin:inline ? "0px" : 'inherit'}}>
                {!inline && <div className="id">{name}</div>}
                <OperatorSelect className="operator" disabled={disabled} filterable={false} activeItem={operator} items={this.getOperators(type)} itemRenderer={this.renderOperators} onItemSelect={(item) => onOperatorSelect(id,item)}>
                    <Button  disabled={disabled} className="operator-button" minimal={outlined ? false : true} outlined={outlined} intent={outlined ? Intent.NONE : Intent.PRIMARY} rightIcon="double-caret-vertical">
                        <div className="operator-button-content">
                            <span className={"text" + ((operator === null || disabled) ? " gray-text" : "")}>{disabled ? disabledMessage : i18n.t(`operator.${operator}`)}</span>

                        </div>
                    </Button>
                </OperatorSelect>
                <div className="input">
                    <InputGroup
                        disabled={operator === null || disabled}
                        minimal={true}
                        fill={true}
                        value={value ?? ""}
                        onChange={(e) => {onValueChange(id, e.target.value)}}
                     />
                </div>
                {operator === Operator.between && <span className="between">~</span>}
                {operator === Operator.between && <div className="input secondary">
                    <InputGroup
                        disabled={operator === null || operator === Operator.checked || operator === Operator.unchecked}
                        minimal={true}
                        fill={true}
                        value={value2 ?? ""}
                        onChange={(e) => {onValueChange(id, e.target.value, true)}}
                     />
                </div>}

            </div>
        )
    }

}

export {FilterInput}