import React from 'react';
import Scrollspy from 'react-scrollspy';
import i18n from 'i18next';
import {eraseWhiteSpaces} from '../_helpers';
import './SectionMenu.css';

class SectionMenu extends React.Component {
    render() {
        const {layout} = this.props;
        return <div className="section">
            <h5 className="bp3-heading section-title">{i18n.t("section.section-shortcut")}</h5>
            <Scrollspy 
            items={layout ? layout.map((element, index) => `section-${index}`) : []}
            currentClassName="selected"
            className="section-list"
            offset="86"
            >
                {layout && layout.map((element, index) => <li key={index} className="section-item"><span onClick={()=>{ window.location.replace(`#section-${index}`);document.documentElement.scroll({top: document.documentElement.scrollTop-90});}}>{element.name}</span></li>)}
            </Scrollspy>

        </div>;
    }

}

export {SectionMenu};