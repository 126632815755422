import { Alert, Button, ButtonGroup, Callout, Checkbox, Classes, ControlGroup, Dialog, Divider, H4, H5, H6, InputGroup, Intent, Spinner, Tooltip } from '@blueprintjs/core';
import {recordsIcon} from "../_components";
import i18n from 'i18next';
import React from 'react';
import { Redirect } from 'react-router-dom';
import './RecordsPage.css';
import InfiniteScroll from 'react-infinite-scroller';
// import InfiniteScroll from 'react-infinite-scroll-component';
import {PatientDialog} from './PatientDialog';
import {isStringEmpty} from '../_helpers';

import { NetworkToaster,history, isFieldEmpty, postWithToken, removeArrayByValue } from '../_helpers';
import { FilterInput } from './FilterInput';
function findPos(obj) {
  var curtop = 0;
  if (obj.offsetParent) {
      do {
          curtop += obj.offsetTop;
      } while (obj = obj.offsetParent);
  return [curtop];
  }
}
class RecordsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page : 0,
      list : [],
      loading : false,
      titleFields : [],
      hasMore:true,
      showMultipleDelete:false,
      showMultipleDeleteWarning:false,
      showAddRecord:false,
      searchNumber:"",
      searchByNumberLoading:false,
      showFindPatientDialog:false,
      showFilterListDialog:false,
      showFindPatientDialogNumber:"",
      showFindPatientDialogName:"",
      findPatientDialogList:[],
      showFindPatientDialogLoading: false,
      filters:[],
      initiallyLoaded:false,

    }
    this.checkedList = [];
    this.appliedFilters = null;
    this.scrollRef = null;
    this.appliedPatientNumberSearch = null;

    this.loadedFromStorage = false;
    this.startPage = -1;


    //load from localStorag
    if(sessionStorage.getItem("records_state") !== null) {
      // console.log('recovering from state');
      this.loadedFromStorage = true;
      this.state = JSON.parse(sessionStorage.getItem("records_state"));
      this.startPage = this.state.page;

    }
    if(sessionStorage.getItem("records_filters") !== null) {
      this.appliedFilters = JSON.parse(sessionStorage.getItem("records_filters"));

    
    }
    

  }

  componentDidMount() {
    if(this.loadedFromStorage===false) { this.getList(0) } else {
      //loaded, so find scroll pos

      const previousRecord = parseInt(sessionStorage.getItem("records_clicked"));

      if(previousRecord > 0) {
        try{
          const previous_scrollTop = sessionStorage.getItem("records_scrollTop");
          // document.querySelector("#record-scrollable-list > .table-list").scroll(0,findPos(document.querySelector('#record_tr_'+previousRecord)) - findPos(document.querySelector('#record-scrollable-list .tr.clickable').firstChild) - 100);
          document.querySelector("#record-scrollable-list > .table-list").scroll(0,previous_scrollTop);

        } catch(e) {

        }
      }

      if(sessionStorage.getItem("records_clicked") !== null) {
        //now erase
        this.clearSessionStorage();

      }

      //now erase
    }
  }

  getList = (page) => {
    // if(page===undefined) page = this.state.page+1;

    /****
     * 'data' : {
 *      'titleFields' : [
 *          {
 *              "name" : "field name",
 *              "type" : "field type",
 *              "id" : "field key"  
 *          },
 *      ],
 *      'list' : [
 *          {
 *              'records_idx' / 'edited' / 'patient_no' / field1 ... / 'name' (작성자이름)
 *          },
 *      ],
 *      'hasMore' : true / false
 *  }
     */
    // this.setState({
    //   loading : true,
    // });
    
    let vars = {
      tableIdx:this.props.currentTable,
      paging:page,
    };

    if(this.appliedFilters!==null) {
      vars.filters = this.appliedFilters;
    }

    if(this.appliedPatientNumberSearch !== null && this.appliedPatientNumberSearch!=="") {
      vars.patientNo = this.appliedPatientNumberSearch;

    }


    postWithToken('records/list.php',vars, (data) => {
      //success
      console.log(data);

      this.setState({
        page:page,
        titleFields: data.data.titleFields,
        list:page > 0 ? this.state.list.concat(data.data.list) : data.data.list,
        loading:false,
        hasMore:data.data.hasMore,
        initiallyLoaded:true,
      });
      

    },(errorMessage,data) => {
      if(data.message === 'no_permission') {
          //then return to tables
          history.push('/');
      } else {
          NetworkToaster.show({message:errorMessage, intent:Intent.DANGER, icon:"warning-sign"});
      }
  }, () => {
      this.setState({
        searchByNumberLoading:false,
      });

    });

  }
  handleCheckChange = (records_idx, checked) => {
    if(this.checkedList.indexOf(records_idx) > -1) {
      //already present
      if(!checked)  {
        this.checkedList = removeArrayByValue(this.checkedList, records_idx);
      }
    } else {
      //not present
      if(checked) {
        this.checkedList.push(records_idx);
      }
    }
    if(this.checkedList.length > 0 && this.state.showMultipleDelete===false) {
      //show!
      this.setState({showMultipleDelete:true});
    } else if(this.checkedList.length ===0 && this.state.showMultipleDelete===true) {
      //hide!
      this.setState({showMultipleDelete:false});
    }
  }
  deleteMultipleRecords = () => {
    this.setState({showMultipleDeleteWarning:true});

  }
  cancelDeleteMultipleRecords = () => {
    this.setState({showMultipleDeleteWarning:false});

  }
  confirmDeleteMultipleRecords = () => {
    postWithToken('records/delete.php',{tableIdx:this.props.currentTable,list:JSON.stringify(this.checkedList)},
    (data) => {
      this.checkedList = [];
      this.setState({showMultipleDelete:false});
      this.getList();
    }, null, (data) => {
      this.setState({showMultipleDeleteWarning:false});
    });

  }
  showAddRecord = () => {
    this.setState({showAddRecord:true});
  }
  closeAddRecord = () => {
    this.setState({showAddRecord:false});
  }


  searchByNumber = () => {
    const {searchNumber} = this.state;
    if(this.props.currentTableIsMulti ==="multi") {
      //if multi, go to get List
      this.setState({
        searchByNumberLoading:true,
      });
      this.appliedPatientNumberSearch = searchNumber.trim();
      this.getList(0);
    } else {
      if(searchNumber.trim() !=="") {
        //if not multi, perform search
        this.setState({searchByNumberLoading:true});
        postWithToken('records/searchByNumber.php', {
          tableIdx:this.props.currentTable,
          searchNumber:searchNumber,
        }, (data) => {
          history.push(`/records/${data.recordIdx}`);
        }, null, () => {
          this.setState({searchByNumberLoading:false});
        });
      }

    }

  }

  
  showFindPatientDialog = () => {
    this.setState({showFindPatientDialog:true});
  }
  hideFindPatientDialog = () => {
    this.setState({showFindPatientDialog:false});
  }
  findPatient = () => {
    const { showFindPatientDialogNumber, showFindPatientDialogName } = this.state;
    this.setState({showFindPatientDialogLoading:true});
    postWithToken('records/findPatient.php', {
      tableIdx:this.props.currentTable,
      number:showFindPatientDialogNumber.trim(), 
      name: showFindPatientDialogName.trim(),
    }, (data) => {
      if(data.hasList===false) {
        history.push(`/records/${data.recordIdx}`);
      } else {
        this.setState({findPatientDialogList:data.list});
      }
    }, null, (data) => {
      this.setState({showFindPatientDialogLoading:false});
    })
  }

  showFilterListDialog = () => {
    this.setState({
      showFilterListDialog:true,
      filters:this.appliedFilters !== null ? this.appliedFilters : [],
    });
  }
  hideFilterListDialog= () => {
    this.setState({showFilterListDialog:false});
  }

  changeFieldValue = (key, value) => {
    this.setState({[key] : value});
  }

  changeFilterOperator = (id, operator) => {
    let newState = Array.from(this.state.filters);

    let thisIndex = null;
    newState.forEach((obj, index) => {
      if(obj.field === id) {thisIndex = index; return;}
    });

    if(operator===null) {
      if(thisIndex !== null) {
        newState.splice(thisIndex,1);
      }

    } else {

      if(thisIndex === null) {
        //push
        newState.push({
          field:id, type:operator
        });
      } else {
        //edit
        newState[thisIndex]['type'] = operator;
      }
    }

    this.setState({filters:newState});
  }

  changeFilterValue = (id, value, isSecondary = false) => {
    let newState = Array.from(this.state.filters);

    let thisIndex;
    newState.forEach((obj, index) => {
      if(obj.field === id) {thisIndex = index; return;}
    });

    if(thisIndex === null) {
      //push
      newState.push({
        field:id, [isSecondary ? 'value2' : 'value' ] :value
      });
    } else {
      //edit
      newState[thisIndex][isSecondary ? 'value2' : 'value'] = value;
    }
    this.setState({filters:newState});
  }

  resetFilter = () => {
    this.setState({filters:[]});
    if(this.appliedFilters !== null) {
      this.appliedFilters = null;
      this.getList();
    }
    // this.hideFilterListDialog();
  }

  navigateToRecord= (recordIdx)=> {
    //save all state to local variable
    sessionStorage.setItem("records_state", JSON.stringify(this.state));
    sessionStorage.setItem("records_filters", JSON.stringify(this.appliedFilters));
    sessionStorage.setItem("records_clicked", recordIdx);
    sessionStorage.setItem("records_scrollTop", document.querySelector("#record-scrollable-list > .table-list").scrollTop);

    

    history.push(`/records/${recordIdx}`);

  }

  clearSessionStorage = () => {
    sessionStorage.removeItem("records_state");
    sessionStorage.removeItem("records_filters");
    sessionStorage.removeItem("records_clicked");
    sessionStorage.removeItem("records_scrollTop");
  }

  filterList = () => {
    //apply filter from this.state to this.appliedFilters

    this.appliedFilters = this.state.filters.length > 0 ? this.state.filters : null;
    this.getList();
    this.hideFilterListDialog();
  }


  render() {
    const {currentTable, currentTablePermission} = this.props;
    if(currentTable === null) return <Redirect to="/" />;

    // top menu that displays thee actions
    const topMenu = <div className="bp3-callout records-list-header">
      <Button minimal={true} icon="search-text" onClick={this.showFindPatientDialog}>{i18n.t("record.find-patient")}</Button>
      <Button intent={this.state.filters !== undefined && this.state.filters.length > 0 ? Intent.PRIMARY: Intent.NONE} 
        outlined={this.state.filters !== undefined && this.state.filters.length > 0 ? true : false}
        minimal={true} 
        icon="filter-list" 
        onClick={this.showFilterListDialog}>{i18n.t("record.filter-list")}</Button>
      <div className="records-list-expanded"></div>
      
      {currentTablePermission > 0 && this.state.showMultipleDelete && <Tooltip isOpen={true} intent={Intent.DANGER} content={i18n.t("record.delete-multiple-record")}><Button icon="trash" minimal={true} disabled={!this.state.showMultipleDelete} intent={Intent.DANGER} onClick={()=>{this.deleteMultipleRecords();}} style={{marginRight:'10px'}}></Button></Tooltip>}
      {currentTablePermission > 0 && <Button icon="plus" intent={Intent.PRIMARY}
      onClick={()=>this.showAddRecord()}
      >{i18n.t("record.add-record")}</Button>}
    </div>;


    //make title fields joined
    let titles = [];
    let titleKeys = [];
    this.state.titleFields.forEach(eachField => {
      titles.push(eachField.name);
      titleKeys.push(eachField.id);
    });

    const multipleDeleteWarningPopup = <Alert
      cancelButtonText={i18n.t("general.cancel")}
      confirmButtonText={i18n.t("general.delete")}
      icon="trash"
      intent={Intent.DANGER}
      isOpen={this.state.showMultipleDeleteWarning}
      onCancel={this.cancelDeleteMultipleRecords}
      onConfirm={this.confirmDeleteMultipleRecords}
    >
      <p>
        {i18n.t("record.delete-multiple-warning", {count:this.checkedList.length})}
      </p>
    </Alert>;


    // Find Patient Dialog (Search by patient information)
    const FindPatientDialog = <Dialog isOpen={this.state.showFindPatientDialog} 
    onClose={this.hideFindPatientDialog} title={i18n.t("record.find-patient-dialog-title")} >
      <div className={Classes.DIALOG_BODY}>
        <Callout>{i18n.t("record.find-patient-description")}</Callout>
        <form>
          <div className="record-find-patient-dialog-body">
            <InputGroup className="record-find-patient-dialog-input"
              autoFocus={true}
              disabled={this.state.showFindPatientDialogLoading || !!this.state.showFindPatientDialogName}
              value ={this.state.showFindPatientDialogNumber}
              onChange={e => this.changeFieldValue('showFindPatientDialogNumber', e.target.value)}
              placeholder={i18n.t("record.find-patient-number-placeholder")}
            />
            <span className="record-find-patient-dialog-spacer" />
            <InputGroup className="record-find-patient-dialog-input"
              disabled={this.state.showFindPatientDialogLoading || !!this.state.showFindPatientDialogNumber}
              value ={this.state.showFindPatientDialogName}
              onChange={e => this.changeFieldValue('showFindPatientDialogName', e.target.value)}
              placeholder={i18n.t("record.find-patient-name-placeholder")}
            />
            <span className="record-find-patient-dialog-spacer" />
            <Button type="submit" disabled={this.state.showFindPatientDialogLoading || (isStringEmpty(this.state.showFindPatientDialogNumber) && isStringEmpty(this.state.showFindPatientDialogName))} intent={Intent.PRIMARY}
              onClick={this.findPatient}
            >{i18n.t("general.search")}</Button>
          </div>

        </form>
          
        {this.state.findPatientDialogList.length > 0 && <div className="record-find-patient-dialog-list">
          <strong className="blue-text">{i18n.t("record.possible-matches")}</strong>
          <table className="bp3-html-table bp3-interactive bp3-html-table-condensed">
            <thead>
              <tr className="bp3-text-small">
                <th>{i18n.t("record.patient-dialog-record-id")}</th>
                <th>{i18n.t("record.patient-dialog-hospital-no")}</th>
                <th>{i18n.t("record.patient-dialog-patient-name")}</th>
                <th>{i18n.t("record.patient-dialog-edited")}</th>
              </tr>

            </thead>
            <tbody>
              {this.state.findPatientDialogList.map(each => <tr className="bp3-text-small" onClick={()=>history.push(`/records/${each.records_idx}`)}>
                <td>{each.records_idx}</td>
                <td>{each.hospital_no}</td>
                <td>{each.name}</td>
                <td className="record-small-time">{each.edited}</td>
              </tr>)}
            </tbody>
          </table>
        </div>}


      </div>

    </Dialog>;

    //Filter List Dialog (Filter records by certain conditions)

    const FilterListDialog = <Dialog isOpen={this.state.showFilterListDialog} 
    onClose={this.hideFilterListDialog} title={i18n.t("record.filter-list-dialog-title")} >
      {/* <form> */}
        <div className={Classes.DIALOG_BODY}>
          {this.state.titleFields.map(element => {
            //find filter element
            let filterIndex = null;
            this.state.filters.forEach((filter, index) => {
              if(filter.field === element.id) {
                filterIndex = index;
                return;
              }
            });
            let thisFilter = null;
            if(filterIndex === null) thisFilter = {type:null, value:null, value2:null};
            else thisFilter = this.state.filters[filterIndex];
            
            return <FilterInput key={element.id} {...element}
              operator={thisFilter.type  ?? null}
              value = {thisFilter.value ?? null}
              value2 = {thisFilter.value2 ?? null}
              onOperatorSelect={this.changeFilterOperator}
              onValueChange={this.changeFilterValue}
            />
          })}

        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button minimal={true} onClick={e => this.hideFilterListDialog()}>{i18n.t("general.cancel")}</Button>
            <Button minimal={true} intent={Intent.WARNING} onClick={e => this.resetFilter()}>{i18n.t("general.reset")}</Button>
            <Button type="submit" intent={Intent.PRIMARY} onClick={e => this.filterList()}>{i18n.t("record.apply-filter")}</Button>

          </div>
        </div>
      {/* </form> */}

    </Dialog>;
    

    const NoRecordInformation = <div className="record-empty">
      <div className="top-row">
        <div className="form-container">
          <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="130" viewBox="0 0 240.119 56.844">
            <g id="Group_75" data-name="Group 75" transform="translate(-609.381 -701.689)">
              <path id="Path_65" data-name="Path 65" d="M6226.859,4254.3s-62.673-29.6-120.736-23.179-111.516,48.85-111.516,48.85" transform="translate(-5378 -3527)" fill="none" stroke="#8A9BA8" stroke-linecap="round" stroke-width="3"/>
              <path id="Path_66" data-name="Path 66" d="M6736.418,4122.689l14.452,2.88-8.45,12.21" transform="translate(8277.712 2725.169) rotate(163)" fill="none" stroke="#8A9BA8" stroke-width="3"/>
            </g>
          </svg>

          </div>
          <div className="description form">
            {i18n.t("record.empty-form")}
          </div>

        </div>

        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="200" viewBox="0 0 411.455 216.565">
          <g id="Group_74" data-name="Group 74" transform="translate(-1169.549 -595.747)">
            <path id="Path_63" data-name="Path 63" d="M6550.124,4337.745s-5.389-116.329,103.466-152.661c76.524-25.541,125.048,22.913,203.05,18.781,32.959-1.746,74.1-26.822,92.059-74.862" transform="translate(-5379 -3527)" fill="none" stroke="#8A9BA8" stroke-linecap="round" stroke-width="3"/>
            <path id="Path_64" data-name="Path 64" d="M6732.608,4125.569h18.262l-6.656,14.438" transform="matrix(0.848, -0.53, 0.53, 0.848, -6340.248, 676.995)" fill="none" stroke="#8A9BA8" stroke-width="3"/>
          </g>
          </svg>
        </div>

      </div>
      <div className="record description">
        <div>
          {i18n.t("record.record-empty")}
        </div>
      </div>
      

    </div>;

    const NoFilteredRecord = <div className="records-no-filtered-record">
      {i18n.t("record.no-filtered-record")}

    </div>



    //render
    
    var previousRecord;
    if(sessionStorage.getItem("records_clicked") !== null) {
      previousRecord = parseInt(sessionStorage.getItem("records_clicked"));
    }
    
    
    return  <div id="body">
      {FindPatientDialog}
      {FilterListDialog}
      {multipleDeleteWarningPopup}
      <PatientDialog isOpen={this.state.showAddRecord} onClose={() => this.closeAddRecord()} currentTable={this.props.currentTable} currentTableIsMulti={this.props.currentTableIsMulti} permission={this.props.currentTablePermission} />
      <h4 className="bp3-heading title">
          {recordsIcon('#333')} RECORDS 
      </h4>
      <div id="record-list">
        {topMenu}
        <div id="record-scrollable-list" className="header-fixed-table-wrapper" ref={(ref) => this.scrollRef = ref}>
          <div className="tr header-fixed-table">
            <div className="td-m fixed">
              <ControlGroup fill={true} vertical={false}>
                <div className="bp3-input-group">
                <InputGroup small={true} placeholder={i18n.t("record.patient-number")} 
                
                  onKeyUp={(e) => {if(e.key==="Enter") this.searchByNumber(); }}
                  onChange={e=>this.changeFieldValue('searchNumber', e.target.value)}
                  autoFocus={true}
                  value={this.state.searchNumber}
                  disabled={this.state.searchByNumberLoading || (this.state.filters !== undefined && this.state.filters.length > 0)} />
                  <div className="bp3-input-action">
                    <Button disabled={this.state.searchByNumberLoading || (this.state.filters !== undefined && this.state.filters.length > 0)} 
                      onClick={()=>{
                        if(this.appliedPatientNumberSearch !== null && this.appliedPatientNumberSearch!=="") {
                          //not empty, currently under patient_no filtering
                          //so reset
                          this.appliedPatientNumberSearch=null;
                          this.setState({
                            searchNumber:"",
                            searchByNumberLoading:true,

                          });
                          this.getList(0);
                        } else {
                          //just serach
                          this.searchByNumber();
                        }
                      }} small={true} style={{margin:'0px'}} 
                      icon={this.appliedPatientNumberSearch !== null && this.appliedPatientNumberSearch!=="" ? "small-cross" : "chevron-right"} minimal={true} />
                  </div>
                </div>
              </ControlGroup>

            </div>
            <div className="td-expand">
              <small>

                {titles.join(" / ")}
              </small>

            </div>
            <div className="td-l td-center">
              <small>
                {i18n.t("record.edited")}

              </small>
            </div>
            <div className="td-s td-center">
              <small>
                {i18n.t("record.author")}
              </small>
            </div>
          </div>
          <div className="table-list">
            <InfiniteScroll
                pageStart={this.startPage}
                loadMore={this.getList}
                hasMore={this.state.hasMore}
                loader={<div key={0} className="table-spinner"><Spinner /></div>}
                useWindow={false}
                // getScrollParent={() => this.scrollRef}
                // dataLength={this.state.list ? this.state.list.length : 0}
                // next={this.getList}
                // scrollableTarget="record-scrollable-list"


            >
              {
                this.state.list.map((eachRecord, index, originalList) => {
                  /* 'records_idx' / 'edited' / 'patient_no' / field1 ... / 'name' (작성자이름)*/

                  let titleFieldValues = [];
                  titleKeys.forEach((eachTitle, index) => {
                    if(index >0) titleFieldValues.push(<span className="record-list-empty"> / </span>);
                    if(isFieldEmpty(eachRecord[eachTitle]) === false) {
                      titleFieldValues.push(<span>{eachRecord[eachTitle]}</span>);

                    } else {
                      titleFieldValues.push(<span className="record-list-empty">{i18n.t("general.empty")}</span>);

                    }
                  });

      

                  return <div className={"tr clickable" + ((previousRecord && previousRecord === parseInt(eachRecord.records_idx)) ? " previous" : "")} onClick={() => this.navigateToRecord(eachRecord.records_idx)} key={"record_tr_"+eachRecord.records_idx} id={"record_tr_"+eachRecord.records_idx}>
                    <div className="td-b td-m">
                      {currentTablePermission > 0 && <span onClick={(e) => {e.stopPropagation()}}><Checkbox inline={true} className="records-list-checkbox"  onChange={e => {this.handleCheckChange(eachRecord.records_idx, e.target.checked)}} >
                        
                      </Checkbox>

                        {/* <span className="list-number-prefix">#</span> {eachRecord.patient_no} */}
                      </span>}
                        <span><span className="list-number-prefix">#</span> {eachRecord.patient_no}</span>
                    </div>
                    <div className="td-expand">{titleFieldValues}</div>
                    <div className="td-l bp3-text-small td-center">{eachRecord.edited}</div>
                    <div className={"td-s bp3-text-small td-center" + (!eachRecord.name ? " gray-text" : "")}>{!eachRecord.name ? i18n.t("record.survey-user") : eachRecord.name}</div>

                  </div>

                })
              }
              
            </InfiniteScroll>
            {!this.state.loading && (!this.state.list || this.state.list.length===0) && this.state.initiallyLoaded && (!this.state.filters || this.state.filters.length ===0) &&
              NoRecordInformation
            }
            {!this.state.loading && (!this.state.list || this.state.list.length===0) && this.state.initiallyLoaded && this.state.filters && this.state.filters.length >0 &&
              NoFilteredRecord
            }

          </div>
          
            
            
        </div>
      </div>
      
    </div>
  }
}

export {RecordsPage}