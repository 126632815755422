import React from 'react';
import i18n from 'i18next';
import {H3, Dialog,Tag, Callout, Switch, H4, Classes, Button, Card, H5, Icon, InputGroup, Intent, Popover, PopoverInteractionKind, Keys, EditableText, TextArea } from '@blueprintjs/core';
import {checkSurveyUrl, getPermissionIcon, getPermissionTitle, history, NetworkToaster, permissionSelectFieldRenderer, postWithToken, validateEmail, validatePassword} from '../_helpers';
import '../../node_modules/@blueprintjs/select/lib/css/blueprint-select.css';
import { Select } from '@blueprintjs/select';
import { settingIcon, VerificationDialog } from '../_components';
import './SettingsPage.css';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactCodeInput from 'react-code-input';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SignatureCanvas from 'react-signature-canvas';
import {ConsentsListPage} from './ConsentsListPage';
import './ConsentsListPage.css';

const settings_initialState = {
    loading:false,
    account_email : '',
    account_pw:'',
    table_survey:false,
    table_editable:false,
    table_survey_intro:null,
    table_survey_outro:null,
    table_survey_url:null,
    table_is_single:false,
    title:'',
    /** consent related */
    table_consent:false,
    table_consent_content:"",
    consent_pi_name:"",
    consent_pi_signature:null,

    changes:{},

    errors:{},
    verification_loading:false,
    show_verification_input:false,
    check_verification_loading:false,
    verification_input:"",
    password_dialog_open:false,
    password_dialog_original_password:"",
    password_dialog_new_password_1:"",
    password_dialog_new_password_2:"",
    password_dialog_error:"",
    password_change_loading:false,
    

}
  

class SettingsPage extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = settings_initialState;

        this.originalEmail = "";
        this.signatureCanvas = null;
        this.originalSignature = null;
    }

    componentDidMount() {
        this.populate();
    }


    populate = () => {
        this.setState({
            loading:true
        });
        postWithToken('settings/populate', {
            tableIdx:this.props.currentTablePermission ===2 ? this.props.currentTable ?? 0 : 0,
        }, (data)=> {
            this.setState(data.state);
            this.originalEmail = data.state.account_email;
            this.originalSignature = data.state.consent_pi_signature;

            if(this.props.currentTableIsMulti==="single") {
                this.setState({
                    table_is_single:true,
                    table_editable:true,
                });
            }

            this.originalConsent = data.state.table_consent_content;

            //already mounted
            this.fillSignatureCanvas();

        },null,() => {
            this.setState({loading:false});
        })

    }

    fieldChange = (key, value) => {
        let {changes} = this.state;
        changes[key] = true;
        this.setState({
            [key] :value,
            changes,

        });

        if(this.state.errors[key]) {
            //then erase error
            let newError = this.state.errors;
            delete newError[key];
            this.setState({errors:newError});
        }

        if(key==='table_survey_url') {
            //real-time check for safe url
            let newError = this.state.errors;
            if(checkSurveyUrl(value)===false) {
                //not safe
                newError[key] = i18n.t("error.invalid_url");
            } else {
                //safe
                delete newError[key];
            }
            this.setState({errors:newError});
        } else if(key==='title') {
            let newError = this.state.errors;
            if(!value) {
                // empty
                newError[key] = i18n.t("error.title-empty");
            } else {
                //safe
                delete newError[key];
            }
            this.setState({errors:newError});

        }

    }

    confirmChange = (key, value) => {
        //do post

        //validation needed

        let newError = this.state.errors;
        let error =false;
        if(key==='account_email') {
            // alert(validateEmail(value));
            //check email
            error=true; // will not post on confirm change, so just escape with error=true 

            if(validateEmail(value)===false) {
                //invalid
                newError.account_email = i18n.t("error.invalid_email");
            }
        } else if(key==='table_survey_url') {
            if(checkSurveyUrl(value) ===false) {
                newError[key] = i18n.t("error.invalid_url");
                error = true;
            } else {
                delete newError[key];
            }


        }

        if(error) this.setState({errors:newError});
        else {
            //post
            postWithToken('settings/setSettings', {
                key:key,
                value:value,
                tableIdx:this.props.currentTable,
            },(data) => {
                NetworkToaster.show({message:i18n.t("settings.save-success"), icon:"tick",intent:Intent.SUCCESS })
                let {changes} = this.state;
                changes[key] = false;
                this.setState({changes});
            },(error,data) => {
                
                newError[key] = error;
                this.setState({errors:newError});

            });
        }

    }


    toggleSurvey = (e) => {
        this.setState({
            table_survey:e.target.checked,
        });
        postWithToken('settings/setSettings', {
            key:'survey',
            value:e.target.checked ? 1:0,
            tableIdx:this.props.currentTable,
        });

    }

    toggleSurveyEditable = (e) => {
        this.setState({
            table_editable:e.target.checked,
        })
        postWithToken('settings/setSettings', {
            key:'editable',
            value:e.target.checked ? 1:0,
            tableIdx:this.props.currentTable,
        });

    }


    sendVerification = () => {
        if(validateEmail(this.state.account_email) && this.originalEmail !== this.state.account_email) {
            this.setState({verification_loading:true});
            postWithToken('settings/sendVerification',{
                email:this.state.account_email,
            },(data) => {
                //success
                //show dialog
                this.setState({
                    show_verification_input:true,
                })
            },null,()=>this.setState({
                verification_loading:false,
            }));

        } else {
            alert(i18n.t("error.invalid_email"));
        }
    }

    inputVerificationCode = (value) => {
        if(String(value).length === 4) {
            //complete!
            this.setState({check_verification_loading:true});

            postWithToken('settings/checkVerification',{
                code:value,
            },(data) => {
                //success
                //so close and change email

                NetworkToaster.show({message:i18n.t("verification.success"), intent:Intent.SUCCESS, icon:"tick"});
                this.originalEmail = this.state.account_email;
                this.setState({
                    show_verification_input:false,
                });
            }, (error, data) => {
                //error,
                //retry with a toaster message
                NetworkToaster.show({message:i18n.t("verification.failed"), intent:Intent.DANGER, icon:"cross"});

            }, () => this.setState({
                check_verification_loading:false,
                verification_input:"",
            }));

        }
        
    }

    cancelVerification = () => {
        //close field and return email to original
        this.setState({
            show_verification_input:false,
            verification_input:"",
            verification_loading:false,
            check_verification_loading:false,
            account_email:this.originalEmail,
        });

    }

    openChangePasswordDialog = () => {
        this.setState({
            password_dialog_open:true
        });

    }
    closeChangePasswordDialog = () => {
        this.setState({
            password_dialog_open:false,
        })
    }

    onPasswordInputChange = (key,value) => {
        //validate password
        let changeState = {
            [`password_dialog_${key}`]:value
        };
        if(key==='new_password_1') {
            //check if password is strong enough
            if(validatePassword(value)) {
                changeState.password_dialog_error = "";

            } else {
                changeState.password_dialog_error = i18n.t("error.password_weak");

            }
        } else if(key==='new_password_2') {
            //check same as 1
            if(value === this.state.password_dialog_new_password_1) {
                if(validatePassword(value)) {
                    changeState.password_dialog_error = "";

                } else {
                    changeState.password_dialog_error = i18n.t("error.password_weak");

                }

            } else {

                changeState.password_dialog_error = i18n.t("error.password_does_not_match");
            }
        }
        this.setState(changeState);



    }
    submitPasswordChange = () => {
        //submit password change
        //validate password

        const {password_dialog_original_password, password_dialog_new_password_1, password_dialog_new_password_2} = this.state;

        if(!password_dialog_original_password) {
            //please input original password
            alert(i18n.t("settings.need-original-password"));
        } else if(!password_dialog_new_password_1) {
            alert(i18n.t("settings.need-new-password"));

        } else if(password_dialog_new_password_1 !== password_dialog_new_password_2) {
            alert(i18n.t("error.password_does_not_match"));

        } else if(validatePassword(password_dialog_new_password_1)===false) {
            alert(i18n.t("error.password_weak"));

        } else {
            //success, go change!
            this.setState({
                password_change_loading:true
            });
            postWithToken('settings/changePassword', {
                original:password_dialog_original_password,
                pw:password_dialog_new_password_1,
            }, () => {
                //success
                //initialize state vars
                this.setState({
                    password_dialog_open:false,
                    password_dialog_original_password:"",
                    password_dialog_new_password_1:"",
                    password_dialog_new_password_2:"",
                    password_dialog_error:"",
                });
                NetworkToaster.show({message:i18n.t("settings.change-password-success"), icon:"tick", intent:Intent.SUCCESS});
            },null,() => {
                this.setState({
                    password_change_loading:false,
                });
            });

        }
    }

    /**
     * Consent related
     */
    toggleConsent = (e) => {
        this.setState({
            table_consent:e.target.checked,
        })
        postWithToken('settings/setSettings', {
            key:'consent',
            value:e.target.checked ? 1:0,
            tableIdx:this.props.currentTable,
        });

    }
    editorChange = (content) => {
        let {changes}= this.state;
        changes['consent_content'] = true;
        this.setState({table_consent_content : content, changes});

    }
    saveConsent = (e) => {
        if(window.confirm(i18n.t("settings.create-new-consent"))) {
            postWithToken('settings/setSettings', {
                key:'consent_content',
                value:this.state.table_consent_content,
                tableIdx:this.props.currentTable,

            },() => {
                let {changes} = this.state;
                changes['consent_content'] = false;
                this.setState({
                    changes
                });
                NetworkToaster.show({message:i18n.t("settings.save-success"), icon:"tick",intent:Intent.SUCCESS })
            });

        }
    }
    resetConsent = () => {
        if(window.confirm(i18n.t("settings.discard-consent-changes"))) {

            
            this.setState({
                table_consent_content:this.originalConsent,
            });

            setTimeout(()  => { //fixing the bug where onchanged is called after this
                let {changes}= this.state;
                changes['consent_content'] = false;
                this.setState({
                    changes
                });

            }, 1);
        }

    }

    fillSignatureCanvas = (reset=false) => {
        if(this.signatureCanvas ===null) return;
        if(reset) {
            this.signatureCanvas.clear();
            if(this.originalSignature) {
                this.signatureCanvas.fromDataURL(this.originalSignature);
            } else {
                this.signatureCanvas.clear();
            }
            let {changes} = this.state;
            changes['consent_pi_signature'] = false;
            this.setState({changes});

        } else {
            if(this.originalSignature) {

                this.signatureCanvas.fromDataURL(this.originalSignature,{width: 300, height: 200});
            }

        }
    }
    saveSignature = () => {
        postWithToken('settings/setSettings', {
            key:'consent_pi_signature',
            value:this.signatureCanvas.toDataURL(),
            tableIdx:this.props.currentTable,

        },() => {
            let {changes} = this.state;
            changes['consent_pi_signature'] = false;
            this.setState({
                changes
            });
            // NetworkToaster.show({message:i18n.t("settings.save-success"), icon:"tick",intent:Intent.SUCCESS })
        }); 

    }
    clearSignature = () => {
        if(this.signatureCanvas.isEmpty() === false) {
            let {changes} = this.state;
            changes['consent_pi_signature'] = true;
            this.setState({
                changes
            }); 

            this.signatureCanvas.clear();

        }

    }

    getCurrentSignature = () => {
        return this.signatureCanvas.toDataURL();
    }



    render() {
        const {currentTable, currentTableName, currentTablePermission, currentTableIsMulti} = this.props;
        const toolbarOptions = [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold','underline', 'strike'],        // toggled buttons
            // ['blockquote', 'code-block'],
          
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          
            
          
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            // [{ 'font': [] }],
            [{ 'align': [] }],
          
            ['clean']                                         // remove formatting button
          ];

        const editorChanged = this.state.changes['consent_content'];

        const ConsentElement = (
            <div className="conditional">

                <div className="settings-field-title" style={{marginTop:'40px'}}>
                    {i18n.t("settings.consent-download-title")}
                </div>
                <ConsentsListPage tableIdx={this.props.currentTable} signatureCanvas={this.getCurrentSignature} piName={this.state.consent_pi_name} />
                <div className="settings-field">
                    <div className="settings-field-title">
                        {i18n.t("settings.consent-content-title")}
                        <span className={"editor-status" + (editorChanged ? " changed" : "")}>{i18n.t(editorChanged ? "settings.consent-unsaved" : "settings.consent-saved")}</span>
                    </div>
                    
                    <div className="editor-wrapper">
                        <ReactQuill value={this.state.table_consent_content}
                            onChange={this.editorChange}
                            // onBlur={this.saveConsent}
                            modules={{toolbar : toolbarOptions,clipboard: {
                                matchVisual: false,
                            },}}
                        
                            />
                        
                    </div>
                    <div className="editor-actions">
                        <Button 
                            intent={Intent.PRIMARY}
                            text={i18n.t("settings.save-a-new-version")}
                            disabled={!editorChanged}
                            onClick={this.saveConsent}
                            />
                        <Button 
                            intent={Intent.NONE}
                            minimal={true}
                            text={i18n.t("general.reset")}
                            disabled={!editorChanged}
                            onClick={this.resetConsent}
                            />

                    </div>
                </div>
                <SettingField 
                    title={i18n.t("settings.consent-pi-name-title")}
                    value={this.state.consent_pi_name}
                    type="text"
                    intent={this.state.changes['consent_pi_name'] ? Intent.SUCCESS : Intent.NONE}
                    multiline={false}
                    onChange={(value) => this.fieldChange('consent_pi_name', value)}
                    onConfirm={(value) => this.confirmChange('consent_pi_name', value)}
                        />
                <SettingSignatureField 
                    
                    onBegin={()=>{
                        if(!this.state.changes['consent_pi_signature']) {
                            let {changes }= this.state;
                            changes['consent_pi_signature'] = true;
                            this.setState({changes});
                        }
                    }}
                    reset ={() => this.fillSignatureCanvas(true)}
                    clear = {() => this.clearSignature()}
                    save={this.saveSignature}
                    changed={this.state.changes['consent_pi_signature']}
                    refFunction={(ref) => {this.signatureCanvas = ref}}
                    title={i18n.t("settings.consent-pi-sign-title")}
                    
                     />
                
            </div>
        );


        const SwitchEnabledElements = [
            <Callout intent={Intent.PRIMARY} style={{marginTop:'10px',marginBottom:'20px'}}>
                {i18n.t("settings.survey-desc-1")}
                <Tag intent={Intent.PRIMARY}>survey</Tag>
                {i18n.t("settings.survey-desc-2")}
                <br />

                {i18n.t("settings.survey-desc-3")}

            </Callout>,
            <SettingField 
                    title={i18n.t("settings.survey-title")}
                    value={this.state.title}
                    error={this.state.errors['title']}
                    type="text"
                    intent={this.state.changes['title'] ? Intent.SUCCESS : Intent.NONE}
                    multiline={false}
                    onChange={(value) => this.fieldChange('title', value)}
                    onConfirm={(value) => this.confirmChange('title', value)}
                        />,
            <SettingURLField 
                title={i18n.t("settings.url-title")}
                value={this.state.table_survey_url}
                type="text"
                intent={this.state.changes['table_survey_url'] ? Intent.SUCCESS : Intent.NONE}
                error={this.state.errors['table_survey_url']}
                onChange={(value) => this.fieldChange('table_survey_url', value)}
                onConfirm={(value) => this.confirmChange('table_survey_url', value)}
                tableIdx={this.props.currentTable}
                />,
            <SettingField 
                    title={i18n.t("settings.intro-title")}
                    value={this.state.table_survey_intro}
                    error={this.state.errors['table_survey_intro']}
                    type="text"
                    intent={this.state.changes['table_survey_intro'] ? Intent.SUCCESS : Intent.NONE}
                    multiline={true}
                    onChange={(value) => this.fieldChange('table_survey_intro', value)}
                    onConfirm={(value) => this.confirmChange('table_survey_intro', value)}
                        />,

            <SettingField 
                    title={i18n.t("settings.outro-title")}
                    value={this.state.table_survey_outro}
                    error={this.state.errors['table_survey_outro']}
                    type="text"
                    intent={this.state.changes['table_survey_outro'] ? Intent.SUCCESS : Intent.NONE}
                    multiline={true}
                    onChange={(value) => this.fieldChange('table_survey_outro', value)}
                    onConfirm={(value) => this.confirmChange('table_survey_outro', value)}
                        />,
            <Switch 
                className="settings-switch editable"
                checked={(this.state.table_editable || this.state.table_is_single===true || this.props.currentTableIsMulti==="single")} 
                disabled={this.state.table_is_single===true || this.props.currentTableIsMulti==="single"}
                onChange={(this.state.table_is_single===true || this.props.currentTableIsMulti==="single") ? null : this.toggleSurveyEditable} 
                large={false} 
                labelElement={<SwitchLabel 
                    checked={this.state.table_editable || this.state.table_is_single===true || this.props.currentTableIsMulti==="single"}
                    main={i18n.t("settings.editable-label")}
                    descTrue={i18n.t((this.state.table_is_single===true || this.props.currentTableIsMulti==="single") ? "settings.editable-force-enabled" : "settings.editable-enabled")}
                    descFalse={i18n.t("settings.editable-disabled")}

                        />}
                    />,
            <div className={"settings-consent-box" + (this.state.table_consent ? " active" : "")}>
                <Switch 
                    className="settings-switch editable"
                    checked={this.state.table_consent} 
                    onChange={this.toggleConsent} 
                    large={false} 
                    labelElement={<SwitchLabel 
                        checked={this.state.table_consent}
                        main={i18n.t("settings.consent-label")}
                        descTrue={i18n.t("settings.consent-enabled")}
                        descFalse={i18n.t("settings.consent-disabled")}

                            />}
                        />
                {ConsentElement}

            </div>
            
        ];

        const needVerification = this.originalEmail !== this.state.account_email;


        return <div id="body" className={this.state.loading ? "loading" : ""}>
            <VerificationDialog 
                isOpen={this.state.show_verification_input}
                onChange={this.inputVerificationCode}
                loading={this.state.check_verification_loading}
                resend={this.sendVerification}
                cancel={this.cancelVerification}
                value={this.state.verification_input}
                resetLoading = {this.state.verification_loading}
                />
            <ChangePasswordDialog
                isOpen={this.state.password_dialog_open}
                onClose={this.closeChangePasswordDialog}
                currentPassword={this.state.password_dialog_original_password}
                newPassword1={this.state.password_dialog_new_password_1}
                newPassword2={this.state.password_dialog_new_password_2}
                onChange={this.onPasswordInputChange}
                error={this.state.password_dialog_error}
                submit={this.submitPasswordChange}
                loading = {this.state.password_change_loading}
             />
            <h4 className="bp3-heading title">
                {settingIcon('#333')} SETTINGS 
            </h4>
            <div className="body-limited small">
                {currentTable && currentTablePermission ===2 && <SettingSection title={i18n.t("settings.table-title")}>
                    <Switch 
                        className="settings-switch"
                        checked={this.state.table_survey} 
                        onChange={this.toggleSurvey} 
                        large={true} 
                        labelElement={<SwitchLabel 
                            checked={this.state.table_survey}
                            main={i18n.t("settings.survey-label")}
                            descTrue={i18n.t("settings.survey-enabled")}
                            descFalse={i18n.t("settings.survey-disabled")}
                             />}
                        />
                    {this.state.table_survey && SwitchEnabledElements}

                    

                </SettingSection>}
                <SettingSection title={i18n.t("settings.account-title")}>
                    <SettingField 
                        title={i18n.t("settings.email-title")}
                        value={this.state.account_email}
                        error={this.state.errors['account_email']}
                        type="email"
                        onChange={(value) => this.fieldChange('account_email', value)}
                        onConfirm={(value) => this.confirmChange('account_email', value)}
                        intent={needVerification ? Intent.SUCCESS : Intent.NONE}
                         />
                    {needVerification && <Button onClick={this.sendVerification} style={{marginTop:'10px'}} text={i18n.t("settings.verify-email")} intent={Intent.SUCCESS} loading={this.state.verification_loading} disabled={this.state.show_verification_input} />}

                    <Button onClick={this.openChangePasswordDialog} style={{marginTop:'40px'}} text={i18n.t("settings.password-title")} outlined={true} />
                </SettingSection>
                
            </div>
        </div>
    }
}

function SettingSection(props) {
    return <div className="settings-section">
        <H4>{props.title}</H4>
        <div className="children">
            {props.children}
        </div>
    </div>
}
function SettingField(props) {
    return <div className="settings-field">
        <div className="settings-field-title">
            {props.title}
        </div>
        <EditableText className="settings-field-input" 
            onChange={props.onChange} 
            value={props.value} 
            type={props.type}
            multiline={props.multiline}
            onConfirm={props.onConfirm}
            intent={props.intent}
             />
        {props.error && <div className="error">
            {props.error}
        </div>}

    </div>

}
function SettingSignatureField(props) {
    return <div className="settings-field">
        <div className="settings-field-title">
            {props.title}
        </div>
        <SignatureCanvas penColor='black'
            ref = {props.refFunction}
            onBegin={props.onBegin}


            velocityFilterWeight={0.1}
            // ref ={(ref) => {this.signatureCanvas = ref} }
            canvasProps={{width: 300, height: 200, className: 'settings-consent-signature-canvas' + (props.changed ? " changed" : "")} } />
        <div className="row">
            <Button minimal={true} text={i18n.t("settings.clear")} 
                onClick={props.clear} />
            
            <Button minimal={true} text={i18n.t("general.reset")} 
                onClick={props.reset} disabled={!props.changed} />
            <Button intent={Intent.PRIMARY} text={i18n.t("general.save")} 
                onClick={props.save} disabled={!props.changed} />
        </div>
        

    </div>

}
function SwitchLabel(props) {
    return <div className="settings-switch-label">
        <div>
            {props.main}
        </div>
        <div className="desc-label">
            {props.checked ? props.descTrue : props.descFalse}
        </div>
    </div>
}
function SettingURLField(props) {
    return <div className="settings-field">
        <div className="settings-field-title">
            {props.title}
        </div>
        <div className="settings-field-url-input">
            <span className="fixed">https://survey.redea.io/</span>
            <EditableText className="settings-field-input" 
                onChange={props.onChange} 
                value={props.value} 
                type={props.type}
                intent={props.intent}
                onConfirm={props.onConfirm} />
            <CopyToClipboard 
                style={{marginLeft:'10px'}} 
                text={`https://survey.redea.io/${props.value}`}
                onCopy={() => NetworkToaster.show({message:i18n.t("settings.url-copy-success"),intent:Intent.SUCCESS, icon:"tick"})}
                >
                <Button icon="duplicate" minimal={true} />
            </CopyToClipboard>
            <Button onClick={() => window.open('https://survey.redea.io/qr?i='+props.tableIdx)} minimal={true}>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#5c7080"><path d="M24 13h-24v-3h24v3zm-14-5h1v-2h-2v1h1v1zm2-3h1v-1h-1v-1h-3v1h2v2h1v-1zm2 1h1v-5h-1v1h-1v-1h-4v1h3v1h2v3zm-1 1h1v-1h-1v1zm-10-1h3v-3h-3v3zm15 0h3v-3h-3v3zm-2 2v-7h7v7h-7zm1-1h5v-5h-5v5zm-2 1v-1h-1v1h1zm-3 0h1v-1h-1v1zm-11 0v-7h7v7h-7zm1-1h5v-5h-5v5zm11 13v1h1v-1h-1zm3-4h1v-1h-1v1zm2 3h2v1h-1v2h-1v1h-1v-1h-1v1h-1v-1h-1v-1h1v-2h-2v1h-3v1h1v1h-1v1h-1v-5h1v-1h2v-1h1v-1h1v1h2v1h-1v1h1v1h1v-1h1v1zm-1 1h-1v1h1v-1zm2-5v1h1v-1h-1zm2 0v1h1v-1h-1zm-3 2v-1h-1v1h1zm3 0v-1h-1v1h-1v1h1v1h1v-1h2v-2h-1v1h-1zm-9 6h1v-2h-1v2zm9-2v2h1v-1h1v-2h-1v1h-1zm-20-5h7v7h-7v-7zm1 6h5v-5h-5v5zm18 1v-1h-1v1h1zm-17-2h3v-3h-3v3zm19-1v-1h-1v1h1zm-13-5v2h1v-2h-1z"/></svg> */}
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#5c7080"><path d="M11 8h-1v-2h1v1h2v1h-1v1h-1v-1zm2 12v-1h-1v1h1zm-1-15v-1h-2v1h1v1h1v-1zm8-1v6h-1v-1h-4v-5h5zm-1 4v-3h-3v3h3zm-14 2h-1v1h2v-1h-1zm0 3h1v1h1v-3h-1v1h-2v2h1v-1zm5 1v2h1v-2h-1zm4-10h-1v3h1v-3zm0 5v-1h-1v1h1zm3-2h1v-1h-1v1zm-10-1h-1v1h1v-1zm2-2v5h-5v-5h5zm-1 1h-3v3h3v-3zm9 5v1h-1v-1h-2v1h-1v-1h-3v-1h-1v1h-1v1h1v2h1v-1h1v2h1v-2h3v1h-2v1h2v1h1v-3h1v1h1v2h1v-1h1v-1h-1v-1h-1v-1h1v-1h-2zm-11 8h1v-1h-1v1zm-2-3h5v5h-5v-5zm1 4h3v-3h-3v3zm12-3v-1h-1v1h1zm0 1h-1v1h-1v-1h-1v-1h1v-1h-2v-1h-1v2h-1v1h-1v3h1v-1h1v-1h2v2h1v-1h1v1h2v-1h1v-1h-2v-1zm-9-3h1v-1h-1v1zm10 2v1h1v1h1v-3h-1v1h-1zm2 4v-1h-1v1h1zm0-8v-1h-1v1h1zm-2-10h4v4h2v-6h-6v2zm-16 4v-4h4v-2h-6v6h2zm4 16h-4v-4h-2v6h6v-2zm16-4v4h-4v2h6v-6h-2z"/></svg> */}
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#5c7080"><path d="M21 21v3h3v-3h-1v-1h-1v1h-1zm2 1v1h-1v-1h1zm-23 2h8v-8h-8v8zm1-7h6v6h-6v-6zm20 3v-1h1v1h-1zm-19-2h4v4h-4v-4zm8-3v2h-1v-2h1zm2-8h1v1h-1v-1zm1-1h1v1h-1v-1zm1 2v-1h1v1h-1zm0-2h1v-6h-3v1h-3v1h3v1h2v3zm-1-4v-1h1v1h-1zm-7 4h-4v-4h4v4zm6 0h-1v-2h-2v-1h3v1h1v1h-1v1zm-4-6h-8v8h8v-8zm-1 7h-6v-6h6v6zm3 0h-1v-1h2v2h-1v-1zm-3 3v1h-1v-1h1zm15 6h2v3h-1v-1h-2v1h-1v-1h-1v-1h1v-1h1v1h1v-1zm-4 2h-1v1h-1v-1h-1v-1h1v-1h-2v-1h-1v1h-1v1h-2v1h-1v6h5v-1h-1v-2h-1v2h-2v-1h1v-1h-1v-1h3v-1h2v2h-1v1h1v2h1v-2h1v1h1v-1h1v-2h1v-1h-2v-1zm-1 3h-1v-1h1v1zm6-6v-2h1v2h-1zm-9 5v1h-1v-1h1zm5 3v-1h1v2h-2v-1h1zm-3-23v8h8v-8h-8zm7 7h-6v-6h6v6zm-1-1h-4v-4h4v4zm1 4h1v2h-1v1h-2v-4h1v2h1v-1zm-4 6v-3h1v3h-1zm-13-7v1h-2v1h1v1h-3v-1h1v-1h-2v1h-1v-2h6zm-1 4v-1h1v3h-1v-1h-1v1h-1v-1h-1v1h-2v-1h1v-1h4zm-4-1v1h-1v-1h1zm19-2h-1v-1h1v1zm-13 4h1v1h-1v-1zm15 2h-1v-1h1v1zm-5 1v-1h1v1h-1zm-1-1h1v-3h2v-1h1v-1h-1v-1h-2v-1h-1v1h-1v-1h-1v1h-1v-1h-1v1h-1v1h-1v-1h1v-1h-4v1h2v1h-2v1h1v2h1v-1h2v2h1v-4h1v2h3v1h-2v1h2v1zm1-5h1v1h-1v-1zm-2 1h-1v-1h1v1z"/></svg> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 31">
                <g id="Group_85" data-name="Group 85" transform="translate(-7106.5 144.5)">
                    <g id="Group_84" data-name="Group 84" transform="translate(1)">
                    <g id="Rectangle_97" data-name="Rectangle 97" transform="translate(7112 -138)" fill="none" stroke="#5c7080" stroke-width="2">
                        <rect width="7" height="7" rx="2" stroke="none"/>
                        <rect x="1" y="1" width="5" height="5" rx="1" fill="none"/>
                    </g>
                    <g id="Rectangle_98" data-name="Rectangle 98" transform="translate(7124 -138)" fill="none" stroke="#5c7080" stroke-width="2">
                        <rect width="7" height="7" rx="2" stroke="none"/>
                        <rect x="1" y="1" width="5" height="5" rx="1" fill="none"/>
                    </g>
                    <g id="Rectangle_99" data-name="Rectangle 99" transform="translate(7112 -126)" fill="none" stroke="#5c7080" stroke-width="2">
                        <rect width="7" height="7" rx="2" stroke="none"/>
                        <rect x="1" y="1" width="5" height="5" rx="1" fill="none"/>
                    </g>
                    <g id="Rectangle_100" data-name="Rectangle 100" transform="translate(7126 -124)" fill="#5b7080" stroke="#5c7080" stroke-width="2">
                        <rect width="4" height="4" rx="1" stroke="none"/>
                        <rect x="1" y="1" width="2" height="2" fill="none"/>
                    </g>
                    <path id="Path_73" data-name="Path 73" d="M7110.3-146.612h-5v5" transform="translate(1.701 3.612)" fill="none" stroke="#5c7080" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path id="Path_74" data-name="Path 74" d="M7110.3-146.612h-5v5" transform="translate(6989.388 -7248.299) rotate(90)" fill="none" stroke="#5c7080" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path id="Path_75" data-name="Path 75" d="M7110.3-146.612h-5v5" transform="translate(14241.299 -261.612) rotate(180)" fill="none" stroke="#5c7080" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    <path id="Path_76" data-name="Path 76" d="M7110.3-146.612h-5v5" transform="translate(7253.612 6990.299) rotate(-90)" fill="none" stroke="#5c7080" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    </g>
                </g>
                <path id="Path_77" data-name="Path 77" d="M7068.455-170H7084" transform="translate(-7060.229 185.5)" fill="none" stroke="#5c7080" stroke-linecap="round" stroke-width="1"/>
                <path id="Path_78" data-name="Path 78" d="M7068.455-170h7.773" transform="translate(-7060.229 187.5)" fill="none" stroke="#5c7080" stroke-linecap="round" stroke-width="1"/>
                <path id="Path_79" data-name="Path 79" d="M7068.455-170h4.781" transform="translate(-153 -7060.519) rotate(90)" fill="none" stroke="#5c7080" stroke-linecap="round" stroke-width="1"/>
                <path id="Path_80" data-name="Path 80" d="M7068.455-170h3.4" transform="translate(-153 -7048.247) rotate(90)" fill="none" stroke="#5c7080" stroke-linecap="round" stroke-width="1"/>
                <path id="Path_81" data-name="Path 81" d="M7068.454-170h3.323" transform="translate(-151 -7048.908) rotate(90)" fill="none" stroke="#5c7080" stroke-linecap="round" stroke-width="1"/>
                <path id="Path_82" data-name="Path 82" d="M7068.454-170h3.323" transform="translate(7092.228 -151.5) rotate(180)" fill="none" stroke="#5c7080" stroke-linecap="round" stroke-width="1"/>
                </svg>

            </Button>

        </div>
        {props.error && <div className="error">
            {props.error}
        </div>}

    </div>

}

function ChangePasswordDialog(props) {

    const shouldEnableButton = (!props.error) && !!props.currentPassword && !!props.newPassword1;
    return <Dialog 
        isOpen={props.isOpen}
        autoFocus={true}
        onClose={props.onClose}
    >
        <div className={Classes.DIALOG_BODY}>
            <form onSubmit={(e) => {e.preventDefault(); props.submit();}}>
                <div className="settings-password-dialog-body">
                    <div className="dialog-title">
                        {i18n.t("settings.change-password-title")}
                    </div>
                    <div className="dialog-title-description">
                        {i18n.t("settings.change-password-description")}
                    </div>
                    <InputGroup 
                        type="password"
                        autoFocus={true}
                        value={props.currentPassword}
                        placeholder={i18n.t("settings.current-password")}
                        onChange={(e) => props.onChange('original_password',e.target.value)}
                        style={{marginBottom:'20px'}}
                        
                    />

                    <InputGroup 
                        type="password"
                        value={props.newPassword1}
                        placeholder={i18n.t("settings.new-password-1")}
                        onChange={(e) => props.onChange('new_password_1',e.target.value)}
                        style={{marginBottom:'10px'}}
                        
                    />

                    <InputGroup 
                        type="password"
                        value={props.newPassword2}
                        placeholder={i18n.t("settings.new-password-2")}
                        onChange={(e) => props.onChange('new_password_2',e.target.value)}
                        
                        style={{marginBottom:'10px'}}
                    />
                    <div className="dialog-error">
                        {props.error}
                    </div>

                    <div className="dialog-actions" >
                        <Button 
                            disabled={!shouldEnableButton}
                            intent={Intent.PRIMARY}
                            text={i18n.t("settings.change-password-submit")}
                            type="submit"
                            onClick={props.submit}
                            loading={props.loading}
                        />
                        <Button 
                            minimal={true}
                            onClick={props.onClose}
                            text={i18n.t("general.cancel")}
                            style={{marginTop:'10px'}}
                        />

                    </div>

                </div>

            </form>



        </div>

    </Dialog>
}


export {SettingsPage}