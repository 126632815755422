import React from 'react';
import './DownloadPage.css';
import i18n from 'i18next';
import { defaultSelectFieldPredicate, defaultSelectFieldRenderer, defaultSelectItemPredicate, defaultSelectItemRenderer, getIconForType, NetworkToaster, postWithToken, scrollElementToBottom, streamWithToken } from '../_helpers';
import '../../node_modules/@blueprintjs/select/lib/css/blueprint-select.css';
import { Select } from '@blueprintjs/select';
import {Dialog, Tag, Divider,  EditableText, H3, Intent, Button, Icon, Card,  ProgressBar} from '@blueprintjs/core';


import { downloadIcon, StepCard, DownArrow, TitleMenu } from '../_components';
import {FilterInput} from '../RecordsPage';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { history, reorder} from '../_helpers';

const download_initialState = {
    /* general */
    loading:false,
    databaseColumns:[],

    /* card 1 */
    filterDatabaseColumn : null,
    filterDatabaseOperator : null,
    filterDatabaseValue : null,
    filterDatabaseValue2 : null,

    filterAndOr:null,
    filters:[/**
        {and: true/false, type:filter, column : field, operator : operator, value : value, value2: value2, timestamp:90}
        {and: true/false, type:parenthesis, start:true, timestamp:90}
     */],

    /* card 2 */
    databaseColumn:null,
    fields:[/**
        field, field, field...

     */],

     /** 
      * PROGRESS
      */
    showDownloadCard:false,
    progress:0,
    total:0,
    downloading:false,
    paused:false,

    /**
     * menu
     */

    title:"",
    templateIdx:0,
    changed:false,
    templateListOpen:false,
    templateLoading:false,
    templateList:[],
    owner:false,
    ownerName:null,
    shared:false,
    shareAlertOpen:false,
}



class DownloadPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = download_initialState;
        this.downloadSource = null;
        // this.history = 0;
        this.duplicate = false; //used to decide if saving model is currently duplicate -> set before setting share alert open to true
        this.lastUpdateHistory = 0;
    }
    initializeState = () => {
        this.setState(download_initialState);
    }

    componentDidMount() {
        this.populate();
    }

    populate = () => {
        /** populate from DB */
        this.initializeState();
        this.setState({loading:true});

        postWithToken('download/currentTemplate.php',{tableIdx:this.props.currentTable},data => {
            //populate with data
            
            if(data.hasData) {
                let newState = data.state;

                // console.log(newState);

                //pre-process state
                try {
                    newState.filters = Array.from(JSON.parse(newState.filters));
                    
                } catch(e) {
                    newState.filters = [];
                }
                try {
                    newState.fields= Array.from(JSON.parse(newState.fields));
                    
                } catch(e) {
                    newState.fields= [];
                }
                this.setState(newState);
            }
        },(errorMessage,data) => {
            if(data.message === 'no_permission') {
                //then return to tables
                history.push('/');
            } else {
                NetworkToaster.show({message:errorMessage, intent:Intent.DANGER, icon:"warning-sign"});
            }
        }, data => this.setState({loading:false}));
    }

    /**
     * CARD 1
     */
    selectedFilterDatabaseColumn = (value) => {
        this.setState({filterDatabaseColumn:value});
    }
    shouldDisableAndOr = () => {
        //decide if this should be disabled
        const {filters} = this.state;
        if(filters) {
            if(Array.from(filters).length > 0) {
                return false;
            } else {
                return true;
            }
            

        } else {
            return true;
        }
    }
    addFilter = () => {
        //add filter based on state
        let newState = Array.from(this.state.filters);
        newState.push({
            and:this.state.filterAndOr!=='OR',
            type:'filter', 
            column:this.state.filterDatabaseColumn,
            operator:this.state.filterDatabaseOperator,
            value:this.state.filterDatabaseValue,
            value2:this.state.filterDatabaseValue2,
            timestamp:Date.now(),
           

            
        });
        this.setState({filters:newState,  filterAndOr:null,
        filterDatabaseColumn:null,
        filterDatabaseOperator:null,
        filterDatabaseValue:null,
        filterDatabaseValue2:null,},() => {this.saveFilter(); scrollElementToBottom('.download-filter-list');});
        //scroll to bottom
        

    }
    addParenthesis = (and = true) => {
        let newState = Array.from(this.state.filters);
        newState.push({
            and:and,
            type:'parenthesis', 
            start:true,
            timestamp:Date.now()
        });
        newState.push({
            and:and,
            type:'parenthesis', 
            start:false,
            timestamp:Date.now()
        });
        this.setState({filters:newState},() => this.saveFilter());
    }
    onDragEnd = (result) => {
        let state = Array.from(this.state.filters);
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;


        const items = reorder(state, source.index, destination.index);
        
        if(state[source.index].type==='parenthesis') {
            //check  parenthesis order
            let sum =0, error=false;
            items.forEach(filter => {
                if(filter.type==='parenthesis') {
                    if(filter.start) sum++;
                    else sum--;
                }

                if(sum < 0) {
                    error = true; 
                    return;
                }

            });

            if(error) {
                NetworkToaster.show({message:i18n.t("error.parenthesis-order-error"),intent:Intent.DANGER, icon:"warning-sign"})
                return;
            }
        }

        
        this.setState({
            filters: items
        },() => this.saveFilter());

        //save
        
    }

    deleteFilter = (index) => {
        const filters= Array.from(this.state.filters);
        let newFilter = filters;



        if(filters[index].type==='parenthesis') {
            let searchLevel =0;
            if(filters[index].start) {
                //this is start of parenthesis, so search forwards
                for(var i=index+1; i<filters.length; i++) {
                    if(filters[i].type==='parenthesis') {
                        if(filters[i].start) {
                            //another start, so increase level
                            searchLevel++;
                        } else {
                            //check if this is match!
                            if(searchLevel===0) {
                                //match!
                                newFilter.splice(i,1);//erase pair
                                newFilter.splice(index,1); //erase this one (smaller than i)
                                break;
                            } else {
                                //decrease level
                                searchLevel--;
                            }
                        }
                    }

                }


            } else {
                //this is end of parenthesis, so search backwards
                for(var i=index-1; i>=0; i--) {
                    if(filters[i].type==='parenthesis') {
                        if(filters[i].start) {
                            //start, so check if this match!
                            if(searchLevel==0) {
                                //match!
                                newFilter.splice(index,1);//erase this (larger than i) 
                                newFilter.splice(i,1); //erase pair (smaller than index)
                                break;
                            } else {
                                searchLevel--;
                            }
                        } else {
                            //another end, so increase level!
                            searchLevel++;
                        }
                    }

                }

            }

        } else {
            //not parenthesis
            newFilter.splice(index,1);
        }

        this.setState({filters:newFilter},() => this.saveFilter());

    }

    saveFilter = () => {
        //save current filters to the DB
        postWithToken('download/saveFilter.php', {tableIdx:this.props.currentTable, filters:JSON.stringify(this.state.filters)});

    }
    /**
     * CARD 2
     */
    selectedDatabaseColumn = (value) => {
        this.setState({databaseColumn:value});
    }
    isDatabaseColumnDisabled = (field, index) => {
        try{
            let fields = Array.from(this.state.fields);
            for(var i=0;i<fields.length;i++) {
                if(fields[i].fieldIdx === field.fieldIdx) return true;

            }

        } catch(e) {
            return false;

        }

        return false;
    }
    addDatabaseColumn = () => {
        //use state to add a new column
        let newState;
        try{
            newState = Array.from(this.state.fields);
        } catch(e) {
            newState = [];
        }

        newState.push(this.state.databaseColumn);
        this.setState({
            fields:newState,
            databaseColumn:null,
        }, () => {
            this.saveFields();

        });


    }
    addAllDatabaseColumn = () => {
        let newState = Object.values(this.state.databaseColumns);
        this.setState({
            fields:newState,
            databaseColumn:null,
        }, () => {
            this.saveFields();

        });


    }
    deleteField = (index) => {
        let newState = Array.from(this.state.fields);
        newState.splice(index,1);
        this.setState({
            fields:newState,
        },() => {
            this.saveFields();

        });
    }

    saveFields = () => {
        postWithToken('download/saveFields.php', {tableIdx:this.props.currentTable, fields:JSON.stringify(this.state.fields)});

    }

    /**
     * PROGRESS
     */
    startStream = () => {
        this.setState({downloading:true, showDownloadCard:true,progress:0, total:0,});
        //now really upload
        window.scrollTo(0,document.body.scrollHeight);


        // if(initialize) {
        //     //remove previously set variables
        //     if(this.downloadSource) this.downloadSource.close();
        //     // this.history =0 ;
        //     this.setState({paused:false, progress:0, total:0,});
        // }

        postWithToken('download/prepare.php', {
            tableIdx:this.props.currentTable, 
            // history:this.history
        }, (data) => {
            
            // this.history = data.history;

            //start upload
            this.downloadSource = streamWithToken('download/start.php',data.auth,{
                tableIdx:this.props.currentTable, 
                progress:this.state.progress, 
                // history:this.history
            }, {
                message : (e) => {
                    //e.data
                    const data = JSON.parse(e.data);
                    // if(data.status==="initializing") console.log(data);
                        

                    if(data.progress !== undefined) {
                        if(this.lastUpdateHistory < Date.now()-500) {
                            //needs update (500ms max)
                            this.setState({
                                progress:parseInt(data.progress),
                                total:parseInt(data.total),
                            }, () => {
                                this.lastUpdateHistory = Date.now();
                            })
                            

                        }
                    }
            
                    if(data.status ==='completed') {
                        // TODO : data.url  이용하여 download하기
                        // also send jwt using data.token
                        
                        // alert('completed');
                        this.downloadSource.close();
                        this.setState({downloading:false, progress:this.state.total});

                        window.location.href='https://console.redea.io/download/?f='+data.url+'&t='+data.token;
                        // this.history = 0;
                    }
                    
                },
                error:(e) => {
                    // data.type = ignore | fail | error

                    if(e.data !== undefined) {
                        const data = JSON.parse(e.data);
                        // console.log(data);

                        if(data.progress !== undefined) {
                            this.setState({
                                progress:parseInt(data.progress),
                                total:parseInt(data.total),
                            })
                        }


                        if(data.type === 'error') {
                            alert(i18n.t(`error.${data.message}`));
                            this.downloadSource.close();
                        }
                    }
                }
            });


        },(error) => { //post error
            alert(error);
            this.setState({downloading:false});
        });
        

    }
    // pauseUpload = () => {
    //     this.downloadSource.close();
    //     this.setState({downloading:false, paused:(this.state.progress < this.state.total) ? true : false});

    // }
    // resumeUpload = () => {
    //     this.setState({downloading:true, showDownloadCard:true, paused:false});
    //     this.startStream();
    // }
    

    /** MENU related */
    changedTitle = (value) => {
        this.setState({title:value, changed:true});
    }
    confirmTitleChange = (value) => {
        this.setState({title:value});
        postWithToken("download/setTemplateName.php", {tableIdx:this.props.currentTable, name:value});
    }

    resetAll = () => {
        //confirm,
        //reset all state
        //erase desktop template on the server
        if(this.state.changed) {
            //confirm
            let confirm = window.confirm(i18n.t("template.confirm-reset"));
            if(!confirm) return;
        } 

        postWithToken('download/reset.php',{tableIdx:this.props.currentTable});
        this.setState(download_initialState);

    }
    showOpenTemplate = () => {
        this.setState({templateListOpen:true, templateLoading:true, templateList:[
            {name:'aaaa',filters:'[]',fields:'[]', share:false},
            {name:'aaaa',filters:'[]',fields:'[]', share:false},
            {name:'aaaa',filters:'[]',fields:'[]', share:false},
            {name:'aaaa',filters:'[]',fields:'[]', share:false},
            {name:'aaaa',filters:'[]',fields:'[]', share:false},
            {name:'aaaa',filters:'[]',fields:'[]', share:false},
            {name:'aaaa',filters:'[]',fields:'[]', share:false},
        ]});
        postWithToken('download/templateList.php', {tableIdx:this.props.currentTable},(data) => {
            // console.log(data.list);
            this.setState({
                templateList:data.list,
            });
        }, null, data => {
            this.setState({templateLoading:false});
        });
    }
    closeOpenTemplate = () => {
        this.setState({templateListOpen:false});

    }

    showSaveTemplate = () => {
        this.duplicate = false;
        if(this.state.templateIdx > 0) {
            //if previous template_idx then just save
            this.saveTemplate();
            

        } else {
            //if not, set if this should be shared
            this.setState({shareAlertOpen:true});
            
        }
    }
    saveTemplate = (sharing = false) => {
        this.setState({loading:true});
        postWithToken('download/saveTemplate.php',{tableIdx:this.props.currentTable, sharing:sharing ? 1 : 0, duplicate : this.duplicate ? 1: 0},data => {
            this.setState({changed:false, templateIdx:parseInt(data.templateIdx)});
        },null,data => this.setState({loading:false, shareAlertOpen:false,}));
    }
    showSaveAsTemplate = () => {
        this.duplicate = true;
        this.setState({shareAlertOpen:true});
    }
    openTemplate = (templateIdx) => {
        let confirmOpen = false;
        if(this.state.changed === true && !this.checkIsEmpty()) {
            confirmOpen = window.confirm(i18n.t("template.confirm-open"));
        }
        if(this.state.changed === false || confirmOpen || this.checkIsEmpty()) {
            this.setState({loading:true});
            this.initializeState();
            postWithToken('download/open.php',{tableIdx:this.props.currentTable, templateIdx:templateIdx},(data)=> {
                let newState = data.state;

                //pre-process state
                if(!newState.filters) newState.filters= "[]";
                if(!newState.fields) newState.fields= "[]";
                newState.filters = JSON.parse(newState.filters);
                newState.fields= JSON.parse(newState.fields);
                
                this.setState(newState);

            },null,data => {this.setState({loading:false})});

        }

    }
    deleteTemplate = () => {
        if(window.confirm(i18n.t("template.confirm-delete"))) {
            this.setState({loading:true});
            postWithToken('download/deleteTemplate.php', {tableIdx:this.props.currentTable}, data => {
                //done remove, so reset everything
                this.setState(download_initialState);
            }, null, data => this.setState({loading:false}) );

        }

    }
    checkIsEmpty = () => {
        if(this.state.templateIdx > 0 || 
            Object.values(this.state.fields).length > 0 ||
            Object.values(this.state.filters).length > 0 ||
            !!this.state.title
        ) return false;
        else return true;

    }

    render() {
        const {loading} = this.state;


        const Card1 = [
            <div className="download-filter-setting">
                <div className={"download-filter-insert-row"}>
                    <div className="name">
                        {i18n.t("download.connector")}
                    </div>
                    <Select filterable={false} 
                        activeItem={this.state.filterAndOr} items={['AND', 'OR']}
                        itemRenderer={defaultSelectItemRenderer}
                        onItemSelect={(item) => this.setState({filterAndOr:item})}>
                        <Button className="operator-button" outlined={true} intent={this.state.filterAndOr ? Intent.PRIMARY : Intent.NONE} rightIcon="double-caret-vertical">
                            <div className="operator-button-content">
                                <span className={"text"}>{(!this.state.filterAndOr) ? "AND" : this.state.filterAndOr}</span>

                            </div>
                        </Button>
                    </Select>

                </div>
                <div className="download-filter-insert-row">
                    <div className="name">
                        {i18n.t("download.column")}
                    </div> 
                    <Select 
                        disabled={this.state.uploading}
                        items={this.state.databaseColumns ? Object.values(this.state.databaseColumns) : []}
                        itemRenderer={defaultSelectFieldRenderer}
                        itemPredicate={defaultSelectFieldPredicate}
                        onItemSelect={this.selectedFilterDatabaseColumn}
                        // itemDisabled={this.isDatabaseColumnDisabled}
                        activeItem={this.state.filterDatabaseColumn}
                        >
                        <Button  outlined={true}  text={this.state.filterDatabaseColumn ? this.state.filterDatabaseColumn.name : i18n.t("upload.select-database-match")}  rightIcon="double-caret-vertical" intent={Intent.PRIMARY} />

                    </Select>
                </div>
                <div className="download-filter-insert-row">
                    <div className="name">
                        {i18n.t("download.filter")}
                    </div> 
                    <FilterInput
                        disabledMessage={i18n.t("download.select-column")} 
                        disabled={!this.state.filterDatabaseColumn} 
                        id="filter" 
                        outlined={true}
                        inline={true} 
                        operator={this.state.filterDatabaseOperator ?? null}
                        value={this.state.filterDatabaseValue ?? null}
                        value2={this.state.filterDatabaseValue2 ?? null}
                        onOperatorSelect={(id, value) => {this.setState({filterDatabaseOperator:value})}}
                        onValueChange={(id, value, isSecondary)=>this.setState({[isSecondary ? 'filterDatabaseValue2' : 'filterDatabaseValue']:value})}
                        
                        type={this.state.filterDatabaseColumn ? parseInt(this.state.filterDatabaseColumn.type) : 0} />
                        
                </div>
                <div className="download-filter-insert-row">
                    <div className="name">
                        
                    </div> 
                    <Button onClick={this.addFilter} text={i18n.t("download.add")} disabled={!this.state.filterDatabaseColumn || !this.state.filterDatabaseOperator || (!this.shouldDisableAndOr && !this.state.filterAndOr)}  intent={Intent.PRIMARY} />
                    <div style={{width:'10px'}} />
                    <Button onClick={()=>this.addParenthesis(true)} minimal={true} text={i18n.t("download.add-parenthesis-and")}  intent={Intent.PRIMARY} />
                    <Button onClick={()=>this.addParenthesis(false)} minimal={true} text={i18n.t("download.add-parenthesis-or")}  intent={Intent.PRIMARY} />
                </div>
                


            </div>,
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable" key="1">
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps}
                        ref={provided.innerRef} className={"download-filter-list" + (!(this.state.filters && Array.from(this.state.filters).length > 0) ? " hidden" : "")}>
                            {this.state.filters && Array.from(this.state.filters).length > 0 && this.state.filters.map((filter,index) => {


                                let key, content, connector;
                                if(filter.type==='filter') {
                                    if(index > 0 && !(this.state.filters[index-1].type==='parenthesis' && this.state.filters[index-1].start)) connector = filter.and ? 'AND' : 'OR';
                                    content = [
                                        <span className="name">{filter.column.name}</span>,
                                        <span className="operator">{i18n.t(`operator.${filter.operator}`)}</span>,
                                        <span className={"value" + (filter.value ? "" : " empty")}>{filter.value ?? i18n.t("general.empty")}</span>,
                                        filter.operator ==='between' && <span className="and">and</span>,
                                        filter.operator ==='between' &&<span className={"value" + (filter.value ? "" : " empty")}>{filter.value2 ?? i18n.t("general.empty")}</span>
                                    ];
                                    
                                    key = filter.timestamp.toString();
                                } else if(filter.type==='parenthesis') {
                                    if(filter.start && index > 0 && !(this.state.filters[index-1].type==='parenthesis' && this.state.filters[index-1].start)) {
                                        //
                                        connector = filter.and ? 'AND' : 'OR';

                                    }
                                    content = filter.start ? "(" : ")";
                                    key = filter.timestamp.toString()+ content;
                                }

                                let indentLevel = 0;
                                for(var i=0;i<=index;i++) {
                                    //until index
                                    if(this.state.filters[i].type==='parenthesis' && this.state.filters[i].start) {
                                        if(i===index) break;
                                        indentLevel++;
                                    } else if(this.state.filters[i].type==='parenthesis' && !this.state.filters[i].start) {
                                        indentLevel--;
                                    }
                                }

                                const indentMargin = indentLevel*30;

                                return <Draggable key={key} draggableId={key} index={index}>
                                        {(provided2, snapshot2) => (
                                            <div className={"item" + (filter.type==='parenthesis' ? " parenthesis" : "")+(snapshot2.isDragging ? " active" : "")} 
                                            ref={provided2.innerRef}
                                            
                                            {...provided2.dragHandleProps}
                                            {...provided2.draggableProps}
                                            
                                            >
                                                <span key="spacer" className="spacer" style={{width:`${indentMargin}px`}}></span>
                                                {connector && <span key="connector" className="connector">{connector}</span>}
                                                <span key="content" className="content">{content}</span>
                                                <span key="delete" onClick={() => this.deleteFilter(index)} className="delete"><Button icon="trash" minimal={true} intent={Intent.DANGER} /></span>

                                            </div>
                                        )}
                                    </Draggable>

                            })}
                        </div>
                        
                    )}
                    

                </Droppable>
            </DragDropContext>
            
            

        ];

        const Card2 = [
            <div className="download-column-select">
                <Select 
                    disabled={this.state.uploading}
                    items={this.state.databaseColumns ? Object.values(this.state.databaseColumns) : []}
                    itemRenderer={defaultSelectFieldRenderer}
                    itemPredicate={defaultSelectFieldPredicate}
                    onItemSelect={this.selectedDatabaseColumn}
                    itemDisabled={this.isDatabaseColumnDisabled}
                    activeItem={this.state.databaseColumn}
                    >
                    <Button  outlined={true}  text={this.state.databaseColumn ? this.state.databaseColumn.name : i18n.t("download.select-column-download")}  rightIcon="double-caret-vertical" intent={Intent.PRIMARY} />

                </Select>
                <Button disabled={!this.state.databaseColumn} onClick={this.addDatabaseColumn} style={{marginLeft:'10px'}} text={i18n.t("download.add")} intent={Intent.PRIMARY}></Button>
                <Button minimal={true} onClick={this.addAllDatabaseColumn} style={{marginLeft:'10px'}} text={i18n.t("download.add-all")} intent={Intent.PRIMARY}></Button>

            </div>,

            <div className={"download-filter-list" + (!(this.state.fields && Array.from(this.state.fields).length > 0) ? " hidden" : "")}  style={{padding:'0px 4px'}}>
                {this.state.fields && Array.from(this.state.fields).map((field,index) => 
                <DatabaseField field={field} onDelete={()=>this.deleteField(index)} />)}
            </div>
        ];

        const ProgressCard = <Card className="step-card" elevation={0} style={{marginBottom:'100px', opacity:this.state.showDownloadCard ? 1 : 0.3}}>
            <div className="title" style={{marginLeft:'0px'}}>{i18n.t("download.progress-title")}</div>
            
            <div style={{marginTop:'20px'}}>
                <span className="count progress">{this.state.progress}</span> <span className="desc progress">{i18n.t("download.progress-progress")}</span>
                <span className="count total">{this.state.total}</span> <span className="desc total">{i18n.t("download.progress-total")}</span>

            </div>
            <ProgressBar value={this.state.total > 0 ? this.state.progress/this.state.total : (this.state.downloading ? 1 : 0)} intent={Intent.PRIMARY} animate={this.state.downloading} />
            {/* <div className="download-row" style={{marginTop:'15px'}}>
                <div style={{flexGrow:'1'}}>
                    <Button active={this.state.showErrors} onClick={() => this.setState({showErrors : !this.state.showErrors})} minimal={true} intent={this.state.errors.length > 0 ? Intent.DANGER : Intent.NONE} disabled={this.state.errors.length < 1} text={i18n.t("upload.error",{count:this.state.errors.length})}/>

                </div>
                <Button style={{marginRight:'10px'}} disabled={!this.state.paused} onClick={() => this.resumeUpload()} intent={Intent.PRIMARY} icon="play">{i18n.t("upload.progress-resume")}</Button>
                <Button disabled={!!this.state.paused} onClick={() => this.pauseUpload()} intent={Intent.DANGER} icon="pause">{i18n.t("upload.progress-pause")}</Button>

            </div> */}
        </Card>;
        const TemplateListDialog = <Dialog isOpen={this.state.templateListOpen} title={i18n.t("template.templates")} onClose={this.closeOpenTemplate}>
            <div className="download-templates-list" >
                {this.state.templateList.map(template => <TemplateButton onClick={this.openTemplate} template={template} loading={this.state.templateLoading} />)}
                {this.state.templateList && this.state.templateList.length === 0 && <div className="download-template-empty">
                    {i18n.t("template.empty")}
                </div>}

            </div>
            <div className="bp3-dialog-footer">
                <div className="bp3-dialog-footer-actions">
                    <Button onClick={this.closeOpenTemplate} minimal={true} text={i18n.t('general.cancel')} />
                </div>
            </div>

        </Dialog>

        const ShareDialog = <Dialog isOpen={this.state.shareAlertOpen} onClose={() => this.setState({shareAlertOpen:false})} 
        title={i18n.t("template.set-sharing")}
        >
            <div className="bp3-dialog-body">
                {i18n.t("template.sharing-description")}

            </div>
            <div className="bp3-dialog-footer">
                <div className="bp3-dialog-footer-actions">

                    <Button loading={this.state.loading} onClick={() => this.setState({shareAlertOpen:false})} text={i18n.t("general.cancel")} intent={Intent.NONE} />
                    <Button loading={this.state.loading} onClick={() => this.saveTemplate(true)} text={i18n.t("template.share")} intent={Intent.WARNING} />
                    <Button loading={this.state.loading} onClick={() => this.saveTemplate(false)} text={i18n.t("template.private")} intent={Intent.PRIMARY} />
                </div>
            </div>



        </Dialog>;

        return <div id="body" className="divided">
            {TemplateListDialog}
            {ShareDialog}
            <div className="divided-body">
                <div className="divided-body-menu">
                    <div className="download-menu-title-description">{i18n.t("template.menu-title-description")}</div>
                    <H3><EditableText className="bp3-text-heading" value={this.state.title} onChange={this.changedTitle} onConfirm={this.confirmTitleChange } /></H3>
                    <div className="download-menu-description">
                        {this.state.shared && <Tag intent={Intent.WARNING} style={{marginRight:'10px'}}>{i18n.t("template.shared")}</Tag>}
                        <span>{i18n.t("template.by", {name:!this.state.ownerName ? i18n.t("template.me") : this.state.ownerName})}</span>
                    </div>
                    <div className="download-menu-title-menu">
                        <TitleMenu disabled={this.checkIsEmpty()} icon="document" menu="new" onClick={this.resetAll} />
                        <TitleMenu icon="folder-open" menu="open" onClick={this.showOpenTemplate} />
                        <Divider />
                        
                        <TitleMenu intent={(!this.checkIsEmpty() && this.state.owner) && this.state.changed ? Intent.DANGER : Intent.NONE} disabled={this.checkIsEmpty() || this.state.changed===false||this.state.owner ===false} icon="floppy-disk" menu="save" onClick={this.showSaveTemplate} />
                        <TitleMenu disabled={this.checkIsEmpty() || !this.state.templateIdx  } icon="duplicate" menu="save-as" onClick={this.showSaveAsTemplate} />
                        <Divider />
                        <TitleMenu disabled={!this.state.templateIdx || this.state.owner ===false} icon="trash" menu="delete" onClick={this.deleteTemplate} />
                    </div>
                </div>
                <div className={"divided-body-content download-page-body" + (loading ? " loading" : "") }>
                    <h4 className="bp3-heading title">
                        {downloadIcon('#333')} DOWNLOAD DATA 
                    </h4>
                    <StepCard  disabled={this.state.downloading} step={1} title={i18n.t("download.card-title-1")}
                    subtitle={i18n.t("download.card-subtitle-1")}>
                        {Card1}
                    </StepCard>
                    <DownArrow />
                    <StepCard  disabled={this.state.downloading} step={2} title={i18n.t("download.card-title-2")}
                    subtitle={i18n.t("download.card-subtitle-2")}>
                        {Card2}
                    </StepCard>

                    <DownArrow />
                    <div style={{textAlign:'center', marginTop:'20px', marginBottom:'20px', marginRight:'20px'}}>

                        <Button loading={this.state.downloading} onClick={()=>this.startStream(true)} intent={Intent.PRIMARY} large={true} text={i18n.t("download.start-download")} fill={true} rightIcon="cloud-download" />
                    </div>
                    {ProgressCard}
                </div>
                
            </div>
        </div>
    }
}

function DatabaseField(props) {
    return <div className="download-database-field">
        <Icon icon={getIconForType(props.field.type)} color="#8A9BA8" iconSize={13} />
        <span className="name">{props.field.name}</span>
        <Button onClick={props.onDelete} small={true} icon="small-cross" minimal={true} />
    </div>;
}
function TemplateButton(props) {
    return <Button fill={true} onClick={()=>props.onClick(props.template.template_idx)} className={props.loading ? "bp3-skeleton" : ""} minimal={true} style={{margin:'5px 0px'}}>
        <div className="download-templates-list-item">
            <span className="name">{props.template.name}</span>
            <span className="shared">{parseInt(props.template.share)>0 && <Tag intent={Intent.WARNING}>{i18n.t("template.shared")}</Tag>}</span>
            <span className="filters">{Object.values(JSON.parse(props.template.filters)).length} {i18n.t("template.filters")}</span>
            <span className="fields">{Object.values(JSON.parse(props.template.fields)).length} {i18n.t("template.fields")}</span>

        </div>

    </Button>
}


export {DownloadPage}