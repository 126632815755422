import { Callout, Button, ButtonGroup, Card, Classes, Dialog, Intent, Spinner } from '@blueprintjs/core';
import {editFormIcon } from "../_components";
import i18n from 'i18next';
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import './EditFormPage.css';
import InfiniteScroll from 'react-infinite-scroller';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {NetworkToaster,history, addSection, deleteSection, getWithToken, move, moveSectionDown, moveSectionUp, reorder} from '../_helpers';


import { postWithToken } from '../_helpers';
import { SectionCard } from './SectionCard';
import { FieldDialog } from './FieldDialog';
import { SectionMenu } from '../MenuPage/SectionMenu';



class EditFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /****
       * element = {
       *  'name' : section title,
       *  'children' : [
       *    {'field_idx' / 'name' / 'type' }
       *    ...
       *  ]
       *  
       * }
       * 
       */
      list:[],
      loading:false,
      fieldDialogOpen:false,
      fieldDialogNew:true,
      fieldDialogFieldIdx:null,
      fieldDialogSectionIndex:null,
    }
    this.surveyEnabled=false;
  }

  componentDidMount() {
    this.loadLayout();
  }

  loadLayout = () => {
    this.setState({
      loading:true,
    })
    postWithToken('editform/layout.php',{tableIdx:this.props.currentTable},(data)=> {
      this.setState({
        list:JSON.parse(data.data.layout) ?? [],
      })

      this.surveyEnabled = parseInt(data.surveyEnabled) > 0;

    },(errorMessage,data) => {
      if(data.message === 'no_permission') {
          //then return to tables
          history.push('/');
      } else {
        NetworkToaster.show({message:errorMessage, intent:Intent.DANGER, icon:"warning-sign"});
      }
    },(data) => {
      this.setState({
        loading:false,
      })

    })

  }


  updateLayout = () => {
    // TODO : update to server
    // console.log(this.state.list);
    postWithToken('editform/saveLayout.php',{
      tableIdx:this.props.currentTable,
      layout:JSON.stringify(this.state.list),
    });
  }

  onSectionNameChange = (index, newName) => {
    var newState = this.state.list;
    newState[index].name = newName;
    this.setState({
      list:newState,
    });

  }
  onSectionNameSubmit = () => {
    this.updateLayout();
  }

  onSectionDescriptionChange = (index, newDescription) => {
    var newState = this.state.list;
    newState[index].description = newDescription;
    this.setState({
      list:newState,
    });

  }
  onSectionDescriptionSubmit = () => {
    this.updateLayout();
  }
  moveSectionUp = (index) => {
    let newList = moveSectionUp(this.state.list,index);
    this.setState({
      list:newList,
    });
    this.updateLayout();
  }
  moveSectionDown = (index) => {
    let newList = moveSectionDown(this.state.list,index);
    this.setState({
      list:newList,
    });
    this.updateLayout();
  }
  deleteSection = (index) => {
    let newList = deleteSection(this.state.list,index);
    this.setState({
      list:newList,
    });
    this.updateLayout();
  }
  addSection = (index) => {
    let newList = addSection(this.state.list,index,i18n.t("editform.new-section"));
    this.setState({
      list:newList,
    });
    this.updateLayout();
  }

  addField = (sectionIndex, field_idx, name, type,survey) => {
    let newList = this.state.list;
    if(!newList[sectionIndex].children)  newList[sectionIndex].children = [];
    newList[sectionIndex].children.push({field_idx:field_idx, name:name, type:type,survey:survey});
    this.setState({
      list:newList,
    });
    this.updateLayout();
  }

  editField = (sectionIndex, field_idx, name, type,survey) => {
    let newList = this.state.list;
    let children = Array.from(newList[sectionIndex].children);
    children.forEach((el, ind) => {
      if(el.field_idx === field_idx) {
        newList[sectionIndex].children[ind].name = name;
        newList[sectionIndex].children[ind].type = type;
        newList[sectionIndex].children[ind].survey = survey;
        return;
      }
    });
    this.setState({
      list:newList,
    });
    this.updateLayout();
  }

  deleteField = (sectionIndex, field_idx) => {
    let newList = this.state.list;
    let children = Array.from(newList[sectionIndex].children);
    newList[sectionIndex].children = children.filter((el) => el.field_idx!==field_idx);
    this.setState({
      list:newList,
    });
    this.updateLayout();
  }


  onDragEnd = (result) => {
    let state = this.state.list;
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd].children, source.index, destination.index);
      const newState = [...state];
      newState[sInd].children = items;
      this.setState({
        list : newState,
      });
    } else {
      const result = move(state[sInd].children, state[dInd].children, source, destination);
      const newState = [...state];
      newState[sInd].children = result[sInd];
      newState[dInd].children = result[dInd];

      this.setState({
        list : newState,
      });
    }
    this.updateLayout();
  }

  addSectionButton = (index, alwaysShown = false) => {
    return <Button minimal={true} intent={Intent.PRIMARY} icon="small-plus" 
    onClick={()=>{this.addSection(index)}} className={alwaysShown ? "bp3-fill":"section-add-button bp3-fill"}>
      {i18n.t("editform.add-section")}
    </Button>;
  }



  openFieldDialog = (sectionIndex, isNew, field_idx = false) => {
    this.setState({
      fieldDialogOpen:true,
      fieldDialogFieldIdx: field_idx,
      fieldDialogNew:isNew,
      fieldDialogSectionIndex:sectionIndex,
    })
  }

  closeFieldDialog = () => {
    this.setState({
      fieldDialogOpen:false,
    })

  }


  shouldShowSurveyWarning = () => {
    let shouldShow = false;
    if(this.surveyEnabled===false) {
      //check if there is survey inside list
      this.state.list.forEach((el) => {
        if(el.children.length > 0) {
          el.children.forEach(el2 => {
            if(el2.survey) {
              shouldShow = true;
              return;
            }
          });
          if(shouldShow) return;
        }
      })
    }
    return shouldShow;
  }

  getFieldCount = () => {
    let count=0;
    this.state.list.forEach((element) => {
      count+= element.children.length;

    });
    return count;
  }
  render() {
    // console.log(this.getFieldCount());
    const {currentTable} = this.props;
    if(currentTable === null) return <Redirect to="/" />;

    const SurveyWarning = <Callout style={{marginTop:'30px'}} intent={Intent.DANGER} >
      {i18n.t("editform.survey-warning")} <Link to="/settings">{i18n.t("editform.goto-settings")}</Link>
    </Callout>

    return  <div id="body" className="divided">
      <FieldDialog
      tableIdx = {this.props.currentTable}
       isOpen={this.state.fieldDialogOpen} 
       isNew={this.state.fieldDialogNew} 
       fieldIdx={this.state.fieldDialogFieldIdx} 
       sectionIndex={this.state.fieldDialogSectionIndex} 
       onClose={this.closeFieldDialog} 
       onSave={this.state.fieldDialogNew ? this.addField : this.editField}
       onDelete = {this.deleteField}
       shouldDisplayHelper = {this.getFieldCount() === 0}
        />
      
      <div id="edit-form-list" className="divided-body">
        <div className="divided-body-content">
          <h4 className="bp3-heading title">
            {editFormIcon('#333')} EDIT FORM 
          </h4>
          {this.shouldShowSurveyWarning() && SurveyWarning}
          {this.state.loading ? <div className="edit-form-spinner"><Spinner /></div> : 
          <DragDropContext onDragEnd={this.onDragEnd}>
            {this.addSectionButton(0, this.state.list.length === 0 ? true : false)}
            {
              this.state.list.map((el, ind) => (<Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <div className="section-wrapper">
                    <SectionCard element={el} ind={ind} last={this.state.list.length === ind+1} provided={provided} snapshot={snapshot}
                    onSectionNameChange={this.onSectionNameChange}
                    onSectionNameSubmit={this.onSectionNameSubmit}
                    onSectionDescriptionChange={this.onSectionDescriptionChange}
                    onSectionDescriptionSubmit={this.onSectionDescriptionSubmit}
                    onMoveSectionUp={this.moveSectionUp}
                    onMoveSectionDown={this.moveSectionDown}
                    onDeleteSection = {this.deleteSection}
                    onFieldDialogOpen={this.openFieldDialog}

                    />
                    {this.addSectionButton(ind+1, ind+1 === this.state.list.length)}
                  </div>
                )}
                </Droppable>
              ))
            }

            

          </DragDropContext>}
          

        </div>
        <div className="divided-body-menu hide-when-mobile">
          <SectionMenu layout={this.state.list} rootEl="#body" />

        </div>
        
      </div>
    </div>
  }
}

export {EditFormPage}