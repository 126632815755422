import { authenticationService } from '../_services';

export function handleResponse(response) {
    // if(response.ok === false) {
    //     console.log(response.status);
    //     if ([401, 403].indexOf(response.status) !== -1) {
    //         // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    //         authenticationService.logout();
    //         window.location.reload();
    //     } 
    // }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        // TODO : erase this on production
        // console.log(response);
        // console.log(data);

        if (!data.success) {
            if ([401, 403].indexOf(response.status) !== -1 || (data.message !== undefined && data.message ==='logged_out')) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                authenticationService.logout();
                window.location.reload();
            }

            // const error = (data && data.success ===false) || response.statusText;
            // return Promise.reject(data.message ?? "unknown_error");
        } else if(data.newToken !== undefined) {
            // console.log(`updating token... ${data.token}`);
            authenticationService.updateToken(data);
            
        }

        return data;
    }).catch(e=> alert(e));
}