import React, { useState } from 'react'
import { Draggable } from "react-beautiful-dnd";
import { Button, ButtonGroup, Card, Classes, EditableText, Intent, H4, Divider, Tooltip } from '@blueprintjs/core';
import { FieldCard } from './FieldCard';
import './SectionCard.css';
import i18n from 'i18next';
import { eraseWhiteSpaces } from '../_helpers';

class SectionCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const { element, provided, ind, last, snapshot, onSectionNameChange, onSectionNameSubmit } = this.props;
    return (<section id={`section-${ind}`} className={"section-card" + (snapshot.isDraggingOver ? " active" : "")}
      ref={provided.innerRef}
      {...provided.droppableProps}
    >
      <div className="section-card-header">
        <span className="title">
          <EditableText value={element.name}
            onChange={(e) => this.props.onSectionNameChange(this.props.ind, e)} onConfirm={this.props.onSectionNameSubmit}
          >{element.name}</EditableText>

        </span>
        <span className="menu">
          <Button onClick={() => this.props.onFieldDialogOpen(this.props.ind, true)} minimal={true} icon="small-plus" intent={Intent.PRIMARY}>{i18n.t("editform.add-field")}</Button>
          <Divider />
          <Button icon="chevron-up" minimal={true} disabled={ind === 0} onClick={() => { this.props.onMoveSectionUp(this.props.ind) }} />
          <Button icon="chevron-down" minimal={true} disabled={last} onClick={() => { this.props.onMoveSectionDown(this.props.ind) }} />
          {element.children.length === 0 && <Divider />}
          {element.children.length === 0 && <Button icon="trash" minimal={true} disabled={element.children.length > 0} onClick={() => { this.props.onDeleteSection(this.props.ind) }} />}


        </span>

      </div>
      <EditableText value={element.description}
        onChange={(e) => this.props.onSectionDescriptionChange(this.props.ind, e)} onConfirm={this.props.onSectionDescriptionSubmit}
        className="section-description" placeholder='Section description (Optional)' multiline={true} minLines={1} maxLines={12} />
      {element.children.length === 0 &&
        <div className="section-empty">
          <div className="arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="130" viewBox="0 0 411.455 216.565">
              <g id="Group_74" data-name="Group 74" transform="translate(-1169.549 -595.747)">
                <path id="Path_63" data-name="Path 63" d="M6550.124,4337.745s-5.389-116.329,103.466-152.661c76.524-25.541,125.048,22.913,203.05,18.781,32.959-1.746,74.1-26.822,92.059-74.862" transform="translate(-5379 -3527)" fill="none" stroke="#8A9BA8" stroke-linecap="round" stroke-width="3" />
                <path id="Path_64" data-name="Path 64" d="M6732.608,4125.569h18.262l-6.656,14.438" transform="matrix(0.848, -0.53, 0.53, 0.848, -6340.248, 676.995)" fill="none" stroke="#8A9BA8" stroke-width="3" />
              </g>
            </svg>

          </div>
          <div className="description">
            {i18n.t("section.empty")}

          </div>
        </div>}
      {element.children.map((item, index) => (
        <Draggable
          key={item.field_idx}
          draggableId={item.field_idx.toString()}
          index={index}
        >
          {(provided, snapshot) => (
            <FieldCard provided={provided} item={item} snapshot={snapshot} onClick={() => this.props.onFieldDialogOpen(this.props.ind, false, item.field_idx)} />
          )}
        </Draggable>
      ))}
      {provided.placeholder}
    </section>);
  }
}


/***
 * element = {
 *  'name' : section title,
 *  'children' : [
 *    {'field_idx' / 'name' / 'type' / 'options' / 'required' / 'title_field'}
 *    ...
 *  ]
 *  
 * }
 * 
 */




export { SectionCard }