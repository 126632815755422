import { Button, Callout, Card, Classes, Dialog, FormGroup, H4, InputGroup, Intent } from '@blueprintjs/core';
import i18next from 'i18next';
import React from 'react';
import { history, postWithToken } from '../_helpers';
import {NetworkToaster} from '../_helpers';

class PatientDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code : '',
            name : '',
            loading:false,
            showError:false,

            /** mutlipage */
            showName:false,
            anonymized: '',
        }
    }

    componentDidMount() {
        if(this.props.recordIdx !== undefined) {
            this.load();
        }
    }
    componentDidUpdate(prevProps) {
        // if(prevProps.currentTableIsMulti !== this.props.currentTableIsMulti) {
        //     this.setState();
        // }
        if(this.props.recordIdx !== prevProps.recordIdx) {
            this.load();
        }

    }
    load = () => {
        const {recordIdx} = this.props;

        this.setState({loading: true});

        postWithToken('records/loadPatientInfo.php', {tableIdx:this.props.currentTable,recordIdx:recordIdx},
        (data) => {
            this.setState({
                code:data.code,
                name:data.name,
            })
        }, null, () => {
            this.setState({loading: false});
        });

    }

    submit = () => {
        //validate field
        //validate code only (name is optional)
        let {code, name} = this.state;
        const {currentTable, recordIdx} = this.props;

        code = code.trim();

        if(code!=="") {
            //not empty
            this.setState({loading:true});

            if(recordIdx === undefined) {
                postWithToken('records/register.php', {
                    tableIdx: currentTable,
                    code:code,
                    name:name,
                },(data)=>{
                    history.push(`/records/${data.recordIdx}`);
                }, (_, data) => {
                    console.error(data);
                    if(data.message ==='duplicate_code') {
                        if(window.confirm(i18next.t("record.duplicate-code"))) {
                            history.push(`/records/${data.recordIdx}`);
                        }
                    } else {
                        let errorMessage;
                        if(data.message) errorMessage = data.message;
                        else errorMessage = "general";
                        NetworkToaster.show({message:i18next.t(`error.${errorMessage}`), intent:Intent.DANGER, icon:"warning-sign"});
                    }
                }, ()=> {
                    this.setState({loading:false});
                });

            } else {
                //already registered, so edit patient info
                postWithToken('records/editPatientInfo.php', {
                    tableIdx: currentTable,
                    recordIdx : recordIdx,
                    code:code,
                    name:name,
                },(data)=> {
                    this.props.onClose();
                }, null, () => {
                    this.setState({loading:false});
                })
            }



        } else {
            //show error
            this.setState({showError:true});
        } 

    }
    submitMulti = () => {
        //validate field
        //validate code only (name is optional)
        let {code, name, anonymized} = this.state;
        const {currentTable, currentTableIsMulti} = this.props;

        code = code.trim();
        anonymized = anonymized.trim();

        if(code!=="" || anonymized!=="") {
            //not empty
            this.setState({loading:true});

            postWithToken('records/register.php', {
                tableIdx: currentTable,
                multi:currentTableIsMulti,
                code:code,
                anonymized:anonymized,
                showName:this.state.showName,
                name:name,
            },(data)=>{
                // TODO : check type of result ->
                if(data.requireName !== undefined && data.requireName === true) {
                    this.setState({showName:true});
                } else {
                    history.push(`/records/${data.recordIdx}`);
                }
            }, (_, data) => {
                console.error(data);
                if(data.message ==='duplicate_code') {
                    if(window.confirm(i18next.t("record.duplicate-code"))) {
                        history.push(`/records/${data.recordIdx}`);
                    }
                } else {
                    let errorMessage;
                    if(data.message) errorMessage = data.message;
                    else errorMessage = "general";
                    NetworkToaster.show({message:i18next.t(`error.${errorMessage}`), intent:Intent.DANGER, icon:"warning-sign"});
                }
            }, ()=> {
                this.setState({loading:false});
            });

        } else {
            //show error
            this.setState({showError:true});
        }


    }

    changed = (name, value) => {
        this.setState({[name] : value});
    }

    getDialogFooter = () => {
        if(this.props.permission > 0) {
            return <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button minimal={true} onClick={()=>this.props.onClose()} loading={this.state.loading} >
                    {i18next.t("general.cancel")}
                </Button>
                <Button type="submit" onClick={()=>this.submit()} intent={Intent.PRIMARY} loading={this.state.loading} >
                    {i18next.t(this.props.recordIdx !== undefined ? "general.save":"record.create-patient")}
                </Button>
            </div>

        } else {
            return <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button minimal={true} onClick={()=>this.props.onClose()} loading={this.state.loading} >
                    {i18next.t("general.close")}
                </Button>
            </div>

        } 
    }

    getDialogFooterMulti = () => {
        if(this.props.permission > 0) {
            return <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button minimal={true} onClick={()=>this.props.onClose()} loading={this.state.loading} >
                    {i18next.t("general.cancel")}
                </Button>
                <Button type="submit" onClick={()=>this.submitMulti()} intent={Intent.PRIMARY} loading={this.state.loading} >
                    {i18next.t(this.props.recordIdx !== undefined ? "general.save":"record.create-patient")}
                </Button>
            </div>

        } else {
            return <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button minimal={true} onClick={()=>this.props.onClose()} loading={this.state.loading} >
                    {i18next.t("general.close")}
                </Button>
            </div>

        } 
    }

    renderDefault = () => {
        
        const codeIsEmpty = this.state.showError && this.state.code.trim()==="";

        return <Dialog autoFocus={true} 
        isOpen={this.props.isOpen} onClose={this.props.onClose}
        title={i18next.t("record.patient-dialog-title")}>
            <form>
                <div className={Classes.DIALOG_BODY}>
                    <Callout style={{marginBottom:'20px'}}>
                        <small>{i18next.t("record.patient-dialog-description")}</small>
                    </Callout>
                    <FormGroup
                        helperText={codeIsEmpty && i18next.t("record.patient-dialog-code-empty")}
                        label={i18next.t("record.patient-dialog-hospital-no")}
                        labelFor="hospital_no"
                        labelInfo="(required)"
                        intent={codeIsEmpty ? Intent.DANGER : Intent.NONE}
                    >
                        <InputGroup readOnly={this.props.permission === 0} autoFocus={true} disabled={this.state.loading} id="hospital_no" placeholder={i18next.t("record.patient-dialog-hospital-no-placeholder")}
                        onChange={(e) => this.changed('code',e.target.value)} value={this.state.code}
                        intent={codeIsEmpty ? Intent.DANGER : Intent.NONE}
                        />
                    </FormGroup>
                    <FormGroup
                        label={i18next.t("record.patient-dialog-patient-name")}
                        labelFor="patient_name"
                        labelInfo="(optional)"
                    >
                        <InputGroup  readOnly={this.props.permission === 0} disabled={this.state.loading} id="patient_name" placeholder={i18next.t("record.patient-dialog-patient-name-placeholder")}
                        onChange={(e) => this.changed('name',e.target.value)} value={this.state.name} />
                    </FormGroup>

                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    {this.getDialogFooter()}
                </div>
            </form>

        </Dialog>;

    }

    renderMulti = () => {
        const codeIsEmpty = this.state.showError && this.state.code.trim()==="";
        return <Dialog autoFocus={true} 
        isOpen={this.props.isOpen} onClose={this.props.onClose}
        title={i18next.t("record.patient-dialog-title")}>
            <form  onSubmit={(e) => e.preventDefault()}>
                <div className={Classes.DIALOG_BODY}>
                    
                    <Callout style={{marginBottom:'20px'}}>
                        <small>{i18next.t("record.patient-dialog-description-multi")}</small>
                    </Callout>
                    <div className="warning-text">
                        {codeIsEmpty && i18next.t("record.multi-empty-error")}
                    </div>
                    <div className="record-patient-dialog-multi-body">
                        <div>
                            <FormGroup
                                helperText={i18next.t("record.hospital-no-helper")}
                                label={i18next.t("record.patient-dialog-hospital-no")}
                                labelFor="hospital_no"
                                // labelInfo="registered/new"
                                intent={codeIsEmpty ? Intent.DANGER : Intent.NONE}
                            >
                                <InputGroup autoFocus={true} disabled={this.state.loading || this.state.anonymized} id="hospital_no" placeholder={i18next.t("record.patient-dialog-hospital-no-placeholder")}
                                onChange={(e) => this.changed('code',e.target.value)} value={this.state.code}
                                intent={codeIsEmpty ? Intent.DANGER : Intent.NONE}
                                />
                            </FormGroup>
                            {this.state.showName && <FormGroup
                                label={i18next.t("record.patient-dialog-patient-name")}
                                labelFor="patient_name"
                                labelInfo="(optional)"
                            >
                                <InputGroup  disabled={this.state.loading} id="patient_name" placeholder={i18next.t("record.patient-dialog-patient-name-placeholder")}
                                onChange={(e) => this.changed('name',e.target.value)} value={this.state.name} />
                            </FormGroup>}

                        </div>
                        <div className="separator">
                            <div className="line"></div>
                            <div>{i18next.t("record.or")}</div>
                            <div className="line"></div>
                            

                        </div>
                        <div>
                            <FormGroup
                                helperText={i18next.t("record.anonymized-no-helper")}
                                label={i18next.t("record.patient-dialog-anonymized-no")}
                                labelFor="anonymized_no"
                                // labelInfo="registered"
                                intent={codeIsEmpty ? Intent.DANGER : Intent.NONE}
                            >
                                <InputGroup disabled={this.state.loading || this.state.code} id="anonymized_no" placeholder={i18next.t("record.patient-dialog-anonymized-no-placeholder")}
                                onChange={(e) => this.changed('anonymized',e.target.value)} value={this.state.anonymized}
                                intent={codeIsEmpty ? Intent.DANGER : Intent.NONE}
                                />
                            </FormGroup>

                        </div>

                    </div>

                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    {this.getDialogFooterMulti()}
                </div>
            </form>

        </Dialog>;

    }

    render() {
        if(this.props.currentTableIsMulti === "multi") {
            return this.renderMulti();
        } else {
            //single
            return this.renderDefault();
        }

    }
}
export {PatientDialog};