import React from 'react';
import { Router, Route } from 'react-router-dom';
import { history } from './_helpers';
import { authenticationService } from './_services';
import { PrivateRoute } from './_components';
import  {HomePage}  from './HomePage';
import { LoginPage, LostpassPage } from './LoginPage';
import {EditFormPage} from './EditFormPage';
import {SettingsPage} from './SettingsPage';
import {MenuPage} from './MenuPage';
import {RecordsViewPage, RecordsPage} from './RecordsPage';
import { UploadPage } from './UploadPage/UploadPage';
import { DownloadPage } from './DownloadPage/DownloadPage';
import {CoWorkersPage} from './CoWorkers';
import { SignupPage } from './SignupPage';

class App extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
          currentUser: null,
          currentTable: sessionStorage.getItem('currentTable'),
          currentTableName:sessionStorage.getItem('currentTableName'),
          currentTableIsMulti:sessionStorage.getItem('currentTableIsMulti'),
          currentTablePermission:parseInt(sessionStorage.getItem('currentTablePermission')),
      };
      
  }

  componentDidMount() {
      authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));

  }

  logout() {
      authenticationService.logout();
      history.push('/login');
  }

  setCurrentTable = (idx, tableName, multi,  permission) => {
      sessionStorage.setItem('currentTable', idx);
      sessionStorage.setItem('currentTableName', tableName);
      
      sessionStorage.setItem('currentTableIsMulti', multi);
      sessionStorage.setItem('currentTablePermission', permission);
      this.setState({
          currentTable : idx,
          currentTableName:tableName,
          currentTableIsMulti:multi,
          currentTablePermission:permission
      });

  }
  resetCurrentTable = () => {
    this.resetCurrentTableSession();
    history.push('/');
  }
  resetCurrentTableSession = () => {
    // sessionStorage.removeItem('currentTable');
    // sessionStorage.removeItem('currentTableName');
    // sessionStorage.removeItem('currentTableIsMulti');
    // sessionStorage.removeItem('currentTablePermission');


    /* records related */
    sessionStorage.clear();



    if(this.state.currentTable !== null) {
        this.setState({
            currentTable:null,
            currentTableName:null,
            currentTableIsMulti:null,
            currentTablePermission:null,
        });

    }


  }

  render() {
      return (
          <Router history={history}>
            <div id="main">
                <Route 
                    render={({ location }) => ['/login','/register','/lostpass'].includes(location.pathname)
                        ? null
                        : <MenuPage resetTable={this.resetCurrentTable}  currentMenu={location.pathname.substring(1)} {...this.state} />
                    }
                />
                
                <PrivateRoute exact path="/" component={(props) => <HomePage {...props} setCurrentTable={this.setCurrentTable} onLoad={this.resetCurrentTableSession} />} />
                <PrivateRoute exact path="/records" component={(props) => <RecordsPage {...this.state} {...props} />} />
                <PrivateRoute path="/records/:recordIdx" component={(props) => <RecordsViewPage {...this.state} {...props} />} />
                <PrivateRoute path="/upload-data" component={(props) => <UploadPage {...this.state} {...props} />} />
                <PrivateRoute path="/download-data" component={(props) => <DownloadPage {...this.state} {...props} />} />
                <PrivateRoute path="/edit-form" component={(props) => <EditFormPage {...this.state} {...props} />} />
                <PrivateRoute path="/co-workers" component={(props) => <CoWorkersPage {...this.state} {...props} />} />
                <PrivateRoute path="/settings" component={(props) => <SettingsPage {...this.state} {...props} />} />
                <Route path="/lostpass" component={(props) => <LostpassPage {...props} />} />
                <Route path="/login" component={LoginPage} />
                <Route path="/register" component={SignupPage} />
            </div>
              
                  {/* {currentUser &&
                      <nav className="navbar navbar-expand navbar-dark bg-dark">
                          <div className="navbar-nav">
                              <Link to="/" className="nav-item nav-link">Home</Link>
                              <a onClick={this.logout} className="nav-item nav-link">Logout</a>
                          </div>
                      </nav>
                  } */}
                  
                  {/* <PrivateRoute exact path="/" component={HomePage} /> */}
                  
                
              
          </Router>
      );
  }
}


export default App;
