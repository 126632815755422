import { Button, EditableText, Intent, Spinner, Tooltip, Classes, Position, RadioGroup, Radio, Checkbox, Divider, Tag, Alert, Icon } from '@blueprintjs/core';
import {DateInput} from '@blueprintjs/datetime';
import i18next from 'i18next';
import React from 'react';
import { eraseWhiteSpaces, getEmptyFields, history, isFieldEmpty, postWithToken } from '../_helpers';
import './RecordsViewPage.css';
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import {jsDateFormatter, getMomentFormatter} from '../_helpers';
import moment from "moment";
import { DateTimePicker } from './DateTimePicker';
import Scrollspy from 'react-scrollspy';
import { SectionMenu } from '../MenuPage/SectionMenu';
import { PatientDialog } from './PatientDialog';

const records_view_init_state = {
    loading:false,
    record : null,
    patientNo:null,
    layout : null,
    fields:null,
    permission:0,
    history:null,
    errorForKey:null,
    required:[],
    highlightedIndex:null,
    recordDifference:null,
    historyIndex:null,
    historyLoading:false,
    deleteAlertOpen:false,
    deleteLoading:false,
    showPatientInfo:false,
    // currentTable:props.currentTable,
    fixNavigation:false,
    totalListCount:0,
    currentListCount:0,
    nextRecordIdx:0,
    previousRecordIdx:0,
    
    
}

class RecordsViewPage extends React.Component {

    constructor(props) {
        super(props);





        var newState = records_view_init_state;
        newState.currentTable = props.currentTable;
        this.state = newState;
        this.highlightTimeout = null;


        //update sessionStorages' current record (because the user could have navigated multiple times to different records)
        this.recordIdx = this.props.match.params.recordIdx;
        // sessionStorage.setItem("records_clicked", this.recordIdx);


        
    }

    initializeState = () => {
        this.setState(records_view_init_state);
    }


    componentDidMount() {
        this.getData();
        window.addEventListener("scroll", this.scrolledBody);
    }

    getSubTitle = () => {
        let titleFields = [];
        const {recordIdx} = this.props.match.params;

        if(this.state.record !== null && this.state.fields !== null) {
            for(const [key, element] of Object.entries(this.state.fields)) {
                if(parseInt(element.title_field)>0) {
                    //title field
                    const thisKey = element.key;
                    const thisValue = this.state.record[thisKey];
                    if(titleFields.length > 0) titleFields.push(<span key={`separator_${thisKey}`} className="gray-text"> / </span>);
                    if(isFieldEmpty(thisValue) === false) {
                        titleFields.push(<span key={thisKey} className="bp3-text-large">{String(thisValue)}</span>);

                    } else {

                        titleFields.push(<span key={thisKey} className="gray-text">{i18next.t("general.empty")}</span>);
                    }

                    


                }
            }

            //UPDATE list cache with value changes
            //fill localStorage (which saves record list) with titlekeys
            if(sessionStorage.getItem("records_state") !== null) {
                var listState = JSON.parse(sessionStorage.getItem("records_state"));
                var stateChanged = false;
                listState.list.forEach((eachObj, index) => {
                    //for each record... find this record
                    if('records_idx' in eachObj) {
                        if(parseInt(eachObj['records_idx']) === parseInt(recordIdx)) {

                            //then this object is this record object
                            listState.titleFields.forEach((eachField, index) => {
                                if(eachObj[eachField['id']] !== this.state.record[eachField['id']]) {
                                    stateChanged = true;
                                    eachObj[eachField['id']] = this.state.record[eachField['id']]; 

                                }
                            });


                            listState.list[index] = eachObj;
                            
                            

                        }
                    }

                });

                if(stateChanged) {
                    sessionStorage.setItem("records_state", JSON.stringify(listState));

                }
            }
        }
        return titleFields;
    }
    unCheck = (key,value)=>{
        if(this.state.record[key] == value){
            this.fieldChanged(key, '', true)
        }
    }
    loadHistory = (index) => {
        if(index===0) {
            this.setState({historyLoading:false, recordDifference:null, historyIndex:null});
            return;
        }


        //else, load from DB
        this.setState({historyLoading:true});

        const {recordIdx} = this;
        postWithToken('records/history.php', {
            tableIdx:this.state.currentTable,recordIdx:recordIdx,
            index:index,
        },(data)=> {
            let difference = data.difference;
            /**
             * difference : key-value pairs for different values
             */
            this.setState({recordDifference:difference, historyIndex:index});

        }, null, (data) => {
            this.setState({historyLoading:false});
        });
    }
    getData = () => {
        this.initializeState();
        this.setState({
            loading:true,
        });
        const {recordIdx} = this;

        var appliedFilters = [];
        if(sessionStorage.getItem("records_filters") !== null) {
            appliedFilters = JSON.parse(sessionStorage.getItem("records_filters"));

        }
        if(!appliedFilters) appliedFilters = [];

        postWithToken('records/view.php', {recordIdx:recordIdx, filters:appliedFilters},(data) => {
            let record = JSON.parse(data.record.fields);
            /** record : object
             * key : value
             * key : value
             * ...
             */
            let patientNo = parseInt(data.patientNo);
            let layout = Array.from(data.layout);
            let currentTable = parseInt(data.currentTable);
            /** layout : array
             * [
             *  {
             *      name : "section name",
             *      children: [
             *          {name : "field_name", type: int, field_idx: int}
             *      ]
             *  }
             * ]
             */

            let fields = data.fields;
            /**
             * fields : object
             * field_(field_idx) : { field_idx : "1", key : "key", options: json_string, required:"1", title_field:"1", type:"1", name:"string"}
             */
            
            let errorForKey = {};
           
            let newLayout = []; 
            /** calculate error */
            layout.forEach((section,index) => {
                if (section.children !== undefined && Array.isArray(section.children)) {
                    let newChildren= [];
                    section.children.forEach((field) => {
                        if (field.name !== null) {
                            // console.log(`${field.name} - ${field.field_idx}`);
                            const fieldInfo = fields['field_' + field.field_idx];
                            // console.log('field_' + field.field_idx);

                            if (fieldInfo) {
                                const key = fieldInfo.key ?? "";
                                const options = JSON.parse(fieldInfo.options);
                                const validateResult = this.validateField(field.type, options, record[key]);
                                errorForKey[key] = validateResult;
                                console.log(fieldInfo.key);
                                newChildren.push(field);
                                
                                
                                
                            // } else {
                            //     console.log('field_' + field.field_idx + " :: not ");
                            }
                            // console.log(fields);
                            
                        }
                    });
                    let newSection = JSON.parse(JSON.stringify(section));
                    newSection.children = newChildren;
                    newLayout.push(newSection);
                }
            })

            
            const required = getEmptyFields(record,fields);

            let permission = parseInt(data.permission);

            let history = data.history;

            let totalListCount = parseInt(data.listCount);
            let currentListCount= parseInt(data.currentListCount);

            let nextRecordIdx = parseInt(data.nextRecordIdx);
            let previousRecordIdx = parseInt(data.previousRecordIdx);
            // console.log(` previous : ${previousRecordIdx} | current : ${recordIdx} | next : ${nextRecordIdx}`)

            this.setState({
                record:record,
                patientNo:patientNo,
                layout:newLayout,
                fields:fields,
                permission:permission,
                errorForKey:errorForKey,
                required:required,
                history:history,
                currentTable:currentTable,
                currentListCount:currentListCount,
                totalListCount:totalListCount,
                hasNextRecord:this.state.currentListCount<totalListCount,
                nextRecordIdx:nextRecordIdx,
                previousRecordIdx:previousRecordIdx,
            })

        }, (e,data) => {console.log(data)}, (data) => {
            this.setState({
                loading:false,
            })
        })

    }

    getSectionView = (section, index) => {
        /** 
         * {
         *      name : "section name",
         *      children: [
         *          {name : "field_name", type: int, field_idx: int}
         *      ]
         *  }
         */
        return <section id={`section-${index}`} key={`section-${index}`} className="records-view-section">
            <h4 className="bp3-heading records-view-section-title">{section.name}</h4>
            <span className='section-description'>{section.description}</span>
            <Divider />
            {section.children && section.children.map(field => this.getFieldView(field))}

        </section>;
    }

    getFieldView = (field) => {
        /**
         * {name : "field_name", type: int, field_idx: int} 
         */
        return <section id={`field_${field.field_idx}`} key={"field_"+field.field_idx} className={"records-view-field-wrapper" + (parseInt(this.state.highlightedIndex) === parseInt(field.field_idx) ? " highlighted" : "")}>
            <div className="records-view-field-name">
                {field.name}
            </div>
            {this.getFieldByType(field)}

        </section>;
    }

    getFieldByType = (field) => {
        const fieldInfo =this.state.fields['field_'+field.field_idx]; 
        const key = fieldInfo.key;
        const options = JSON.parse(fieldInfo.options);
        const error = this.state.errorForKey[key];

        const disabled = this.state.historyIndex !== null;

        let editableField;



        let different = false;
        if(this.state.historyIndex !== null ) {
            /** now considering history, checkboxes have different key-value pairs */
            if(field.type ===4) {
                options.forEach((element) => {
                    if(this.state.recordDifference[`${key}-${element.key}`] !== undefined) {
                        different = true;
                    }
                });
            } else {
                if(key in this.state.recordDifference) different = true;
            }
        }

        if(field.type === 0) {
            //text
            editableField = <EditableText disabled={disabled || this.state.permission === 0} 
            value={different ? this.state.recordDifference[key] : this.state.record[key]} 
            className="records-view-field field_simple_text" 
            onChange={(value) => this.fieldChanged(key, value)}
            onConfirm={(value) => this.confirmFieldChange(key,value)}
            placeholder={(!options.description || options.description =="") ? i18next.t("record.default-placeholder-text") : options.description}
            
            />;
        } else if(field.type===1) {
            //integer
            editableField = <EditableText type="text" disabled={disabled || this.state.permission === 0}
            value={different ? this.state.recordDifference[key] : this.state.record[key]} 
            className="records-view-field" 
            onChange={(value) => this.fieldChanged(key, value)}
            onConfirm={(value) => this.confirmFieldChange(key,value)} 
            placeholder={(!options.description || options.description =="") ? i18next.t("record.default-placeholder-number") : options.description}
            intent={error[0] ? Intent.NONE : Intent.DANGER}
            />;

        } else if(field.type===2) {
            //float
            editableField = <EditableText type="text" disabled={disabled || this.state.permission === 0} 
            value={different ? this.state.recordDifference[key] : this.state.record[key]} 
            className="records-view-field" 
            onChange={(value) => this.fieldChanged(key, value)}
            onConfirm={(value) => this.confirmFieldChange(key,value)} 
            placeholder={(!options.description || options.description =="") ? i18next.t("record.default-placeholder-number") : options.description}
            intent={error[0] ? Intent.NONE : Intent.DANGER}
            />;
        } else if(field.type===3) {
            //radio
            //options : key-value pairs
            editableField = <div className="records-view-editable-wrapper"><RadioGroup
            disabled={disabled || this.state.permission === 0}  
            className={Classes.radio}
            onChange={(e) => this.fieldChanged(key, e.target.value, true)}
            inline={true}
            selectedValue = {different ? String(this.state.recordDifference[key]) : String(this.state.record[key])}
            >
                {options.map(element => <Radio label={element.key} onClick={(e) => this.unCheck(key, e.target.value)} value={String(element.value)} />)}
                
            </RadioGroup></div>
        } else if(field.type===4) {
            //checkbox
            //options : key-value pairs

            //saved as key-(options.key) as key of this.state.records

            editableField = <div className="records-view-editable-wrapper">{options.map(element => <Checkbox 
                disabled={disabled || this.state.permission === 0} 
                label={element.key}
                checked={parseInt((different && this.state.recordDifference[`${key}-${element.value}`] !== undefined) ? this.state.recordDifference[`${key}-${element.value}`] : this.state.record[`${key}-${element.value}`])>0}
                inline={true}
                onChange={e => this.fieldChanged(key, e.target.checked ? 1 : 0, true, `${key}-${element.value}`)}

            />)}</div>;
        } else if(field.type===5) {
            //long text
            editableField = <div className="records-view-editable-wrapper-textarea"><EditableText disabled={disabled || this.state.permission === 0} 
            value={different ? this.state.recordDifference[key] : this.state.record[key]} 
            className="records-view-field" 
            onChange={(value) => this.fieldChanged(key, value)}
            onConfirm={(value) => this.confirmFieldChange(key,value)} 
            placeholder={(!options.description || options.description =="") ? i18next.t("record.default-placeholder-text") : options.description}
            intent={error[0] ? Intent.NONE : Intent.DANGER}
            multiline={true}
            minLines={3} maxLines={12}
            /></div>;
        } else {
            // let dateFormat;
            // if(field.type===6) dateFormat="YYYY-MM-DD";
            // else if(field.type===7) dateFormat="YYYY-MM-DD HH:mm:ss";
            // else dateFormat=" HH:mm:ss";
            // const momentFormatter = getMomentFormatter(dateFormat);
            // editableField=<DateInput disabled={this.state.permission === 0} 
            // // {...jsDateFormatter}
            // {...momentFormatter}
            // placeholder={options.description ?? undefined}
            // showActionsBar={true}
            // shortcuts={true}
            // dayPickerProps={undefined}
            // reverseMonthAndYearMenus={true}
            // value={this.state.record[key] ? momentFormatter.parseDate(this.state.record[key]) : null}
            // onChange={(selectedDate) => this.fieldChanged(key, selectedDate !== null ?  momentFormatter.formatDate(selectedDate) : null,true)}
            //  />

            const editableProps = {
                className: "records-view-field"
            }
            editableField = <DateTimePicker
            disabled={disabled || this.state.permission === 0}
            placeholder={(!options.description || options.description =="") ? i18next.t("record.default-placeholder-date") : options.description}
            value={different ? this.state.recordDifference[key] : this.state.record[key]} 
            showDatePicker={field.type ===6 || field.type===7}
            showTimePicker={field.type ===7 || field.type===8}
            onConfirm={(selectedDate) => this.fieldChanged(key, selectedDate ,true)}
            onChange={(selectedDate) => this.fieldChanged(key, selectedDate)}
            editableProps={editableProps} />;
        }


        if(this.state.historyIndex !== null ) {
            if(different) {

                //compose content of tooltip
                let tooltipContent;
                if(field.type === 3) {
                    //radio
                    options.forEach((element) => {
                        if(element.value === this.state.record[key]) {
                            tooltipContent = element.key;
                        }
                    });
                } else if(field.type===4) {
                    //checkbox
                    let tooltipContentList = [];
                    options.forEach(element => {
                        if(parseInt(this.state.record[`${key}-${element.value}`])>0) {
                            tooltipContentList.push(element.key)
                        }

                    });
                    tooltipContent = tooltipContentList.join(", ");


                } else {
                    tooltipContent = this.state.record[key];
                }

                if(isFieldEmpty(tooltipContent)) tooltipContent = <span>empty</span>;

                return <Tooltip 
                position={Position.BOTTOM}
                isOpen={true}
                content={<div className="records-view-history-popup"><span className="currently">currently</span>{tooltipContent}</div>}
                intent={Intent.SUCCESS}
                >{editableField}</Tooltip>
            } else {
                return editableField;

            }
        }
        


        if(field.type ===3 || field.type===4 || field.type===5) {
            return editableField
        } else {
            return <Tooltip 
            disabled={error[0]}
            content={error[1] ?? ""}
            intent={Intent.DANGER}
            >{editableField}</Tooltip>

        }

    }

    /**
     * returns [true|false, "message"];
     */

    validateField = (type, options, value) => {

        //empty then return
        if(value==="" || value === undefined) return [true];
        

        if(type === 1) {
            //only integer
            // const numberedValue = Number(value);
            
            //number, check not float
            var n = Math.floor(Number(value));
            // console.log("n : "+n);
            if((n !== Infinity && String(n) === String(value))===false) {
                return [false, i18next.t("error.integer-only")];
            }

            // value = Math.floor(Number(value));
            
            //check max/min
            const minMaxResult = this.checkMinMax(options.min, options.max, value);
            if(minMaxResult[0] === false) return [false, minMaxResult[1]];


        } else if(type===2) {
            //only float 
            if(isNaN(value)) {
                //not a number
                var n = Number(value);
                if((n !== Infinity && String(n) === value)===false) {
                    return [false, i18next.t("error.number-only")];
                }

            }            
            //check max/min
            const minMaxResult = this.checkMinMax(options.min, options.max, value);
            if(minMaxResult[0] === false) return [false, minMaxResult[1]];
        }

        return [true];
    }

    /**
     * returns [true|false, "message"];
     */
    checkMinMax = (min, max, value) => {
        if(!(min === "" || min === undefined)) {
            if(parseFloat(value) < min) return [false, i18next.t("error.less-than-min",{value:min})];
        }

        if(!(max=== "" || max=== undefined)) {
            if(parseFloat(value) > max) return [false, i18next.t("error.more-than-max",{value:max})];
        }

        return [true];

    }

    getFieldInfoByKey = (key) => {
        for (const [_, fieldInfo] of Object.entries(this.state.fields)) {
            if(fieldInfo.key === key) {
                //match!
                return fieldInfo;
            }
        }

    }

    fieldChanged = (key, value, save = false, saveKey = undefined) => {
        // console.log("key" + key + " + value + " + value);
        let newRecord = this.state.record;
        if(saveKey !== undefined) {
            newRecord[saveKey] = value;
        } else {
            newRecord[key] = value;
        }

        let fieldInfo = this.getFieldInfoByKey(key);
        const options = JSON.parse(fieldInfo.options);

        let newError = this.state.errorForKey;

        const error = this.validateField(parseInt(fieldInfo.type), options, value);
        newError[key] = error;


        this.setState({
            record:newRecord,
            errorForKey:newError,
        }, () => {
            if(save) this.confirmFieldChange(saveKey !== undefined? saveKey:key,value);
        });

    }
    confirmFieldChange = (key, value) => {
        // let fields = JSON.stringify(this.state.record);
        const {recordIdx} = this;

        //re-calculated required
        const required = getEmptyFields(this.state.record, this.state.fields);
        this.setState({required:required});

        //post value
        postWithToken('records/setValue.php', {
            tableIdx:this.state.currentTable,
            recordsIdx:recordIdx,
            // fields:fields,
            key:key,value:value,
        });
    }

    highlightField = (field) => {
        window.location.replace('#field_'+field.field_idx);
        document.documentElement.scroll({
            top: document.documentElement.scrollTop-50
        });
        this.setState({highlightedIndex:field.field_idx}); 
        if(this.highlightTimeout!== null) clearTimeout(this.highlightTimeout);
        this.highlightTimeout = setTimeout(()=>{this.setState({highlightedIndex:null})}, 3000);
    }

    onDelete = () => {
        this.setState({deleteAlertOpen:true});

    }
    onDeleteCancel = () => {
        this.setState({deleteAlertOpen:false});

    }
    onDeleteConfirm = () => {
        const {recordIdx} = this;
        this.setState({deleteLoading:true});


        if(sessionStorage.getItem("records_state") !== null) {
            var listState = JSON.parse(sessionStorage.getItem("records_state"));
            listState.list.forEach((eachObj, index) => {
                //for each record... find this record
                if('records_idx' in eachObj) {
                    if(parseInt(eachObj['records_idx']) === parseInt(recordIdx)) {

                        //then this object is this record object
                        listState.list.splice(index,1);

                    }
                }

            });

            sessionStorage.setItem("records_state", JSON.stringify(listState));

        }

        postWithToken('records/delete.php', {tableIdx:this.state.currentTable,recordIdx:recordIdx}, (data) => {
            //now erase this record from sessionstorage

            
            history.push("/records");

        }, (data)=>{
            this.setState({deleteLoading:false});

        });
        

    }

    showPatientInfo = () => {
        this.setState({showPatientInfo:true});
    }
    hidePatientInfo = () => {
        this.setState({showPatientInfo:false});
    }

    componentWillUnmount() {
        if(this.highlightTimeout!== null) clearTimeout(this.highlightTimeout);

        window.removeEventListener("scroll", this.scrolledBody);
    }

    scrolledBody = () => {
        const kScrollTopThreshold = 73;
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if(scrollTop > kScrollTopThreshold&& this.state.fixNavigation === false) {
            this.setState({
                fixNavigation : true,
            });

            //then add
        } else if(scrollTop <= kScrollTopThreshold && this.state.fixNavigation === true) {
            this.setState({
                fixNavigation : false,
            });

        }

    }

    gotoNextRecord = () => {
        sessionStorage.setItem("records_clicked", this.state.nextRecordIdx);
        // window.location.replace(`/records/${this.state.nextRecordIdx}`);
        this.recordIdx = this.state.nextRecordIdx;
        history.push(`/records/${this.state.nextRecordIdx}`);
        this.getData();

    }

    gotoPrevRecord = () => {
        sessionStorage.setItem("records_clicked", this.state.previousRecordIdx);
        // window.location.replace(`/records/${this.state.previousRecordIdx}`);
        this.recordIdx = this.state.previousRecordIdx;
        history.push(`/records/${this.state.previousRecordIdx}`);
        this.getData();
    }

    render() {

        const subtitle = this.getSubTitle();



        return (
        <div id="body" className="divided">
            <PatientDialog isOpen={this.state.showPatientInfo} onClose={this.hidePatientInfo} currentTable={this.state.currentTable} recordIdx={this.recordIdx} permission={this.state.permission} />
            <div id="records-view" className="divided-body">
                <div
                    className={"divided-body-content record-view-body" + (this.state.historyLoading ? " loading" : "")}
                    >
                    <div id="record-navigation" className={this.state.fixNavigation ? "fixed" : ""}>
                        <Button icon="chevron-left" minimal={true} intent={Intent.PRIMARY} className="back-button" onClick={()=>history.push('/records')}>{i18next.t("general.list")}</Button>
                        <div className="patient-no">#{this.state.patientNo}</div>
                        <div className="patient-description">
                            {subtitle}
                        </div>
                        <div className="navigation-page">
                            <strong>{this.state.currentListCount > 0 && this.state.currentListCount}</strong>{this.state.totalListCount >0  && "/"+this.state.totalListCount}

                        </div>
                        <Button 
                            disabled={this.state.previousRecordIdx === 0}
                            icon="chevron-left" 
                            minimal={true}
                            className="navigation-left"
                            onClick={this.gotoPrevRecord} />
                        <Button 
                            disabled={this.state.nextRecordIdx=== 0}
                            icon="chevron-right" 
                            minimal={true}
                            className="navigation-right"
                            onClick={this.gotoNextRecord} />

                    </div>
                    {this.state.loading === false &&<h1 className="bp3-heading records-view-heading-spacer"><span className="gray-text">#</span>{this.state.patientNo}</h1>}
                    {this.state.loading && <div className="spinner-wrapper"><Spinner /></div>}
                    {this.state.loading === false && subtitle}
                    {this.state.layout && this.state.layout.map((element, index) => this.getSectionView(element, index))}

                </div>
                <div className="divided-body-menu hide-when-mobile">
                    
                    <SectionMenu layout={this.state.layout} rootEl="#body" />
                    <div className="record-view-menu-actions">
                        <Button small={true} minimal={true} icon="search-text" text={i18next.t("record.show-anonymized")} 
                        onClick={() =>this.showPatientInfo()}
                        />
                        {this.state.permission > 0 && <Button
                        onClick={() => this.onDelete()}
                         small={true} minimal={true} intent={Intent.DANGER} icon="trash" text={i18next.t("record.delete-record")} />}
                    </div>
                    <div className="section">
                        <h5 className="bp3-heading">{i18next.t("record.audit-title")}</h5>
                        {this.state.required.map(field => <Tag className="record-view-menu-audit-tag"  key={field.key} intent={Intent.WARNING} interactive={true} onClick={()=>{this.highlightField(field)}}>{field.name}</Tag>)}
                        {(this.state.required.length === 0 && this.state.loading===false) && <div className="records-view-menu-audit-filled">{i18next.t("record.all-required-filled")}</div>}
                    </div>
                    <div className="section">
                        <h5 className="bp3-heading">{i18next.t("record.history-title")}</h5>
                        {(this.state.history && this.state.history.length > 0) && this.state.history.map((history,index) => <div className={"records-view-menu-history-item" + ((this.state.historyIndex === index || (!this.state.historyIndex && index===0)) ? " active" : "")}
                        onClick={() => {
                           if((this.state.historyIndex === index || (!this.state.historyIndex && index===0))) {
                               //already selected
                               return;
                           }
                           this.loadHistory(index); 
                        }}>
                            {history.name} <span className="records-view-history-date">({history.edited})</span>

                        </div>)}
                    </div>

                </div>
            </div>
            <Alert
                cancelButtonText={i18next.t("general.cancel")}
                confirmButtonText={i18next.t("general.delete")}
                icon="trash"
                intent={Intent.DANGER}
                isOpen={this.state.deleteAlertOpen}
                onCancel={this.onDeleteCancel}
                onConfirm={this.onDeleteConfirm}
                style={{opacity:this.state.deleteLoading ? 0.3:1}}
                disabled={this.state.deleteLoading}
            >
                <p>
                    {i18next.t("record.delete-warning")}
                </p>
            </Alert>

        </div>
        );
    }

}
export {RecordsViewPage};