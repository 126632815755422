import { MenuItem } from "@blueprintjs/core";

export function isNumber(string) {
    let pattern = /^-?\d*\.?\d*$/;
    return pattern.test(string);
}

export function safeParse(string) {

    /**
     * returns JSON object on success
     * or false on failure
     */
    let obj;
    try {
        obj = JSON.parse(string);
    } catch (e) {
        return false;
    }
    return obj;
}
export function eraseWhiteSpaces(string) {
    return string.replace(/\s/g,'-');
}
export function getEmptyFields(record, fields) {
    /** record : object
     * key : value
     * key : value
     * ...
     */
    /**
     * fields : object
     * field_(field_idx) : { field_idx : "1", key : "key", options: json_string, required:"1", title_field:"1", type:"1"}
     */
    /**
     * return value :
     * [
     *      {field_idx : "1", key : "key", options: json_string, required:"1", title_field:"1", type:"1", name:"string"}
     * ]
     */
    // 0) iterate through fields to get required fields
    // 1) check for each record if this field is empty
    let emptyFields = [];
    for(const [_, fieldObj] of Object.entries(fields)) {
        if(parseInt(fieldObj.required) > 0) {
            //required field
            // 1) check for each record if this field is empty
            if(!(fieldObj.key in record) || isFieldEmpty(record[fieldObj.key])) {
                //empty
                emptyFields.push(fieldObj);
            }
        }
    }
    return emptyFields;
}

export function isFieldEmpty(value) {
    if(value === undefined) return true;
    if(value === null) return true;
    if(String(value) === "") return true;
    if(String(value) === 'null') return true;
    return false;
}
export function removeArrayByValue(arr, item) {
    var index = arr.indexOf(item);
    if (index !== -1) {
        arr.splice(index, 1);
    }
    return arr;
}
export function isStringEmpty(string){
    let str = string.trim();
    if(str === undefined) return true;
    if(str ==="") return true;
    if(str === null) return true;
    if(!str) return true;
}
export function defaultSelectItemRenderer(column, {handleClick, modifiers} ) {
    return(
        <MenuItem
            textClassName={column === null ? "gray-text" : ""}
            active={modifiers.active}
            disabled={modifiers.disabled}
            // key={column}
            // label={i18n.t(`operator.${operator}`)}
            onClick={handleClick}
            text={column}
        />
    )
}

export function defaultSelectItemPredicate(query, column, _index, exactMatch) {
    const normalizedTitle = String(column).toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedTitle === normalizedQuery;
    } else {
        return `${column}`.indexOf(normalizedQuery) >= 0;
    }
}
export function getIconForType(fieldType) {
    if(typeof fieldType !== 'number') fieldType = parseInt(fieldType);
    if(fieldType===0) {
        return "font";
    } else if(fieldType===1 || fieldType===2) {
        return "numerical";

    } else if(fieldType===3) {
        //radio
        return "selection";
    } else if(fieldType===4) {
        //checkbox
        return "tick";
    } else if(fieldType===5) {
        //textarea
        return "align-left";
    } else if(fieldType===6 || fieldType===7) {
        //date
        return "calendar";
    } else {
        return "time";
    }
}

export function defaultSelectFieldRenderer(field, {handleClick, modifiers} ) {
    const iconString = getIconForType(parseInt(field.type));
    
    return(
        <MenuItem
            textClassName={field === null ? "gray-text" : ""}
            active={modifiers.active}
            key={field.key}
            label={field.key}
            onClick={handleClick}
            text={field.name}
            disabled={modifiers.disabled}
            multiline={true}
            icon={iconString}
        />
    )
}

export function defaultSelectFieldPredicate(query, field, _index, exactMatch) {
    const normalizedTitle = field.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedTitle === normalizedQuery;
    } else {
        return `${field.name}.${field.key}`.indexOf(normalizedQuery) >= 0;
    }
}

export function scrollElementToBottom(query) {
    var element = document.querySelector(query);
   element.scrollTop = element.scrollHeight - element.clientHeight;
}

export function validateEmail(mail) 
{
    /**
     * returns true on valid, false on invalid;
     */
 if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
  {
    return (true)
  }
    return (false)
}

export function checkSurveyUrl(url) {
    const reg = /^[a-zA-Z0-9_]+$/;
    return reg.test(url);
}

export function validatePassword(pwd) {
    var strongRegex = new RegExp("^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
    var mediumRegex = new RegExp("^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");

    if (strongRegex.test(pwd)) {
        return true;
    } else if (mediumRegex.test(pwd)) {
        return true;
    } else {
        return false;
    }
}
// export function pngOfBase64(image) {
//     if(image)
// }