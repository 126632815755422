import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import logo from "../assets/logo.svg";
import {Button, FormGroup, Callout, Checkbox} from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import "./LoginPage.css";
import i18n from "i18next";
import queryString from 'query-string';
import { history } from '../_helpers';

import { authenticationService } from '../_services';

class LoginPage extends React.Component {
    
    constructor(props) {

        
        super(props);

        this.presetEmail = '';

        // redirect to home if already logged in
        if (authenticationService.currentUserValue) { 
            this.props.history.push('/');
        }
        
        const query = queryString.parse(this.props.location.search);
        if(query.email !== undefined) {
            //has email, so set this as the value for signup/login
            this.presetEmail = query.email;
            
        }

        this.state = {
            signup :false,
            privacyAgreed:false,
            termsAgreed:false,

        };

        
        //=> {foo: 'bar'}
    }

    toggleSignup = () => {
        this.setState({
            signup : !this.state.signup
        });
        
    }

    toggleTermCheckBox = () => {
        this.setState({
            termsAgreed : !this.state.termsAgreed
        })

    }
    togglePrivacyCheckBox = () => {
        this.setState({
            privacyAgreed: !this.state.privacyAgreed
        })

    }

    goSignup = () => {
        history.push("/register");
    }
    goLostPass = () => {
        history.push("/lostpass");
    }

    render() {
        
        return (
            <div className="login-div">
                <div>
                    <img src={logo} alt="logo" id="redea-logo" />
                    <Formik
                        initialValues={{
                            email: this.presetEmail,
                            password: '',
                            passwordVerify:'',
                            name:''

                        }}
                        validationSchema={this.state.signup ? Yup.object().shape({
                            email: Yup.string().email(i18n.t('login.check-email')).required(i18n.t('login.email-required')),
                            password: Yup.string().min(8, i18n.t('login.min-8-letters')).required('login.password-required'),

                            passwordVerify: Yup.string().min(8, i18n.t('login.min-8-letters')).required('login.password-required').oneOf([Yup.ref('password'), null], i18n.t('login.password-must-match')),
                            name:Yup.string().required(i18n.t('login.name-required'))
                      
                      
                        }) : Yup.object().shape({
                            email: Yup.string().email(i18n.t('login.check-email')).required(i18n.t('login.email-required')),
                            password: Yup.string().min(8, i18n.t('login.min-8-letters')).required('login.password-required'),
                      
                      
                        })}
                        onSubmit={({ email, password, passwordVerify, name }, { setStatus, setSubmitting }) => {
                            setStatus();

                            if(this.state.signup === false) {
                                // console.log('here');
                                authenticationService.login(email, password)
                                .then(
                                    result => {
                                        if(result===true) {
                                            const { from } = this.props.location.state || { from: { pathname: "/" } };
                                            if(this.props.location.state && this.props.location.state.logoutByUser !== undefined && this.props.location.state.logoutByUser ===true) {
                                                this.props.history.push("/");

                                            } else {

                                                this.props.history.push(from);
                                            }

                                        } else {
                                            setSubmitting(false);
                                            setStatus(result);
                                            // console.log(result);

                                        }
                                    },
                                    error => {
                                        setSubmitting(false);
                                        setStatus(error);
                                        // console.log(error);
                                    }
                                );

                            } else {
                                authenticationService.signup(email, password, name)
                                .then(
                                    result => {
                                        if(result===true) {
                                            const { from } = this.props.location.state || { from: { pathname: "/" } };
                                            this.props.history.push(from);

                                        } else {
                                            setSubmitting(false);
                                            setStatus(result);
                                            // console.log(result);

                                        }
                                    },
                                    error => {
                                        setSubmitting(false);
                                        setStatus(error);
                                        // console.log(error);
                                    }
                                );


                            }
                        }}
                        render={({ errors, status, touched, isSubmitting }) => (

                            <Form>
                                {status && <Callout className="bp3-intent-danger login-callout">{i18n.t(`error.${status}`)}</Callout>}
                                <FormGroup
                                    label={i18n.t("login.email")}
                                    labelFor="login-email"
                                >
                                    <Field 
                                        name="email" 
                                        id="login-email" 
                                        type="email" 
                                        placeholder={i18n.t("login.email")} 
                                        className={'bp3-input bp3-fill' + (errors.email&& touched.email? ' bp3-intent-danger' : '')} />
                                    <ErrorMessage name="email" component="div" className="bp3-text-small warning-text" />
                                </FormGroup>
                                <FormGroup
                                    label={i18n.t("login.password")}
                                    labelFor="login-password"
                                >
                                    <Field name="password" type="password" id="login-password" placeholder={i18n.t("login.password")} className={'bp3-input bp3-fill' + (errors.password&& touched.password? ' bp3-intent-danger' : '')} />
                                    <ErrorMessage name="password" component="div" className="bp3-text-small warning-text" />
                                </FormGroup>
                                {this.state.signup && 
                                <FormGroup
                                    label={i18n.t("login.verify-password")}
                                    labelFor="login-password-verify"
                                >
                                    <Field 
                                    name="passwordVerify" 
                                    type="password" 
                                    id="login-password-verify" 
                                    placeholder={i18n.t("login.verify-password")} 
                                    className={'bp3-input bp3-fill' + (errors.passwordVerify&& touched.passwordVerify? ' bp3-intent-danger' : '')} />
                                    <ErrorMessage name="passwordVerify" component="div" className="bp3-text-small warning-text" />
                                </FormGroup>}
                                {this.state.signup && 
                                <FormGroup
                                    label={i18n.t("login.name")} 
                                    labelFor="login-name"
                                >
                                    <Field 
                                    name="name" 
                                    id="login-name" 
                                    placeholder={i18n.t("login.name")}  
                                    className={'bp3-input bp3-fill' + (errors.name&& touched.name? ' bp3-intent-danger' : '')} />
                                    <ErrorMessage name="name" component="div" className="bp3-text-small warning-text" />
                                </FormGroup>}
                                {this.state.signup && 
                                <Checkbox checked={this.state.termsAgreed} onClick={this.toggleTermCheckBox}>
                                    {i18n.t('login.agree-to')}
                                <a href="https://redea.brainpill.io/terms.html" target="_blank">{i18n.t('login.terms')}</a>
                                </Checkbox>}
                                {this.state.signup && 
                                <Checkbox checked={this.state.privacyAgreed} onClick={this.togglePrivacyCheckBox}>
                                    {i18n.t('login.agree-to')}
                                <a href="https://redea.brainpill.io/privacy.html" target="_blank">{i18n.t('login.privacy')}</a>
                                </Checkbox>}
                                <Button loading={isSubmitting} disabled={this.state.signup === true && ( this.state.termsAgreed === false || this.state.privacyAgreed ===false)} className="bp3-fill" type="submit" >
                                    {i18n.t(this.state.signup ? 'login.create-account' : 'login.sign-in')}
                                </Button>
                                <Button disabled={isSubmitting} 
                                    onClick={this.goSignup} 
                                    // onClick={this.toggleSignup} 
                                    className="bp3-fill bp3-minimal login-signup" >
                                    {i18n.t(this.state.signup ? 'login.sign-in':'login.create-account')}
                                </Button>
                                <Button disabled={isSubmitting} 
                                    onClick={this.goLostPass} 
                                    // onClick={this.toggleSignup} 
                                    className="bp3-fill bp3-minimal" >
                                    <span style={{color:'#8A9BA8'}}>{i18n.t("login.lost-pass")}</span>
                                </Button>
                                

                                
                            </Form>
                        )}
                    />
                </div>
                
            </div>
        )
    }
}

export {LoginPage }; 